import React, {useState} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {Container, Button, Form, Segment, Loader} from "semantic-ui-react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import {useDispatch, useSelector} from "react-redux";
import {formatErrors} from "../../constants";
import {toast} from "react-toastify";
import {registerAction} from "../../actions/loginAction";

function Register(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lang = useSelector(state => state.common.lang);
  const [loading, setLoading] = useState(false);
  const [preloading, setPreloading] = useState(false);
  const [errors, setErrors] = useState({});
  const [form, handleChange] = useState({
    name: '',
    phone: '',
    email: '',
    password: '',
    agree: false,
    invite_code: ''
  });

  const handleChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({...form, [name]: value});
    setErrors({...errors, [name]: value.trim() === ''});
  }

  const handleChangeCb = () => {
    handleChange({...form, agree: !form.agree});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return false;
    }
    let errs = {};
    if (!form.agree) {
      errs.agree = true;
    }
    if (!form.invite_code && form.name.trim().length < 2) {
      errs.name = true;
    }
    if (form.email === '' || validateEmail(form.email) !== true) {
      errs.email = true;
    }
    if (form.password.length < 6) {
      errs.password = true;
    }

    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      return;
    }

    setLoading(true);
    dispatch(registerAction({name: form.name, email: form.email, phone: form.phone,
      language: lang, password: form.password, invite_code: form.invite_code})).then(() => {
      setLoading(false);
      navigate('/register-complete', {state: {email: form.email, invite: form.invite_code ? true : false}});
    }).catch(err => {
      setLoading(false);
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      if (err.message === 'used:email') {
        setErrors({email: true});
      }
    });
  };

  const validateEmail = (value) => {
    const email_filter = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
    return email_filter.test(value);
  };

  /*
  useEffect(() => {
    const invite_code = (new URLSearchParams(location.search)).get('invite_code');
    if (!invite_code) {
      navigate('/login');
      return;
    }
    if (invite_code) {
      setPreloading(true);
      dispatch(inviteCheckAction(invite_code)).then((email) => {
        handleChange(form => {
          return {...form, email, invite_code}
        });
        setPreloading(false);
      }).catch((err) => {
        setPreloading(false);
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
        navigate('/login');
      })
    }
  }, [location, dispatch, navigate]);
  */

  return <React.Fragment>
    <MyAppBar/>
    <Container className={'container-narrow'}>
      <Header as={'h3'} textAlign='center'>Sign up</Header>
      {preloading && <Loader active size="large" />}
      {!preloading && <Form size='large' noValidate onSubmit={handleSubmit}>
        <Segment stacked>
          <Form.Input fluid label='Name' placeholder='Your name'
                      error={!!errors.name} name={'name'} value={form.name} onChange={handleChangeForm}/>

          <Form.Input fluid label='Email Address' placeholder='Email Address'
                      error={!!errors.email} name={'email'} value={form.email} readOnly={form.invite_code !== ''} onChange={handleChangeForm}/>

          <Form.Input fluid label='Phone' placeholder='Phone'
                      error={!!errors.phone} name={'phone'} value={form.phone} onChange={handleChangeForm}/>

          <Form.Input type={'password'} fluid label='Password' placeholder='Password'
                      error={!!errors.password} name={'password'} value={form.password} onChange={handleChangeForm}/>


          <Form.Checkbox label={<label>I agree to the <Link to={'/'} className={'black bold'}>Privacy Policy</Link> and <Link to={'/'} className={'black bold'}>User Agreement</Link></label>}
                         checked={form.agree}
                         onChange={handleChangeCb}
                         required
                         error={!!errors.agree} />

          <Button type="submit" fluid size='medium' color="black">Sign Up</Button>
        </Segment>

        <div>
          <Link to="/login" className={'purple'}>{"Have an account? Please Sign In"}</Link>
        </div>
      </Form>}
    </Container>
  </React.Fragment>;
}


export default Register
