import {authMiddleware} from "./loginAction";
import cfg, {formatStatusError, handleResponse} from "../constants";

// usersAction - get users (invited or confirmed)
export const usersAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/users', {
    method: "POST",
    body: JSON.stringify(data),
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

// userGetAction - get user by ID
export const userGetAction = (id) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/user/' + id, {
    method: "GET",
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data.item;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

// userDeleteAction - delete user by ID
export const userDeleteAction = (id) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/user/' + id, {
    method: "DELETE",
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

// userInviteAction - invite user
export const userCreateAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/user-create', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

// userInviteAction - invite user
export const userInviteAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/user-invite', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

// upload user logo
export const userUploadLogoAction = (form) =>  (dispatch, getState) => {
  const token = authMiddleware(dispatch, getState, false, true);

  return new Promise((resolve, reject) => {
    const formData = new FormData();

    if (form.file && form.file.name) {
      formData.append("file", form.file, form.file.name);
    }
    formData.append('user_id', form.user_id);

    const req = new XMLHttpRequest();
    req.addEventListener("load", event => {
      if (req.status === 200) {
        resolve(req.response);
      } else {
        reject(formatStatusError(req.status, req.response.trim()));
      }
    });

    req.upload.addEventListener("error", event => {
      reject(req.response);
    });

    req.open("POST", cfg.base_url + '/auth/user-upload-logo');
    req.setRequestHeader("Authorization", 'Bearer ' + token);
    req.send(formData);
  });
};

// remove user logo
export const userDeleteLogoAction = (user_id) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/user/' + user_id + '/delete-logo', {
    method: "GET",
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};


// userEditAction - edit user profile
export const userEditAction = (body) => (dispatch, getState) => {
  const token = authMiddleware(dispatch, getState, false, true);
  return fetch(cfg.base_url + '/auth/user-edit', {
    method: "POST",
    headers: {"Authorization": "Bearer " + token},
    body: JSON.stringify(body)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

// 
export const nearbysearchAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/nearbysearch', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

//
export const footfallAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/footfall', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const showcaseAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/showcase', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};
