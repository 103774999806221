import React, {useState, useEffect} from 'react';
import {Accordion, Icon, List} from "semantic-ui-react";
import {AccordionTabs} from "../../../constants";

function Footfall() {
  const [tabs, setTabs] = useState([]);
  const at = new AccordionTabs();

  return <React.Fragment>
    <Accordion className={"report-qa"}>
      <Accordion.Title active={at.g(0, tabs)}
                       index={0}
                       onClick={() => at.toggle(0, tabs, setTabs)}>
        <Icon name='dropdown'/>
        <b>What is the source of the data?</b>
      </Accordion.Title>
      <Accordion.Content active={at.g(0, tabs)}>
        <span>
          We have partnered with Locomizer, which has developed an innovative dataset and approach to support a detailed
          understanding of pedestrian and non-pedestrian movement modalities at microgeographic levels. The key source
          of input data is SDK-sourced GPS coordinates from almost 7 million mobile devices in the UK.<br/>
          The mobile data is sourced from various apps representing various user types to capture representative and
          robust coordinate data per unique device that best reflects the population. The data is collected via mobile
          applications installed on smartphones and opted in by users. If the device has a specific app installed and
          location data collection is enabled, then this data will be accessible.<br/>
          The mobility data for the UK is sourced from 1000+ apps (Lifestyle, Sports, Finance, Video Players,
          News/Magazines, Social Networking, Entertainment/Games, Video Players, Communications, Tools, etc) and
          provides raw data per unique device.
        </span>
      </Accordion.Content>

      <Accordion.Title active={at.g(1, tabs)}
                       index={1}
                       onClick={() => at.toggle(1, tabs, setTabs)}>
        <Icon name='dropdown'/>
        <b>Can this data be trusted?</b>
      </Accordion.Title>
      <Accordion.Content active={at.g(1, tabs)}>
        <span>
          To maintain the accuracy and representative robustness of the human mobility data, a combination of elements
          is used:<br/>
          <List bulleted>
            <List.Item>
              Large data volumes (>2-8% of the mobile holding population).
            </List.Item>
            <List.Item>
              Persistent user base
            </List.Item>
            <List.Item>
              Diverse range of mobile apps
            </List.Item>
            <List.Item>
              High correlation with residential distribution compared to official statistics, i.e. Census
            </List.Item>
          </List>
        </span>
      </Accordion.Content>

      <Accordion.Title active={at.g(2, tabs)}
                       index={2}
                       onClick={() => at.toggle(2, tabs, setTabs)}>
        <Icon name='dropdown'/>
        <b>Does this data adhere to GDPR?</b>
      </Accordion.Title>
      <Accordion.Content active={at.g(2, tabs)}>
        <span>
          The data sets don’t contain User IDs, Phone Numbers, Emails, Addresses, Names, or any Personally Identifiable
          Information. The data sets do contain aggregated (Footfall) and abstract (Brand Affinities) data derivatives
          and adhere to GDPR.
        </span>
      </Accordion.Content>
    </Accordion>
  </React.Fragment>
}

export default Footfall;
