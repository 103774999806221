import cfg, {handleResponse} from "../constants";
import {authMiddleware} from "./loginAction";

// sendContactAction - send contact form to server
export const sendContactAction = (body) => (dispatch, getState) => {
  return fetch(cfg.base_url + '/auth/contact-form', {
    method: "POST",
    body: JSON.stringify(body)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

// get contacts list
export const contactsAction = (filter) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/contacts', {
      method: "POST",
      body: JSON.stringify(filter),
      headers
  }).then(handleResponse).then((data) => {
      if (data.status === 'ok') {
          return data;
      }
      if (data.errors && data.errors.length > 0) {
          throw new Error(data.errors[0]);
      }
      throw new Error('error_other');
  });
};

// delete contact
export const contactDeleteAction = (id) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/contact/' + id, {
      method: "DELETE",
      headers
  }).then(handleResponse).then((data) => {
      if (data.status === 'ok') {
          return data;
      }
      if (data.errors && data.errors.length > 0) {
          throw new Error(data.errors[0]);
      }
      throw new Error('error_other');
  });
};