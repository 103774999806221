import {combineReducers} from 'redux';
import user from './user';
import common from './common';
import countries from "./countries";

const AppReducer = combineReducers({
    user,
    countries,
    common
});

export default AppReducer
