import React, {useEffect, useState} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Container, Icon, Table, Button, Grid, Breadcrumb, Message} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {formatErrors} from "../../constants";
import {contactsAction, contactDeleteAction} from '../../actions/contactAction';
import Paging from '../../utils/Paging/Paging';
import {ConfirmModal} from '../../utils/ConfirmModal/ConfirmModal';


function Contacts() {
  const [filter, setFilter] = useState(null);
  const filterName = "m_ContactsFilter";

  const handleChangeFilter = (value, name) => {
    if (name === "page") {
      setFilter({...filter, page: value});
    } else if (name === "sort") {
      setFilter({
        ...filter,
        sort: value,
        sortd: filter.sort === value ? (filter.sortd === "asc" ? "desc" : "asc") : "asc",
        page: 1
      });
    } else if (name) {
      setFilter({...filter, [name]: value, page: 1});
    } else {
      setFilter({...filter});
    }
  };

  useEffect(() => { 
    const dFilter = {page: 1, limit: 10, sort: 'created', sortd: 'desc'};
    let curFilter = localStorage.getItem(filterName);
    curFilter = curFilter ? JSON.parse(curFilter) : dFilter;

    setFilter(curFilter);
  }, []);

  return <React.Fragment>
    <MyAppBar/>

      <Container className={'container-wide'}>
          <ButtonsTop handleChange={handleChangeFilter}/>
          {filter && <ContactsList  filterName={filterName} filter={filter} handleChange={handleChangeFilter}/>}
      </Container>
  </React.Fragment>;
}

const ButtonsTop = ({handleChange}) => {
  return <Grid>
    <Grid.Row columns={2}>
      <Grid.Column>
        <Breadcrumb>
          <Breadcrumb.Section active>
            Contacts
          </Breadcrumb.Section>
        </Breadcrumb>
      </Grid.Column>

      <Grid.Column textAlign='right'></Grid.Column>
    </Grid.Row>
  </Grid>;
};

function ContactsList({filter, filterName, handleChange}) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);

  const deleteContacts = (id) => {
    return new Promise((resolve, reject) => {
      if (loading) {
        return resolve(false);
      }
      setLoading(true);
      dispatch(contactDeleteAction(id)).then(() => {
        setLoading(false);
        resolve(true);
        if (Math.ceil(total / filter.limit) === filter.page && filter.page > 1) {
          handleChange(filter.page - 1, "page");
        } else {
          handleChange();
        }
      }).catch(err => {
        setLoading(false);
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
        reject(err);
      });
    })
  };

  useEffect(() => {
    if (!filter) {
      return;
    }

    dispatch(contactsAction(filter)).then((data) => {
      setItems(data.items || []);
      setTotal(data.total);
      localStorage.setItem(filterName, JSON.stringify(filter));
    }).catch(err => {
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }, [dispatch, filter, filterName]);

  return <React.Fragment>
    {total === 0 && <Message color="purple">No records found.</Message>}

    {total > 0 && <Table celled striped sortable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>First name</Table.HeaderCell>
          <Table.HeaderCell>Last name</Table.HeaderCell>
          <Table.HeaderCell>Company</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Message</Table.HeaderCell>
          <Table.HeaderCell width={2}/>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((i) => {
          return <Table.Row key={i.id}>
            <Table.Cell>{i.created}</Table.Cell>
            <Table.Cell>{i.first_name}</Table.Cell>
            <Table.Cell>{i.last_name}</Table.Cell>
            <Table.Cell>{i.company}</Table.Cell>
            <Table.Cell>{i.email}</Table.Cell>
            <Table.Cell>{i.message}</Table.Cell>
            <Table.Cell textAlign="center">
              <ConfirmModal content='Do you wish to delete message?' ok={() => deleteContacts(i.id)}
                            trigger={<Button size='mini' icon><Icon name='delete'/></Button>}/>
            </Table.Cell>
          </Table.Row>;
        })}
      </Table.Body>
    </Table>}

    <Paging total={total} page={filter.page} limit={filter.limit} handleChange={handleChange}/>

  </React.Fragment>;
}

export default Contacts
