import React, {useEffect, useRef, useState} from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from "mapbox-gl";
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {cellToBoundary, cellToLatLng, gridDisk, latLngToCell} from "h3-js";
import {getDeliveryZoneAction} from "../../actions/placeMapFootfallAction";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {formatErrors, ROLE_ADMIN, ROLE_MANAGER, ROLE_SUBSCRIBER, trafficGradient} from "../../constants";
import {Dropdown, Loader} from "semantic-ui-react";
import {getUser} from "../../actions/loginAction";
import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = 'pk.eyJ1Ijoic3Nlcmd5IiwiYSI6ImNsOHU5enNjbTAyMGQzcHJ4ODlsanNpNHgifQ.NXh_OvvnBFO_uArBg676IA';

function PlaceMapDeliveryZone() {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const reportPopup = useRef(null);
  const activeHexes = useRef([]);
  const [latLng, setLatLng] = useState([]);
  const [marker, setMarker] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeInterval, setTimeInterval] = useState('25');

  const profile = getUser();

  const ffLayer = 'footfall-layer';

  const dispatch = useDispatch();

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    bbox: [-8.585350424507451, 49.89060631678373, 1.762474098318385, 60.85571099962073],
    mapboxgl: mapboxgl,
    placeholder: 'Search Location'
  });

  useEffect(() => {
    if (latLng.length > 1 && !loading) {
      setLoading(true);

      let hex = latLngToCell(latLng[0], latLng[1], 10)

      if (!!reportPopup.current) {
        reportPopup.current.remove();
      }

      if (marker !== null) {
        marker.remove();
      }

      let hexCenter = cellToLatLng(hex)
      let m = new mapboxgl.Marker({color: "#4668F2"}).setLngLat([hexCenter[1], hexCenter[0]]).addTo(map.current);
      setMarker(m);

      /*
      let cycling = JSON.parse("{\"type\":\"Polygon\",\"coordinates\":[[[-0.108171,51.493701],[-0.10859,51.490798],[-0.109833,51.488],[-0.111959,51.485372],[-0.114479,51.482815],[-0.117663,51.480707],[-0.121184,51.478862],[-0.125414,51.477478],[-0.129863,51.476479],[-0.134509,51.476124],[-0.138999,51.475813],[-0.143922,51.476607],[-0.148325,51.476908],[-0.151998,51.477543],[-0.156319,51.479686],[-0.15933,51.481646],[-0.16367599095022625,51.48383809954751],[-0.164874,51.486515],[-0.1666,51.489326],[-0.167526,51.492186],[-0.167723,51.495123],[-0.16714,51.497996],[-0.165495,51.500835],[-0.163917,51.503708],[-0.161443,51.505734],[-0.158132,51.508088],[-0.154405,51.509701],[-0.15008769014084508,51.51137561971831],[-0.1491912196969697,51.51250752272727],[-0.141204,51.512773],[-0.136544,51.512767],[-0.131824,51.512722],[-0.127579,51.511866],[-0.123325,51.510629],[-0.119569,51.509104],[-0.115995,51.507021],[-0.11299408552631579,51.5048435],[-0.110751,51.50203],[-0.108922,51.499373],[-0.108263,51.496573],[-0.108171,51.493701]]]}")
      let driving =  JSON.parse("{\"type\":\"Polygon\",\"coordinates\":[[[-0.09212,51.49349],[-0.093002,51.488775],[-0.094999,51.484572],[-0.097888,51.480341],[-0.101702,51.47663],[-0.107163,51.473539],[-0.112516,51.470588],[-0.118886,51.468338],[-0.12524,51.466928],[-0.1325813273847918,51.46541164215581],[-0.139971,51.465841],[-0.146553,51.466405],[-0.153891,51.467505],[-0.160721,51.469682],[-0.166289,51.471937],[-0.171686,51.47517],[-0.174784,51.477855],[-0.17953,51.482361],[-0.182196,51.486494],[-0.183174,51.491078],[-0.183748,51.495876],[-0.182799,51.500155],[-0.180375,51.501786],[-0.178967,51.511137],[-0.174052,51.512183],[-0.169121,51.515383],[-0.163425,51.518176],[-0.157045,51.52023],[-0.151688222462203,51.5192152798519],[-0.13820123617894114,51.52124253311194],[-0.13601084936071292,51.52326286578845],[-0.12964416629995396,51.52160571979737],[-0.121044497300216,51.51899138498921],[-0.121044497300216,51.51899138498921],[-0.109509,51.516815],[-0.104225,51.513755],[-0.09428640230515917,51.512286088913285],[-0.096216,51.506443],[-0.093972,51.501878],[-0.092385,51.497729],[-0.09212,51.49349]]]}")
      let walking =  JSON.parse("{\"type\":\"Polygon\",\"coordinates\":[[[-0.126379,51.493972],[-0.12661,51.49307],[-0.12588242575819955,51.49158588390284],[-0.128122,51.49077],[-0.129106,51.489631],[-0.130106,51.489087],[-0.131414,51.488484],[-0.13314,51.487853],[-0.134559,51.487505],[-0.136598,51.487364],[-0.138359,51.487221],[-0.140116,51.487339],[-0.141903,51.48769],[-0.143429,51.488196],[-0.14515,51.488793],[-0.146286,51.489498],[-0.147371,51.49065],[-0.14836,51.49133],[-0.148834,51.492632],[-0.14937930883919062,51.49380134611289],[-0.149452,51.494686],[-0.149189,51.495781],[-0.148574,51.496957],[-0.147928,51.4979],[-0.146913,51.498856],[-0.14684905224920802,51.499341812375924],[-0.14625271552833077,51.49874439087289],[-0.14625271552833077,51.49874439087289],[-0.141003,51.501316],[-0.139227,51.501517],[-0.137272,51.501396],[-0.135887,51.501838],[-0.133961,51.501141],[-0.132273,51.500559],[-0.130538,51.500026],[-0.129642,51.499336],[-0.128358,51.498451],[-0.127457,51.497203],[-0.126817,51.496215],[-0.126473,51.49531],[-0.126379,51.493972]]]}")

      let jsonData = {
        "type": "geojson",
        "data": {
          "type": "FeatureCollection",
          "features": []
        }
      };


            jsonData.data.features.push(
              {
                "type": "Feature",
                "geometry": cycling
              }
            );
            jsonData.data.features.push(
              {
                "type": "Feature",
                "geometry": driving
              }
            );
            jsonData.data.features.push(
              {
                "type": "Feature",
                "geometry": walking
              }
            );

      let src = map.current.getSource(ffLayer);

      console.log(jsonData);

      if (typeof src !== 'undefined') {
        src.setData(jsonData.data);
      }

      setLoading(false);
      */

      dispatch(getDeliveryZoneAction({lat: latLng[0], lng: latLng[1]})).then((res) => {

        let jsonData = {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": []
          }
        };

        let driving = JSON.parse(res.driving);
        let walking = JSON.parse(res.walking);
        let cycling = JSON.parse(res.cycling);

        jsonData.data.features.push({
          "type": "Feature",
          "geometry": driving
        });
        jsonData.data.features.push({
          "type": "Feature",
          "geometry": walking
        });
        jsonData.data.features.push({
          "type": "Feature",
          "geometry": cycling
        });

        let src = map.current.getSource(ffLayer);

        if (typeof src !== 'undefined') {
          src.setData(jsonData.data);
        }

        /*
        jsonData = {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": []
          }
        };

        for (let itm of res.geoms) {
          let geo = JSON.parse(itm);

          jsonData.data.features.push({
            "type": "Feature",
            "geometry": geo
          });
        }

        src = map.current.getSource("routes");

        if (typeof src !== 'undefined') {
          src.setData(jsonData.data);
        }*/

        setLoading(false);
      }).catch((err) => {

        let jsonData = {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": []
          }
        };

        let src = map.current.getSource(ffLayer);

        if (typeof src !== 'undefined') {
          src.setData(jsonData.data);
        }

        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [latLng, timeInterval]);

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-0.13765485305231095, 51.49468631310461],
      zoom: 15,
    });

    map.current.on("load", () => {

      map.current.on('dblclick', (e) => {
        e.preventDefault();

        setLatLng([e.lngLat.lat, e.lngLat.lng]);

      });

      map.current.addSource(ffLayer, {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      map.current.addLayer({
        'id': ffLayer+"-line",
        'type': 'line',
        'source': ffLayer,
        'layout': {},
        'paint': {
          'line-color': 'rgba(115,21,48,0.45)',
          'line-width': 2
        }
      });


      map.current.addLayer({
        'id': ffLayer+"-fill",
        'type': 'fill',
        'source': ffLayer, // reference the data source
        'layout': {},
        'paint': {
          'fill-color': 'rgba(140,73,96,0.45)',
          'fill-opacity': 0.5
        }
      });

      map.current.addSource("routes", {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      map.current.addLayer({
        'id': "routes-line",
        'type': 'line',
        'source': "routes",
        'layout': {},
        'paint': {
          'line-color': 'rgb(5,0,255)',
          'line-width': 2
        }
      });

      geocoder.on('result', (e) => {
        // remove `click` marker if exists

        geocoder.mapMarker.remove();
        setLatLng([e.result.center[1], e.result.center[0]]);
      });

      document.getElementById('geocoder-container-footfall-map')?.appendChild(geocoder.onAdd(map.current));

      map.current.on('mousedown', (e) => {

        if (e.originalEvent.button === 2) {

          if (!profile || !profile.token || [ROLE_SUBSCRIBER, ROLE_ADMIN, ROLE_MANAGER].indexOf(profile.role_id) < 0) {
            return;
          }

          let hex = latLngToCell(e.lngLat.lat, e.lngLat.lng, 10);

          if (!!activeHexes.current && activeHexes.current.indexOf(hex) < 0) {
            return;
          }

          if (reportPopup.current !== null) {
            reportPopup.current.remove();
          }

          reportPopup.current = new mapboxgl.Popup()
            .setLngLat([e.lngLat.lng, e.lngLat.lat])
            .setHTML("<div style='font-size: 12px;min-width: 200px;'>" +
              "<a class='map-popup-button' href='/location-report?hex=" + hex + "' target='_blank'>Open location report</a>" +
              "</div>")
            .addTo(map.current);
        }
      });

      setLoading(false);
    });
  }, [map])

  const options = [
    { key: '1', text: '00:00-00:59', value: '0' },
    { key: '2', text: '01:00-01:59', value: '1' },
    { key: '3', text: '02:00-02:59', value: '2' },
    { key: '4', text: '03:00-03:59', value: '3' },
    { key: '5', text: '04:00-04:59', value: '4' },
    { key: '6', text: '05:00-05:59', value: '5' },
    { key: '7', text: '06:00-06:59', value: '6' },
    { key: '8', text: '07:00-07:59', value: '7' },
    { key: '9', text: '08:00-08:59', value: '8' },
    { key: '10', text: '09:00-09:59', value: '9' },
    { key: '11', text: '10:00-10:59', value: '10' },
    { key: '12', text: '11:00-11:59', value: '11' },
    { key: '13', text: '12:00-12:59', value: '12' },
    { key: '14', text: '13:00-13:59', value: '13' },
    { key: '15', text: '14:00-14:59', value: '14' },
    { key: '16', text: '15:00-15:59', value: '15' },
    { key: '17', text: '16:00-16:59', value: '16' },
    { key: '18', text: '17:00-17:59', value: '17' },
    { key: '19', text: '18:00-18:59', value: '18' },
    { key: '20', text: '19:00-19:59', value: '19' },
    { key: '21', text: '20:00-20:59', value: '20' },
    { key: '22', text: '21:00-21:59', value: '21' },
    { key: '23', text: '22:00-22:59', value: '22' },
    { key: '24', text: '23:00-23:59', value: '23' },
    { key: '25', text: '00:00-23:59', value: '25' },
  ]

  return <React.Fragment>

    <div className={"map-contu-u "+([ROLE_ADMIN, ROLE_MANAGER].indexOf(profile?.role_id) >= 0?"map-admin-fix":"")}>
      <div className={'place-map-analytics-dropdown two-child-ff'}>
        <div><div id={"geocoder-container-footfall-map"} /></div>
      </div>
      <MyAppBar fixed={true} />
      <div ref={mapContainer} className="map-container" />
      <div className={"map-legend-container"}>
        <div className={'map-loader'}>
          <Loader content='Loading' active={loading} size={'large'}/>
        </div>
      </div>
    </div>
  </React.Fragment>
}

export default PlaceMapDeliveryZone
