import React, { useEffect, useMemo, useRef, useState } from 'react';
import {Breadcrumb, Button, Checkbox, Container, Form, Grid, Icon, Image} from 'semantic-ui-react';
import MyAppBar from '../../utils/MyAppBar/MyAppBar';
import { pageDeleteImageAction, pageEditAction, pageGetAction, pageUploadImageAction } from '../../actions/pageAction';
import { toast } from 'react-toastify';
import cfg, { formatErrors, formatStatusError } from '../../constants';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js-image-plugin/lib/plugin.css"
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { convertToRaw, ContentState, EditorState } from 'draft-js';
import { getUser } from '../../actions/loginAction';
import FileInput from "../../utils/Upload/FileInput";
import DOMPurify from 'dompurify';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

function PageItem() {
  const {pageId, language} = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const languages = [{code: "en"}];
  const [lang, setLang] = useState(language || 'en');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [img, setImg] = useState(null);
  const [form, handleChange] = useState({
    id: 0,
    title: null,
    sub_title: null,
    navigation: '',
    content: null,
    date_time: new Date(),
    date_time_edited: null,
    active: false,
  });

  const handleChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({...form, [name]: value});
    setErrors({...errors, [name]: value.trim() === ''});
  };

  const handleChangeFormTitle = (e, lang) => {
    let val = Object.assign({}, form.title);
    val[lang] = e.target.value;
    handleChange({...form, title: val});
  };

  const handleChangeFormSubTitle = (e, lang) => {
    let val = Object.assign({}, form.sub_title);
    val[lang] = e.target.value;
    handleChange({...form, sub_title: val});
  };

  const handleChangeFormContent = (e, lang) => {
    let val = Object.assign({}, form.content);
    val[lang] = e;
    handleChange({...form, content: val});
  };

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html)
    }
  }

  const handleChangeCb = (name) => {
    if (name === 'active' ) {
      handleChange({...form, active: !form.active});
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return false;
    }

    let errs = {};
    if (!form.title['en'] || form.title['en'].trim() === '') {
      errs.title = true;
    }

    if (form.navigation.trim() === '') {
      errs.navigation = true;
    }

    if (Object.keys(errs).length > 0) {
      setErrors(errs);
      return;
    }

    let content = {}
    for (let i = 0; i < languages.length; i++) {
      content[languages[i].code] = draftToHtml(convertToRaw(form.content[languages[i].code].getCurrentContent()));
    }

    const date_time = form.date_time.toLocaleDateString("en-CA").substring(0, 10);
    const date_time_edited = !!form.date_time_edited?form.date_time_edited.toLocaleDateString("en-CA").substring(0, 10):'';

    setErrors(errs);
    setLoading(true);
    dispatch(pageEditAction({...form, content, date_time, date_time_edited})).then((res) => {
      setLoading(false);
      toast.success("Article saved!", {position: toast.POSITION.BOTTOM_RIGHT});
      if (pageId === 'new' && res.id) {
        handleChange({...form, id: res.id});
        navigate(`/page/${res.id}`);
      }
    }).catch((err) => {
      setLoading(false);
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  useEffect(() => {
    // load page
    let content = {};
    let contentBlock = htmlToDraft('<p></p>');
    let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    let editorState = EditorState.createWithContent(contentState);

    if (pageId && pageId !== 'new') {
      setLoading(true);
      dispatch(pageGetAction(pageId)).then((res) => {
        setLoading(false);
        
        for (let i = 0; i < languages.length; i++) {
          contentBlock = htmlToDraft(res.content[languages[i].code] ? res.content[languages[i].code] : '<p></p>');
          contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          editorState = EditorState.createWithContent(contentState);
          content[languages[i].code] = editorState;
        }

        handleChange({
          id: res.id,
          title: res.title,
          sub_title: res.sub_title,
          navigation: res.navigation,
          content,
          date_time: res.date_time ? new Date(res.date_time) : new Date(),
          date_time_edited: !!res.date_time_edited ? new Date(res.date_time_edited) : '',
          active: res.active ? true : false
        });
        if (res.img) {
          setImg(res.img);
        }
      }).catch(err => {
        setLoading(false);
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
    
    if (!pageId || pageId === 'new') {
      let title = {};
      let sub_title = {};
      let content = {};
      for (let i = 0; i < languages.length; i++) {
        title[languages[i].code] = '';
        sub_title[languages[i].code] = '';
        content[languages[i].code] = editorState;
      }
      handleChange({...form, title, sub_title, content});
    }
  }, [language]);

  async function uploadFile(form, name) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      if (form[name] && form[name].name) {
          formData.append("file", form[name], form[name].name);
      }
      formData.append('name', name);
      formData.append('page_id', form.id);
      const user = getUser();
      const token = user.token;
      if (!token) {
        reject({});
      }

      const req = new XMLHttpRequest();
      req.addEventListener("load", event => {
          if (req.status === 200) {
              resolve(JSON.parse(req.response));
          } else {
              reject(formatStatusError(req.status, req.response.trim()));
          }
      });

      req.upload.addEventListener("error", event => {
          reject(req.response);
      });

      req.open("POST", cfg.base_url + '/auth/page/upload');
      req.setRequestHeader("Authorization", 'Bearer ' + token);
      req.send(formData);
    });
  }


  const uploadCallback = (file, callback) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader();
      reader.onloadend = async () => {
        const res = await uploadFile({image: file, id: 0}, 'image', '');
        resolve({ data: { link: cfg.base_url + '/page/0/i/' + res.file } });
      };
      reader.readAsDataURL(file);
    });
  };

  const config = {
    image: { 
      uploadCallback: uploadCallback,
      alt: { present: true, mandatory: false },
      previewImage: true,
    },
  };

  const handleFileSelected = (file, name) => {
    handleChange({...form, [name]: file});
  };

  const handleSubmitFile = (name) => {
    if (loading) {
      return false;
    }
    try {
      setLoading(true);
      dispatch(pageUploadImageAction(form, name)).then((response) => {
        setLoading(false);
        try {
          let data = JSON.parse(response);
          handleFileSelected({}, name);
          if (name === 'img') {
            setImg(data.file);
          }
        } catch (e) {
          uploadError(e);
        }
      }).catch((e) => {
        uploadError(e);
      });
    } catch (e) {
      uploadError(e);
    }
  }

  const uploadError = (error) => {
    setLoading(false);
    toast.error(formatErrors([error.message]), {position: toast.POSITION.BOTTOM_RIGHT});
  };

  // delete file
  const handleDeleteFile = (name) => {
    if (loading) {
      return;
    }
    setLoading(true);
    dispatch(pageDeleteImageAction(pageId, name)).then((res) => {
      if (res.status === 'ok') {
        if (name === 'img') {
          setImg('');
        }
        handleChange({...form, [name]: ''});
      }
      setLoading(false);
    }).catch(err => {
      uploadError(err);
    });
  }

  const handleChangeDate = (value, name) => {
    handleChange({...form, [name]: value});
  };

  return <React.Fragment>
    <MyAppBar/>

      <div style={{padding: '10px 10px 10px 10px'}}>
          <ButtonsTop pageId={pageId}/>
          <br />
          <Form noValidate>
            <Grid>
              <Grid.Row columns={4}>
                <Grid.Column>
                  <Form.Input label="Unique name" name="navigation" value={form.navigation} onChange={handleChangeForm} required error={!!errors.navigation} />
                </Grid.Column>
                <Grid.Column>
                  <Form.Field required={true} error={!!errors.date_time}>
                    <label>Date</label>
                    <SemanticDatepicker
                      value={form.date_time}
                      format={"DD-MM-YYYY"}
                      showToday={true}
                      onChange={(event, data) => handleChangeDate(data.value, 'date_time')} />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                  <Form.Field error={!!errors.date_time_edited}>
                    <label>Date Edited</label>
                <SemanticDatepicker
                  value={form.date_time_edited}
                  format={"DD-MM-YYYY"}
                  showToday={true}
                  onChange={(event, data) => handleChangeDate(data.value, 'date_time_edited')} />
                  </Form.Field>
                </Grid.Column>
                <Grid.Column>
                <Form.Checkbox checked={form.active}
                 label={'Active'} toggle
                 onChange={() => handleChangeCb('active')}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <br />

            {/*languages && <>
              {languages.map((i) => {
                return <Button key={i.code} className={'lng-btn'} color={lang === i.code ? 'green' : 'grey'} onClick={() => {setLang(i.code);}}>{i.code}</Button>
              })}
            </>*/}
            <Grid>
              <Grid.Row columns={4}>
                <Grid.Column>
                  <UploadForm file={form.img} name={'img'} img={img} pageId={pageId} loading={loading}
                          handleFileSelected={handleFileSelected} handleSubmitFile={handleSubmitFile} handleDeleteFile={handleDeleteFile} />
                </Grid.Column>
                <Grid.Column></Grid.Column>
              </Grid.Row>
            </Grid>

            {languages && languages.map((i) => {
              return lang===i.code && <div style={{paddingTop: '20px'}} key={'bl_'+i.code}>
                {form.title !== null && <Form.Input label={'Title (' + i.code + ')'} name="{`title[${i.code}]`}" value={form.title[i.code]} onChange={(e) => handleChangeFormTitle(e, i.code)} 
                  required={i.code==='en'} error={!!errors.title && i.code==='en'} />}
              </div>;
            })}

            {languages && languages.map((i) => {
              return lang===i.code && <div style={{paddingTop: '20px'}} key={'bl2_'+i.code}>
                {form.sub_title !== null && <Form.TextArea label={'Sub title (' + i.code + ')'} name="{`sub_title[${i.code}]`}" value={form.sub_title[i.code]} onChange={(e) => handleChangeFormSubTitle(e, i.code)} 
                  required={i.code==='en'} error={!!errors.sub_title && i.code==='en'} />}
              </div>;
            })}


            <div style={{paddingTop: '10px'}}>
                <Button color="orange" onClick={handleSubmit}>Save</Button>
                <Button as={Link} to={"/page"}>Cancel</Button>
            </div>

            {languages && languages.map((i) => {
              return lang===i.code && <div style={{paddingTop: '20px'}} key={'bl3_'+i.code}>
                {form.content !== null && form.content[i.code] && <div style={{paddingTop: '10px'}}>
                  <Editor
                    toolbar={config}
                    editorState={form.content[i.code]}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    onEditorStateChange={(e) => handleChangeFormContent(e, i.code)}
                  />
                </div>}
              </div>;
            })}
          </Form>

      </div>
  </React.Fragment>;
}

const ButtonsTop = ({handleChange, pageId}) => {
    return <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Breadcrumb>
            <Breadcrumb.Section link={true} as={Link} to="/page">
              Blog articles
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>
              {(!pageId || pageId === 'new') && <>New article</>}
              {(pageId && pageId !== 'new') && <>Article #{pageId}</>}
            </Breadcrumb.Section>
          </Breadcrumb>
        </Grid.Column>
  
        <Grid.Column textAlign='right'>
          
        </Grid.Column>
      </Grid.Row>
    </Grid>;
};

export function UploadForm({file, name, img, pageId, loading,
  handleFileSelected, handleSubmitFile, handleDeleteFile}) {
    return <>
      <Form.Field style={{float: 'left'}}>
        <FileInput accept={".jpg,.jpeg,.png"} loading={file && loading}
                  handleChange={(file) => handleFileSelected(file, name)}>{'Upload image'}</FileInput>
      </Form.Field>

      {file && file.name && <div>
        <Grid columns="equal" verticalAlign="middle">
          <Grid.Column width={8}>
            {file.name}
          </Grid.Column>
          <Grid.Column>
            <Button icon onClick={() => handleFileSelected({}, name)} secondary disabled={loading}><Icon name="remove" /></Button>
            <Button icon onClick={() => handleSubmitFile(name)} primary disabled={loading}><Icon name="upload" /></Button>
          </Grid.Column>
        </Grid>
      </div>}

      {img && (!file || !file.name) && <div>
        <Grid columns="equal" verticalAlign="middle">
          <Grid.Column width={4}>
            <a href={cfg.public_url + '/page/' + pageId + '/i/' + img} target="_blank" rel="noreferrer">
              <Image src={cfg.public_url + '/page/' + pageId + '/i/' + img} size="tiny" wrapped />
            </a>
          </Grid.Column>
          <Grid.Column textAlign="left">
            <Button size='mini' icon onClick={() => handleDeleteFile(name)} disabled={loading}><Icon name="remove" /></Button>
          </Grid.Column>
        </Grid>
      </div>}
    </>;
}

export default PageItem;
