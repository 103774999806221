import React, {forwardRef, useEffect, useState, useRef} from 'react';
import {Dropdown, Grid, List, Table} from "semantic-ui-react";

function DemographicsTab(props) {

  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      let tmp = [...props.data];

      for (let i in tmp) {
        if (tmp[i].title === "Age profile") {
          let total = 0;
          for (let line of tmp[i].data) {
            let val = parseInt(line[1]);
            if (!isNaN(val)) {
              total += val;
            }
          }

          for (let line of tmp[i].data) {
            let val = parseInt(line[1]);
            if (!isNaN(val)) {
              line[1] = (val / total * 100).toFixed(2);
            }
          }
        }
      }

      tmp.sort((a,b) => a.data.length > b.data.length?1:-1)
      setData(tmp);
    }
  }, [props.data]);

  return <React.Fragment>

    <Grid.Row>
      <Grid.Column>

        <div>
          <h2>Demographics</h2>
          <h5>Data on people living in the postcode</h5>
        </div>
        <br/><br/>

        <div className={"demographics-container-3"}>
          {!!props.inRadius && <div className={"item"} style={{gridColumn:"1/3"}}>
            <h3>People and buildings in radius</h3>
            <br/>
            <Table basic={'very'} className={"location-report-nearby-table"}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Radius (yards)</Table.HeaderCell>
                  <Table.HeaderCell>100 yards</Table.HeaderCell>
                  <Table.HeaderCell>500 yards</Table.HeaderCell>
                  <Table.HeaderCell>1000 yards</Table.HeaderCell>
                  <Table.HeaderCell>3 miles</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Number of people</Table.Cell>
                  <Table.Cell>{props.inRadius.p100}</Table.Cell>
                  <Table.Cell>{props.inRadius.p500}</Table.Cell>
                  <Table.Cell>{props.inRadius.p1000}</Table.Cell>
                  <Table.Cell>{props.inRadius.p5000}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Number of buildings</Table.Cell>
                  <Table.Cell>{props.inRadius.b100}</Table.Cell>
                  <Table.Cell>{props.inRadius.b500}</Table.Cell>
                  <Table.Cell>{props.inRadius.b1000}</Table.Cell>
                  <Table.Cell>{props.inRadius.b5000}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </div>}

          <div className={"item"}>
            <h3>Household Income</h3>
            <br/>
            {props.income.rate === "n/a" && <div><span className={'location-report-text'}>Not available for current postcode</span><br /></div>}
            {props.income.rate !== "n/a" && <React.Fragment>
                <div><span className={'location-report-text'}>Income rate: </span><span className={"location-report-value"}>{props.income.rate}</span></div><br />
              {!!props.income.wealth && <React.Fragment><div>{props.income.wealth}</div><br /></React.Fragment>}
              {!!props.income.median && <React.Fragment>
                <span className={'location-report-text'}>Median: </span><span
                className={'location-report-value'}>&nbsp;£{props.income.median}</span><span
                className={'location-report-text'}>/ year</span><br/>
              </React.Fragment>}
            </React.Fragment>}
          </div>

          <div className={"item"}>
            <h3>Crime</h3>
            <br/>
            {props.crime.rating === "n/a" && <div><span className={'location-report-text'}>Not available for current postcode</span><br /></div>}
            {props.crime.rating !== "n/a" && <React.Fragment>
                <div className={"demographics-mid-value "}>{props.crime.rating}</div><br />
              {!!props.crime.types && props.crime.types.length > 0 && <React.Fragment>
                <span className={'location-report-text'}>Most common crime types:</span>
                <List bulleted>
                  {props.crime.types.map((item, idx) => {
                    return <List.Item key={idx}><span className={'location-report-text'}>{item}</span></List.Item>;
                  })}
                </List><br />
              </React.Fragment>}
            </React.Fragment>}
          </div>

          {data.map((item, key) => {
            if (item.data !== null) {
              return <React.Fragment key={key} >
                <div className={"item"}>
                  <h3>{item.title}</h3>
                  <br />
                  {item.data.length > 1 && <DrawChart item={item.data} />}
                  {item.data.length === 1 && <DrawSingleItem item={item} /> }
                </div>
              </React.Fragment>
            }
          })}
        </div>
      </Grid.Column>
    </Grid.Row>

  </React.Fragment>
}

function DrawSingleItem({item}) {
  return <React.Fragment>
    {item.title==="Population" && <>
      <div className={"demographics-single-value"}>{item.data[0][1]}</div>
      <div>people</div><br />
    </>}
    {item.title==="Number of households" && <>
      <div className={"demographics-single-value"}>{item.data[0][1]}</div>
      <div>households</div><br/>
    </>}
  </React.Fragment>
}

function DrawChart({item}) {

  const [style, setStyle] = useState(null)
  const [maxVal, setMaxVal] = useState(100);

  function GetValue(val) {
    if (maxVal > 70) {
      return val;
    }

    return val/(maxVal+10)*100;
  }

  useEffect(() => {
    let maxval = 0;
    for (let idx in item) {
      let val = parseFloat(item[idx][1]);
      item[idx][1] = isNaN(val)?0:val;
      maxval = Math.max(maxval, item[idx][1])
    }

    setMaxVal(maxval);

    if (style === null && item.length > 0) {
      if (item.length > 6) {
        setStyle("small");
      } else if (item.length > 3) {
        setStyle("medium");
      } else {
        setStyle("large");
      }
    }
  }, [item, style]);

  return <React.Fragment>
    <div className={"demographics-charts-container"}>
      {item.map((item, key) => {
          return <React.Fragment key={key}>
            <div className={"demographics-chart-title "+style}>{item[0]}</div>
            <div className={"demographics-chart "+style}><div style={{width:GetValue(item[1])+"%"}} >&nbsp;{item[1]}%</div></div>
            <br/>
          </React.Fragment>
      })}
    </div>
  </React.Fragment>
}

export default DemographicsTab
