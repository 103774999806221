import React from 'react';

function BuyOverlay({buyCallback, type, handleClose}) {
  return <React.Fragment>
    <div className={"poi-map-buy-overlay"}>
      <div>
        <div className={"close-block"} onClick={() => {handleClose()}}>✕</div>
        <h2>Upgrade to Get Access to</h2>
        <ul>
          <li>Footfall Heat Map (whole UK)</li>
          <li>Households Density Heat Map (whole UK)</li>
          <li>Food Businesses Heat Map (whole UK)</li>
          <li>Unlimited Location Insight Reports</li>
          <li>Tracking Your Competitors with PricePulse</li>
        </ul>
        <div style={{textAlign:"center"}}>
          <a href={"#"}
             className={"appbar-buy-fullsub gb-magenta"}
             onClick={(e) => {buyCallback(e, type)}}
          >Upgrade for £69</a>
        </div>

      </div>
    </div>
  </React.Fragment>;
}

export default BuyOverlay
