import React, {useEffect, useRef, useState} from 'react';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import mapboxgl from "mapbox-gl";
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {cellToBoundary, cellToChildren, cellToLatLng, gridDisk, latLngToCell} from "h3-js";
import {getFootfallDynamicAction, getFootfallResolutionsAction, getHouseholdsDynamicAction} from "../../actions/placeMapFootfallAction";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {
  formatErrors,
  ROLE_ADMIN,
  ROLE_MANAGER,
  ROLE_SUBSCRIBER,
  ROLE_SUBSCRIBER_MAP,
  trafficGradient
} from "../../constants";
import {Dropdown, Loader, Radio} from "semantic-ui-react";
import {getUser} from "../../actions/loginAction";
import 'mapbox-gl/dist/mapbox-gl.css';
import {useLocation, useSearchParams} from "react-router-dom";
//import WelcomePasswordChange from "./utils/WelcomePasswordChange";

mapboxgl.accessToken = 'pk.eyJ1Ijoic3Nlcmd5IiwiYSI6ImNsOHU5enNjbTAyMGQzcHJ4ODlsanNpNHgifQ.NXh_OvvnBFO_uArBg676IA';

function PlaceMapDynamicFootfall() {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const reportPopup = useRef(null);
  const activeHexes = useRef([]);

  const [requestParams] = useSearchParams();
  const location = useLocation();

  const [showWelcome, setShowWelcome] = useState(location.state !== null && location.state.hasOwnProperty("welcome"));

  const hexCache = useRef([]);
  const hexToProcess = useRef([])

  const hexCacheHH = useRef([]);
  const hexToProcessHH = useRef([])

  const hex7Data = useRef([]);
  const hex8Data = useRef([]);

  const hex7HHData = useRef([]);
  const hex8HHData = useRef([]);

  const hex7CData = useRef([]);

  const hex10Data = useRef([]);
  const hex10HHData = useRef([]);
  const zoomLevel = useRef(0);

  const timeOutEvent = useRef(null);
  const timeOutZoom = useRef(null);

  const ActiveLayer = useRef(0);
  const [activeLayerState, setActiveLayerState] = useState(0);

  const [latLng, setLatLng] = useState([]);
  const [marker, setMarker] = useState(null);
  const [loading, setLoading] = useState(true);
  const [idleLoading, setIdleLoading] = useState(true);
  const [timeInterval, setTimeInterval] = useState(24);
  const timeIntervalCurrent = useRef(24);

  const workerTimeInterval = useRef(100);
  const workerId = useRef(null);

  const profile = getUser();

  const aLayerFootfall = 0;
  const aLayerHouseholds = 1;
  const aLayerCompetitors = 2;

  const ffLayer = 'footfall-layer';
  const ff7layer = 'footfall7-layer';
  const ff8layer = 'footfall8-layer';

  const hhLayer = 'households-layer';
  const hh7layer = 'households7-layer';
  const hh8layer = 'households8-layer';

  const cLayer = 'competitors-layer';
  const c7layer = 'competitors7-layer';

  const ffLayerF = 'footfall-layer-fill';
  const ff7layerF = 'footfall7-layer-fill';
  const ff8layerF = 'footfall8-layer-fill';

  const hhLayerF = 'households-layer-fill';
  const hh7layerF = 'households7-layer-fill';
  const hh8layerF = 'households8-layer-fill';

  const cLayerF = 'competitors-layer-fill'
  const c7layerF = 'competitors7-layer-fill'

  const dispatch = useDispatch();

  const layersList = [
    ffLayerF, ff7layerF, ff8layerF, hhLayerF, hh7layerF, hh8layerF, cLayerF, c7layerF
  ]

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    bbox: [-8.585350424507451, 49.89060631678373, 1.762474098318385, 60.85571099962073],
    mapboxgl: mapboxgl,
    placeholder: 'Search Location'
  });

  useEffect(() => {
    if (!!map.current) {
      ActiveLayer.current = activeLayerState

      checkHexesToProcess()
      CheckLayers();
    }
  }, [activeLayerState])

  useEffect(() => {
    if (latLng.length > 0) {
      if (marker !== null) {
        marker.remove();
      }

      if (!!reportPopup.current) {
        reportPopup.current.remove();
      }

      let m = new mapboxgl.Marker({color: "#4668F2"}).setLngLat([latLng[1], latLng[0]]).addTo(map.current);
      if (!!requestParams.get('lat') && !!requestParams.get('lng')) {
        map.current.setZoom(14);
        map.current.setCenter([latLng[1], latLng[0]]);
      }
      setMarker(m);
    }

  }, [latLng]);

  useEffect(() => {
    timeIntervalCurrent.current = timeInterval;

    if (!loading && !!map.current && ActiveLayer.current === aLayerFootfall) {

      UpdateLayer(ff7layerF, timeIntervalCurrent.current);
      UpdateLayer(ff8layerF, timeIntervalCurrent.current);
      UpdateLayer(ffLayerF, timeIntervalCurrent.current);

      CheckLayers('---> INTERVALS');
    }
  }, [timeInterval]);

  function UpdateLayer(param, timeInterval) {

    //map.current.scrollZoom.disable();

    /*
    if (!!reportPopup.current) {
      reportPopup.current.remove();
    }*/

    let jsonData = {
      "type": "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": []
      }
    };

    if (param === ff7layerF) {

      for (let h of hex7Data.current) {
        jsonData.data.features.push({
          "type": "Feature",
          "properties": {"_score": h.intervals[timeInterval]},
          "geometry": {
            "type": "Polygon",
            "coordinates": [cellToBoundary(h.code, true)]
          }
        });
      }

      let src = map.current.getSource(ff7layer);

      if (typeof src !== 'undefined') {
        src.setData(jsonData.data);
      }
    } else if (param === ffLayerF) {

      for (let h of hex10Data.current) {
        jsonData.data.features.push({
          "type": "Feature",
          "properties": {"_score": h.intervals[timeInterval]},
          "geometry": {
            "type": "Polygon",
            "coordinates": [cellToBoundary(h.code, true)]
          }
        });
      }

      let src = map.current.getSource(ffLayer);

      if (typeof src !== 'undefined') {
        src.setData(jsonData.data);
      }
    } else if (param === ff8layerF) {

      for (let h of hex8Data.current) {
        jsonData.data.features.push({
          "type": "Feature",
          "properties": {"_score": h.intervals[timeInterval]},
          "geometry": {
            "type": "Polygon",
            "coordinates": [cellToBoundary(h.code, true)]
          }
        });
      }

      let src = map.current.getSource(ff8layer);

      if (typeof src !== 'undefined') {
        src.setData(jsonData.data);
      }
    } else if (param === hh7layerF) {

      for (let h of hex7HHData.current) {
        jsonData.data.features.push({
          "type": "Feature",
          "properties": {"_score": h.rank},
          "geometry": {
            "type": "Polygon",
            "coordinates": [cellToBoundary(h.code, true)]
          }
        });
      }

      let src = map.current.getSource(hh7layer);

      if (typeof src !== 'undefined') {
        src.setData(jsonData.data);
      }
    } else if (param === hh8layerF) {

      for (let h of hex8HHData.current) {
        jsonData.data.features.push({
          "type": "Feature",
          "properties": {"_score": h.rank},
          "geometry": {
            "type": "Polygon",
            "coordinates": [cellToBoundary(h.code, true)]
          }
        });
      }

      let src = map.current.getSource(hh8layer);

      if (typeof src !== 'undefined') {
        src.setData(jsonData.data);
      }
    } else if (param === hhLayerF) {
      for (let h of hex10HHData.current) {
        jsonData.data.features.push({
          "type": "Feature",
          "properties": {"_score": h.rank},
          "geometry": {
            "type": "Polygon",
            "coordinates": [cellToBoundary(h.code, true)]
          }
        });
      }

      let src = map.current.getSource(hhLayer);

      if (typeof src !== 'undefined') {
        src.setData(jsonData.data);
      }
    } else if (param === cLayerF) {
      for (let h of hex10Data.current) {
        jsonData.data.features.push({
          "type": "Feature",
          "properties": {"_score": h.cr},
          "geometry": {
            "type": "Polygon",
            "coordinates": [cellToBoundary(h.code, true)]
          }
        });
      }

      let src = map.current.getSource(cLayer);

      if (typeof src !== 'undefined') {
        src.setData(jsonData.data);
      }
    } else if (param === c7layerF) {
      for (let h of hex7CData.current) {
        jsonData.data.features.push({
          "type": "Feature",
          "properties": {"_score": h.rank},
          "geometry": {
            "type": "Polygon",
            "coordinates": [cellToBoundary(h.code, true)]
          }
        });
      }

      let src = map.current.getSource(c7layer);

      if (typeof src !== 'undefined') {
        src.setData(jsonData.data);
      }
    }

  }

  function CheckLayers() {

    if (ActiveLayer.current === aLayerFootfall) {
      if (!map.current.getLayer(ffLayerF) || !map.current.getLayer(ff8layerF) || !map.current.getLayer(ff7layerF)) {
        return;
      }

      if (zoomLevel.current === 0 && map.current.getLayoutProperty(ff7layerF, 'visibility') !== 'visible') {
        ShowLayer(ff7layerF);
      } else if (zoomLevel.current === 1 && map.current.getLayoutProperty(ff8layerF, 'visibility') !== 'visible') {
        ShowLayer(ff8layerF);
      } else if (zoomLevel.current === 2 && map.current.getLayoutProperty(ffLayerF, 'visibility') !== 'visible') {
        ShowLayer(ffLayerF);
      }
    } else if (ActiveLayer.current === aLayerHouseholds) {
      if (!map.current.getLayer(hh8layerF) || !map.current.getLayer(hh7layerF)) {
        return;
      }

      if (zoomLevel.current < 2 && map.current.getLayoutProperty(hh7layerF, 'visibility') !== 'visible') {
        ShowLayer(hh7layerF);
      } else if (zoomLevel.current === 2 && map.current.getLayoutProperty(hhLayerF, 'visibility') !== 'visible') {
        ShowLayer(hhLayerF);
      }
    } else if (ActiveLayer.current === aLayerCompetitors) {
      if (!map.current.getLayer(cLayerF) || !map.current.getLayer(c7layerF)) {
        return;
      }

      if (zoomLevel.current < 2 && map.current.getLayoutProperty(c7layerF, 'visibility') !== 'visible') {
        ShowLayer(c7layerF);
      } else if (zoomLevel.current === 2 && map.current.getLayoutProperty(cLayerF, 'visibility') !== 'visible') {
        ShowLayer(cLayerF);
      }
    }

  }

  function ShowLayer(layer) {
    map.current.setLayoutProperty(layer, 'visibility', 'visible');
    disableExclude(layer);
  }

  function disableExclude(layer) {
    setTimeout(function () {
      for (let i of layersList) {
        if (layer !== i) {
          map.current.setLayoutProperty(i, 'visibility', 'none');
        }
      }
    }, 100);
  }

  useEffect(() => {
    if (map.current) return;

    setLoading(true);
    setIdleLoading(true);

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-0.13765485305231095, 51.49468631310461],
      zoom: 10,
    });

    map.current.on("load", () => {

      hexCache.current = [];

      map.current.on('dblclick', (e) => {
        e.preventDefault();

        setLatLng([e.lngLat.lat, e.lngLat.lng]);

      });

      map.current.on('mousedown', (e) => {

        if (e.originalEvent.button === 2) {

          if (!profile || !profile.token || [ROLE_SUBSCRIBER, ROLE_ADMIN, ROLE_MANAGER].indexOf(profile.role_id) < 0) {
            return;
          }

          if (zoomLevel.current !== 2) {
            map.current.setZoom(13.5);
            map.current.flyTo({
              center: [e.lngLat.lng, e.lngLat.lat]
            });
          }

          let hex = latLngToCell(e.lngLat.lat, e.lngLat.lng, 10);

          if (reportPopup.current !== null) {
            reportPopup.current.remove();
          }

          reportPopup.current = new mapboxgl.Popup()
            .setLngLat([e.lngLat.lng, e.lngLat.lat])
            .setHTML("<div style='font-size: 12px;min-width: 200px;'>" +
              "<a class='map-popup-button' href='/location-report?hex=" + hex + "' target='_blank'>Open location report</a>" +
              "</div>")
            .addTo(map.current);
        }
      });

      map.current.addSource(ffLayer, {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      // footfall 10
      map.current.addLayer({
        'id': ffLayerF,
        'type': 'fill',
        'source': ffLayer, // reference the data source
        'layout': {
          'visibility': 'none',
        },
        'paint': {
          'fill-color': [
            'step',
            ['get', '_score'],
            'gray',
            0.01, '#c6d08a',
            0.1, '#E0FF00',
            0.2, '#FFB500',
            0.3, '#FF8800',
            0.4, '#FF6A00',
            0.5, '#FF4A00',
            0.6, '#FF2500',
            0.7, '#FF0900',
            0.8, '#930410',
            0.9, '#77020b',
          ],
          'fill-opacity': 0.5
        }
      });

      // footfall 7
      map.current.addSource(ff7layer, {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      map.current.addLayer({
        'id': ff7layerF,
        'type': 'fill',
        'source': ff7layer, // reference the data source
        'layout': {
          'visibility': 'visible',
        },
        'paint': {
          'fill-color': [
            'step',
            ['get', '_score'],
            'gray',
            0.01, '#c6d08a',
            0.1, '#E0FF00',
            0.2, '#FFB500',
            0.3, '#FF8800',
            0.4, '#FF6A00',
            0.5, '#FF4A00',
            0.6, '#FF2500',
            0.7, '#FF0900',
            0.8, '#930410',
            0.9, '#77020b',
          ],
          'fill-opacity': 0.5
        }
      });

      // footfall 8
      map.current.addSource(ff8layer, {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      map.current.addLayer({
        'id': ff8layerF,
        'type': 'fill',
        'source': ff8layer, // reference the data source
        'layout': {
          'visibility': 'none',
        },
        'paint': {
          'fill-color': [
            'step',
            ['get', '_score'],
            'gray',
            0.01, '#c6d08a',
            0.1, '#E0FF00',
            0.2, '#FFB500',
            0.3, '#FF8800',
            0.4, '#FF6A00',
            0.5, '#FF4A00',
            0.6, '#FF2500',
            0.7, '#FF0900',
            0.8, '#930410',
            0.9, '#77020b',
          ],
          'fill-opacity': 0.5
        }
      });

      // household 7
      map.current.addSource(hh7layer, {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      map.current.addLayer({
        'id': hh7layerF,
        'type': 'fill',
        'source': hh7layer, // reference the data source
        'layout': {
          'visibility': 'none',
        },
        'paint': {
          'fill-color': [
            'step',
            ['get', '_score'],
            'gray',
            0.01, '#c6d08a',
            0.1, '#E0FF00',
            0.2, '#FFB500',
            0.3, '#FF8800',
            0.4, '#FF6A00',
            0.5, '#FF4A00',
            0.6, '#FF2500',
            0.7, '#FF0900',
            0.8, '#930410',
            0.9, '#77020b',
          ],
          'fill-opacity': 0.5
        }
      });

      // household 8
      map.current.addSource(hh8layer, {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      map.current.addLayer({
        'id': hh8layerF,
        'type': 'fill',
        'source': hh8layer, // reference the data source
        'layout': {
          'visibility': 'none',
        },
        'paint': {
          'fill-color': [
            'step',
            ['get', '_score'],
            'gray',
            0.01, '#c6d08a',
            0.1, '#E0FF00',
            0.2, '#FFB500',
            0.3, '#FF8800',
            0.4, '#FF6A00',
            0.5, '#FF4A00',
            0.6, '#FF2500',
            0.7, '#FF0900',
            0.8, '#930410',
            0.9, '#77020b',
          ],
          'fill-opacity': 0.5
        }
      });

      // household 10
      map.current.addSource(hhLayer, {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      map.current.addLayer({
        'id': hhLayerF,
        'type': 'fill',
        'source': hhLayer, // reference the data source
        'layout': {
          'visibility': 'none',
        },
        'paint': {
          'fill-color': [
            'step',
            ['get', '_score'],
            'gray',
            0.01, '#c6d08a',
            0.1, '#E0FF00',
            0.2, '#FFB500',
            0.3, '#FF8800',
            0.4, '#FF6A00',
            0.5, '#FF4A00',
            0.6, '#FF2500',
            0.7, '#FF0900',
            0.8, '#930410',
            0.9, '#77020b',
          ],
          'fill-opacity': 0.5
        }
      });

      // competitors 10
      map.current.addSource(cLayer, {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      map.current.addLayer({
        'id': cLayerF,
        'type': 'fill',
        'source': cLayer, // reference the data source
        'layout': {
          'visibility': 'none',
        },
        'paint': {
          'fill-color': [
            'step',
            ['get', '_score'],
            'gray',
            0.01, '#c6d08a',
            0.1, '#E0FF00',
            0.2, '#FFB500',
            0.3, '#FF8800',
            0.4, '#FF6A00',
            0.5, '#FF4A00',
            0.6, '#FF2500',
            0.7, '#FF0900',
            0.8, '#930410',
            0.9, '#77020b',
          ],
          'fill-opacity': 0.5
        }
      });

      // competitors 7
      map.current.addSource(c7layer, {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      map.current.addLayer({
        'id': c7layerF,
        'type': 'fill',
        'source': c7layer, // reference the data source
        'layout': {
          'visibility': 'none',
        },
        'paint': {
          'fill-color': [
            'step',
            ['get', '_score'],
            'gray',
            0.01, '#c6d08a',
            0.1, '#E0FF00',
            0.2, '#FFB500',
            0.3, '#FF8800',
            0.4, '#FF6A00',
            0.5, '#FF4A00',
            0.6, '#FF2500',
            0.7, '#FF0900',
            0.8, '#930410',
            0.9, '#77020b',
          ],
          'fill-opacity': 0.5
        }
      });

      /*
      map.current.addLayer({
        id: 'points-text',
        type: 'symbol',
        source: hhLayer,
        filter: ['has', '_score'],
        layout: {
          'text-field': '{_score}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 18
        }
      });*/

      geocoder.on('result', (e) => {
        // remove `click` marker if exists

        geocoder.mapMarker.remove();
        setLatLng([e.result.center[1], e.result.center[0]]);
      });

      document.getElementById('geocoder-container-footfall-map')?.appendChild(geocoder.onAdd(map.current));

      // worker
      StartWorker(workerTimeInterval.current);

      map.current.on('move', (e) => {

        clearTimeout(timeOutZoom.current);
        timeOutZoom.current = setTimeout(function () {
          let cz = map.current.getZoom()

          if (cz < 12 && zoomLevel.current !== 0) {
            zoomLevel.current = 0;
          } else if (cz < 13.5 && cz >= 12 && zoomLevel.current !== 1) {
            zoomLevel.current = 1;
          } else if (cz >= 13.5 && zoomLevel.current !== 2) {
            zoomLevel.current = 2;
          }
        }, 300);
      });


      map.current.on('idle', (e) => {
        CheckLayers();
        setIdleLoading(false);
      })

      map.current.on('moveend', (e) => {

        clearInterval(timeOutEvent.current);
        timeOutEvent.current = setTimeout(function (){
          checkHexesToProcess();
        }, 100);

      })

      dispatch(getFootfallResolutionsAction({layers: ["households", "footfall", "competitors"]})).then((res) => {

        hex7Data.current = JSON.parse(res.resolution_7);
        hex8Data.current = JSON.parse(res.resolution_8);
        UpdateLayer(ff7layerF, timeIntervalCurrent.current);
        UpdateLayer(ff8layerF, timeIntervalCurrent.current);

        hex7HHData.current = JSON.parse(res.resolution_7_hh);
        UpdateLayer(hh7layerF, timeIntervalCurrent.current);

        hex7CData.current = JSON.parse(res.resolution_7_c);
        UpdateLayer(c7layerF, timeIntervalCurrent.current);

        let lat = parseFloat(requestParams.get("lat"));
        let lng = parseFloat(requestParams.get("lng"));

        if (!!lat && !!lng && lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
          setLatLng([lat,lng]);
        }

        setLoading(false);
      }).catch((err) => {

        let jsonData = {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": []
          }
        };

        let src = map.current.getSource(ffLayer);

        if (typeof src !== 'undefined') {
          src.setData(jsonData.data);
        }

        setLoading(false);
        activeHexes.current = [];

        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    });
  }, [map])

  function StartWorker(workerTimeInterval) {
    if (workerId.current !== null) {
      clearInterval(workerId.current)
    }

    workerId.current = setInterval(WorkerHandler, workerTimeInterval);
  }

  function WorkerHandler() {
    if (hexToProcess.current.length > 0 && zoomLevel.current === 2) {

      setLoading(true);
      setIdleLoading(true);

      let hexes = [...hexToProcess.current];
      hexToProcess.current = [];
      for (let hex of hexes) {
        if (hexCache.current.indexOf(hex) < 0) {
          hexCache.current.push(hex);
        }
      }

      dispatch(getFootfallDynamicAction({hexes: hexes})).then((res) => {
        if (workerTimeInterval.current !==100) {
          workerTimeInterval.current = 100;
          StartWorker(100);
        }

        if (!!res.footfall) {
          hex10Data.current.push(...res.footfall);

          UpdateLayer(ffLayerF, timeIntervalCurrent.current);
          UpdateLayer(cLayerF, timeIntervalCurrent.current);
        }

        setLoading(false);
      }).catch((err) => {
        console.log(err);

        if (workerTimeInterval.current<5000) {
          workerTimeInterval.current += 1000
          StartWorker(workerTimeInterval.current);
        }

        for (let hex of hexes) {
          if (hexToProcess.current.indexOf(hex) < 0) {
            hexToProcess.current.push(hex);
          }
        }
        setLoading(false);
      });

    } else if (hexToProcessHH.current.length > 0 && zoomLevel.current === 2) {

      setLoading(true);
      setIdleLoading(true);

      let hexes = [...hexToProcessHH.current];
      hexToProcessHH.current = [];
      for (let hex of hexes) {
        if (hexCacheHH.current.indexOf(hex) < 0) {
          hexCacheHH.current.push(hex);
        }
      }

      dispatch(getHouseholdsDynamicAction({hexes: hexes})).then((res) => {
        if (workerTimeInterval.current !==100) {
          workerTimeInterval.current = 100;
          StartWorker(100);
        }

        if (!!res.households) {
          hex10HHData.current.push(...res.households);
          UpdateLayer(hhLayerF, timeIntervalCurrent.current);
        }

        setLoading(false);
      }).catch((err) => {
        console.log(err);

        if (workerTimeInterval.current<5000) {
          workerTimeInterval.current += 1000
          StartWorker(workerTimeInterval.current);
        }

        for (let hex of hexes) {
          if (hexToProcessHH.current.indexOf(hex) < 0) {
            hexToProcessHH.current.push(hex);
          }
        }

        setLoading(false);
      });
    }
  }

  function checkHexesToProcess() {
    let zoom = map.current.getZoom();
    if (zoom < 13) {
      return;
    }

    let bounds = map.current.getBounds();
    let xmin = bounds._sw.lng;
    let xmax = bounds._ne.lng;

    let ymin = bounds._sw.lat;
    let ymax = bounds._ne.lat;

    let difx = (xmax-xmin)/3;
    let dify = (ymax-ymin)/3;

    let points = [];

    for (let i = 0; i <= 3; i++) {
      for (let j = 0; j <= 3; j++) {
        points.push([xmin+difx*i, ymin+dify*j]);
      }
    }

    let curHexes = [];
    for (let point of points) {
      let hex = latLngToCell(point[1], point[0], 7);
      if (curHexes.indexOf(hex) < 0) {
        curHexes.push(hex);
      }
    }

    let tmpHexes = [...curHexes];
    for (let hex of curHexes) {
      let neighbours = gridDisk(hex, 1);
      for (let n of neighbours) {
        if (tmpHexes.indexOf(n) < 0) {
          tmpHexes.push(n);
        }
      }
    }
    curHexes = tmpHexes;
    tmpHexes = null;

    if (ActiveLayer.current === aLayerFootfall || ActiveLayer.current === aLayerCompetitors) {
      for (let hex of curHexes) {
        if (hexCache.current.indexOf(hex) < 0 && hexToProcess.current.indexOf(hex) < 0) {
          hexToProcess.current.push(hex);
        }
      }
    } else {
      for (let hex of curHexes) {
        if (hexCacheHH.current.indexOf(hex) < 0 && hexToProcessHH.current.indexOf(hex) < 0) {
          hexToProcessHH.current.push(hex);
        }
      }
    }
  }

  const options = [
    { key: '1', text: '00:00-00:59', value: 0 },
    { key: '2', text: '01:00-01:59', value: 1 },
    { key: '3', text: '02:00-02:59', value: 2 },
    { key: '4', text: '03:00-03:59', value: 3 },
    { key: '5', text: '04:00-04:59', value: 4 },
    { key: '6', text: '05:00-05:59', value: 5 },
    { key: '7', text: '06:00-06:59', value: 6 },
    { key: '8', text: '07:00-07:59', value: 7 },
    { key: '9', text: '08:00-08:59', value: 8 },
    { key: '10', text: '09:00-09:59', value: 9 },
    { key: '11', text: '10:00-10:59', value: 10 },
    { key: '12', text: '11:00-11:59', value: 11 },
    { key: '13', text: '12:00-12:59', value: 12 },
    { key: '14', text: '13:00-13:59', value: 13 },
    { key: '15', text: '14:00-14:59', value: 14 },
    { key: '16', text: '15:00-15:59', value: 15 },
    { key: '17', text: '16:00-16:59', value: 16 },
    { key: '18', text: '17:00-17:59', value: 17 },
    { key: '19', text: '18:00-18:59', value: 18 },
    { key: '20', text: '19:00-19:59', value: 19 },
    { key: '21', text: '20:00-20:59', value: 20 },
    { key: '22', text: '21:00-21:59', value: 21 },
    { key: '23', text: '22:00-22:59', value: 22 },
    { key: '24', text: '23:00-23:59', value: 23 },
    { key: '25', text: '00:00-23:59', value: 24 },
  ]

  return <React.Fragment>

    <div className={"map-contu-u "+([ROLE_ADMIN, ROLE_MANAGER].indexOf(profile?.role_id) >= 0?"map-admin-fix":"")}>
      <div className={'place-map-analytics-dropdown two-child-ff'}>
        <div><div id={"geocoder-container-footfall-map"} /></div>

        {activeLayerState === aLayerFootfall && <div className={"time-intervals"}><Dropdown
          placeholder='Time Interval'
          selection
          options={options}
          value={timeInterval}
          disabled={loading||idleLoading}
          onChange={(e, v) => {
            setTimeInterval(v.value);
          } }
        /></div>}
      </div>
      <MyAppBar fixed={true} />
      <div ref={mapContainer} className={"map-container"} />

      <div className={"map-legend-container"}>
        <div className={'map-loader'}>
          <Loader content='Loading' active={loading||idleLoading} size={'large'}/>
        </div>
        <div className={'map-legend'}>
          <Radio
            label='Footfall'
            name='radioGroup'
            value={activeLayerState}
            checked={activeLayerState === aLayerFootfall}
            onChange={() => {setActiveLayerState(aLayerFootfall)}}
          />
          <br/><br />
          <Radio
            label='Households density'
            name='radioGroup'
            value={activeLayerState}
            checked={activeLayerState === aLayerHouseholds}
            onChange={() => {setActiveLayerState(aLayerHouseholds)}}
          />
          <br/><br/>
          <Radio
            label='Food Businesses density'
            name='radioGroup'
            value={activeLayerState}
            checked={activeLayerState === aLayerCompetitors}
            onChange={() => {setActiveLayerState(aLayerCompetitors)}}
          />
          <br/><br/>
          {/*<div className={'legend-dblclck legend-info'}>Double-click to view footfall</div>*/}
          {[ROLE_SUBSCRIBER, ROLE_ADMIN, ROLE_MANAGER].indexOf(profile.role_id) >= 0 && <div className={'legend-rclck legend-info'}>Right-click to see area options</div>}
          {activeLayerState === aLayerFootfall && <div className={'legend-gradient-items'}>
            <div style={{width: '50%'}}>No traffic</div>
            <div style={{width: '50%'}}>Very high traffic</div>
          </div>}

          {activeLayerState === aLayerHouseholds && <div className={'legend-gradient-items'}>
            <div style={{width: '50%'}}>No households</div>
            <div style={{width: '50%'}}>Very high density</div>
          </div>}

          {activeLayerState === aLayerCompetitors && <div className={'legend-gradient-items'}>
            <div style={{width: '50%'}}>No competitors</div>
            <div style={{width: '50%'}}>Very high density</div>
          </div>}

          <div className={"legend-gradient-container"}>
            {trafficGradient.map((itm, idx) => {
              return <div key={idx} style={{backgroundColor:itm}}/>;
            })}
          </div>
        </div>
      </div>

    </div>
    {/*showWelcome && <WelcomePasswordChange active={true} profile={profile} handleChange={setShowWelcome}/>*/}
  </React.Fragment>
}

export default PlaceMapDynamicFootfall
