import React, {useEffect, useRef, useState} from 'react';
import mapboxgl from "mapbox-gl";
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {latLngToCell, cellToBoundary, cellToLatLng} from "h3-js";
import {
  getCrimeAction,
  getFootfallAction,
  getHexCrimeAction,
  getHexCrimeErrAction, getPolyAction
} from "../../actions/placeMapFootfallAction";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {formatErrors, ROLE_ADMIN, ROLE_MANAGER, ROLE_SUBSCRIBER} from "../../constants";
import {Checkbox, Dropdown} from "semantic-ui-react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";


mapboxgl.accessToken = 'pk.eyJ1Ijoic3Nlcmd5IiwiYSI6ImNsOHU5enNjbTAyMGQzcHJ4ODlsanNpNHgifQ.NXh_OvvnBFO_uArBg676IA';

function PlaceMapCrime() {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const crimePopup = useRef(null);
  const [latLng, setLatLng] = useState([]);
  const [marker, setMarker] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeInterval, setTimeInterval] = useState('25');
  const [showOffices, setShowOffices] = useState(true);
  const [showCommercial, setShowCommercial] = useState(true);

  const trafficGradient = [
    'gray',
    '#c6d08a',
    '#E0FF00',
    '#FFB500',
    '#FF8800',
    '#FF6A00',
    '#FF4A00',
    '#FF2500',
    '#FF0900',
    '#930410',
    '#77020b',
  ];

  const crimeNames = {
    'vehicle_crime': 'Vehicle crime',
    'other_theft': 'Other theft',
    'drugs': 'Drugs',
    'criminal_damage_and_arson': 'Criminal damage and arson',
    'shoplifting': 'Shoplifting',
    'anti-social_behaviour': 'Anti-social behaviour',
    'violence_and_sexual_offences': 'Violence and sexual offences',
    'other_crime': 'Other crime',
    'bicycle_theft': 'Bicycle theft',
    'robbery': 'Robbery',
    'burglary': 'Burglary',
    'possession_of_weapons': 'Possession of weapons',
    'theft_from_the_person': 'Theft from the person',
    'public_order': 'Public order',
  };

  const ffLayer = 'footfall-layer';
  const commercialLayer = 'comemrcial-layer';
  const officeLayer = 'office-layer';

  const dispatch = useDispatch();

  const geocoder = new MapboxGeocoder({
    accessToken: mapboxgl.accessToken,
    bbox: [-8.585350424507451, 49.89060631678373, 1.762474098318385, 60.85571099962073],
    mapboxgl: mapboxgl,
    placeholder: 'Search Location'
  });

  useEffect(() => {
    if (latLng.length > 1 && !loading) {
      setLoading(true);

      let hex = latLngToCell(latLng[0], latLng[1], 10)

      if (marker !== null) {
        marker.remove();
      }
      let hexCenter = cellToLatLng(hex)
      let m = new mapboxgl.Marker({color: "#4668F2"}).setLngLat([hexCenter[1], hexCenter[0]]).addTo(map.current);
      setMarker(m);

      dispatch(getFootfallAction({code: hex, time_interval: timeInterval, 'crime': true,
        commercial_property: showCommercial, office_centers: showOffices})).then((res) => {

        // COMMERCIAL PROPERTY

        let jsonData = {
          "type": "FeatureCollection",
          "features": []
        }

        if (!!res.commercial_property && res.commercial_property.length > 0) {
          for (let i of res.commercial_property) {
            jsonData.features.push({
              "type": "Feature",
              "geometry": {
                "type": "Point",
                "coordinates": [ i.longitude, i.latitude ]
              }
            })
          }
        }

        let src = map.current.getSource(commercialLayer);

        if (typeof src !== 'undefined') {
          src.setData(jsonData);
        }

        // OFFICES

        jsonData = {
          "type": "FeatureCollection",
          "features": []
        }

        if (!!res.office_centers && res.office_centers.length > 0) {
          for (let i of res.office_centers) {
            jsonData.features.push({
              "type": "Feature",
              "properties": {
                "title": i.title===""?"Office center":i.title,
                "address": i.address===""?"-":i.address,
                "office_size": i.office_size,
                "meeting_size": i.meeting_size,
              },
              "geometry": {
                "type": "Point",
                "coordinates": [ i.longitude, i.latitude ]
              }
            })
          }
        }

        src = map.current.getSource(officeLayer);

        if (typeof src !== 'undefined') {
          src.setData(jsonData);
        }

        // FOOTFALL
        jsonData = {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": []
          }
        };

        let icons = ["pigeon", "ok", "care", "warning"];

        if (res?.footfall && res.footfall.length > 0) {
          for (let h of res.footfall) {

            let crimeHex = latLngToCell(...cellToLatLng(h.code), 9);
            let icon = "pigeon";
            let popupHTML = "";
            if (res.crime.hasOwnProperty(crimeHex)) {
              if (res.crime[crimeHex].level < 1) {
                icon = "pigeon"
              } else if (res.crime[crimeHex].level < 4) {
                icon = "ok"
              } else if (res.crime[crimeHex].level < 7) {
                icon = "care"
              } else {
                icon = "warning"
              }

              let crimes = [];

              for (let i in res.crime[crimeHex].stats) {
                crimes.push({name: i, ...res.crime[crimeHex].stats[i]})
              }

              crimes.sort((a,b) => {
                return a.coef/a.coef_avg > b.coef/b.coef_avg;
              })


              for (let item of crimes) {
                let val = (Math.round(item.coef/item.coef_avg*100)-100);

                let color = "";
                if (val < -50) {
                  color = "green"
                } else if (val < -80) {
                  color = "lightgreen"
                } else if (val < 20) {
                  color = "yellow"
                } else if (val < 60) {
                  color = "orange"
                } else {
                  color = "red"
                }
                popupHTML += "<b>"+crimeNames[item.name]+
                  ": "+item.cnt+"</b> <span style='color:"+color+";background-color: black;border-radius: 5px;padding: 2px;font-weight: bold;'>"+(val>0?"+"+val:val)+"%</span><br/>";
              }
              popupHTML += "<br/><b style='font-size: 16px;'>Total crime level: "+res.crime[crimeHex].level+"/10</b><br/>";

            } else {
              for (let item in crimeNames) {
                popupHTML += "<b>"+crimeNames[item]+
                  ": 0</b> <span style='color:green;background-color: black;border-radius: 5px;padding: 2px;font-weight: bold;'>-100%</span><br/>";
              }
            }

            jsonData.data.features.push({
              "type": "Feature",
              "properties": {"_score": h.score, "crime_level": icon, "popup": popupHTML},
              "geometry": {
                "type": "Polygon",
                "coordinates": [cellToBoundary(h.code, true)]
              }
            });
          }
        }

        src = map.current.getSource(ffLayer);

        if (typeof src !== 'undefined') {
          src.setData(jsonData.data);
        }

        setLoading(false);
      }).catch((err) => {

        let jsonData = {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": []
          }
        };

        let src = map.current.getSource(ffLayer);

        if (typeof src !== 'undefined') {
          src.setData(jsonData.data);
        }

        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [latLng, timeInterval, showCommercial, showOffices]);

  useEffect(() => {
    if (map.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-0.13765485305231095, 51.49468631310461],
      zoom: 15,
    });

    map.current.on("load", () => {

      map.current.on('click', 'unclustered-point-'+officeLayer, (e) => {
        if (crimePopup.current !== null) {
          crimePopup.current.remove();
        }

        let popup = new mapboxgl.Popup()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML("<div style='font-size: 12px;min-width: 250px;'>" +
            "<h3>"+e.features[0].properties['title']+"</h3>" +
            (e.features[0].properties['office_size']===""?"":"<span><b>Office size:</b> "+e.features[0].properties['office_size']+" ㎡</span><br/>") +
            (e.features[0].properties['meeting_size']===""?"":"<span><b>Meeting room size:</b> "+e.features[0].properties['meeting_size']+"</span><br/>") +
            "<span><b>Address:</b> "+e.features[0].properties['address']+"</span>" +
            "</div>")
          .addTo(map.current);

          crimePopup.current = popup;
      })

      map.current.on('mousedown', ffLayer+"-fill", (e) => {

// Copy coordinates array.
        //const coordinates = e.features[0].geometry.coordinates.slice();
        if (e.originalEvent.button === 2) {

          if (crimePopup.current !== null) {
            crimePopup.current.remove();
          }

          let hex = latLngToCell(e.lngLat.lat, e.lngLat.lng, 10);

          let popup = new mapboxgl.Popup()
            .setLngLat([e.lngLat.lng, e.lngLat.lat])
            .setHTML("<div style='font-size: 12px;min-width: 250px;'>" +
              "<span class='hide-crime-details'>Show location crime details ⇵</span>" +
              "<div class='crime-details hidden'>" +
              e.features[0].properties["popup"]+
              "</div>" +
              "<div class='map-crime-popup-d'></div>" +
              "<a class='map-popup-button' href='/location-report?hex=" + hex + "' target='_blank'>Open location report</a>" +
              "</div>")
            .addTo(map.current);


          document.getElementsByClassName('hide-crime-details')[0].addEventListener('click', () => {
            let elem = document.getElementsByClassName('crime-details')[0];
            if (elem.classList.contains('hidden')) {
              elem.classList.remove('hidden');
            } else {
              elem.classList.add('hidden');
            }
          });

          crimePopup.current = popup;
        }

        /*new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(description)
          .addTo(map);*/
      });

      let amenities = ["pigeon", "ok", "care", "warning"];

      for (let item of amenities) {
        map.current.loadImage('/icons/'+item+'.png', (error, image) => {
          if (error) throw error;
          map.current.addImage(item, image);
        });
      }

      map.current.on('dblclick', (e) => {
        e.preventDefault();
        setLatLng([e.lngLat.lat, e.lngLat.lng]);
        //setLatLng([e.lngLat.lat, e.lngLat.lng]);
        //console.log("--------------------------------");
        //console.log("Coordinates: ", e.lngLat.lat, e.lngLat.lng)

        /*
        dispatch(getHexCrimeAction(latLngToCell(e.lngLat.lat, e.lngLat.lng, 9))).then((res) => {
          console.log("Global location name: ", res.data.location);
          console.log("Global location population: ", res.data.population);
          //console.log(JSON.parse(res.data.crimes));
          let crimes = JSON.parse(res.data.crimes)
          for (let i in crimes) {
            let coef = crimes[i]/res.data.population * 1000000;
            let num = (coef)/CRIME_AVG[i]*100-100;

            console.log(i, "(", crimes[i], "): ", (num > 0?Math.round(num)+"% MORE than average":-1*Math.round(num)+"% LESS than average"))
          }
          console.log("Total crime rating: ", res.data.level+"/10")
        })*/
       // console.log("--------------------------------");
        //console.log(e.lngLat.lat, e.lngLat.lng)

        /*
        dispatch(getHexCrimeAction(latLngToCell(e.lngLat.lat, e.lngLat.lng, 9))).then((res) => {
          console.log(res.data.location);
          console.log(res.data.population);
          let cr = JSON.parse(res.data.crimes);
          let deb = JSON.parse(res.data.debug);

          for (let i in deb) {
            console.log(i, " - ", deb[i])
          }

          console.log(res.data.level+"/10")
        })*/

      });

      map.current.addSource(ffLayer, {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      map.current.addLayer({
        'id': ffLayer+"-line",
        'type': 'line',
        'source': ffLayer,
        'layout': {},
        'paint': {
          'line-color': 'rgba(0,0,0,0.2)',
          'line-width': 0.2
        }
      });

      map.current.addLayer({
        'id': ffLayer+"-fill",
        'type': 'fill',
        'source': ffLayer, // reference the data source
        'layout': {},
        'paint': {
          'fill-color': {
            'property': '_score',
            'stops': [
              [0, 'gray'],
              [0.1, '#c6d08a'],
              [0.2, '#E0FF00'],
              [0.3, '#FFB500'],
              [0.4, '#FF8800'],
              [0.5, '#FF6A00'],
              [0.6, '#FF4A00'],
              [0.7, '#FF2500'],
              [0.8, '#FF0900'],
              [0.9, '#930410'],
              [1, '#77020b'],
            ]
          },
          'fill-opacity': 0.5
        }
      });

      setLoading(false);

      map.current.addSource('test', {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      map.current.addLayer({
        'id': "test-line",
        'type': 'line',
        'source': 'test',
        'layout': {},
        'paint': {
          'line-color': 'rgba(0,0,0,0.2)',
          'line-width': 0.8
        }
      });

      map.current.addLayer({
        'id': "test-fill",
        'type': 'fill',
        'source': 'test', // reference the data source
        'layout': {},
        'paint': {
          'fill-color': {
            'property': 'lvl',
            'stops': [
              [0, '#00FF30'],
              [1, '#4EFF00'],
              [2, '#ABFF00'],
              [3, '#E4FF00'],
              [4, '#FFBD00'],
              [5, '#FF8D00'],
              [6, '#FF6100'],
              [7, '#fd2a07'],
              [8, '#9b0019'],
              [9, '#500109'],
              [10, '#2d0004'],
            ]
          },
          'fill-opacity': 0.5
        }
      });

      map.current.addSource('points', {
        type: 'geojson',
        data: {
          "type": "FeatureCollection",
          "features": []
        },
      });

      map.current.addLayer({
        id: 'points-circle',
        type: 'circle',
        source: 'points',
        paint: {
          'circle-color': [
            'step',
            ['get', 'cnt'],
            '#e28998',
            100,
            '#bc4a5d',
            750,
            '#e42040'
          ],
          'circle-radius': [
            'step',
            ['get', 'cnt'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      /*
      map.current.addLayer({
        id: 'test-text',
        type: 'symbol',
        source: 'test',
        filter: ['has', 'cnt'],
        layout: {
          'text-field': '{cnt}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 18
        }
      });*/

      map.current.addLayer({
        id: 'points-text',
        type: 'symbol',
        source: 'points',
        filter: ['has', 'cnt'],
        layout: {
          'text-field': '{cnt}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 18
        }
      });

      map.current.addSource('poly', {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      map.current.addLayer({
        'id': 'poly-fill',
        'type': 'fill',
        'source': 'poly', // reference the data source
        'layout': {},
        'paint': {
          "fill-color": "yellow",
          'fill-opacity': 0.5,
        }
      });

      map.current.addLayer({
        'id': 'poly-outline',
        'type': 'line',
        'source': 'poly',
        'layout': {},
        'paint': {
          'line-color': 'rgb(225,0,0)',
          'line-width': 2
        }
      });

      map.current.addLayer({
        id: 'poly-text',
        type: 'symbol',
        source: 'poly',
        filter: ['has', 'cnt'],
        layout: {
          'text-field': '{cnt}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 24
        }
      });

      map.current.addLayer({
        id: ffLayer+'-icons',
        type: 'symbol',
        source: ffLayer,
        paint: {
          'icon-color': "red",
        },
        layout: {
          'icon-image': [
            'coalesce',
            ['image', ['get', 'crime_level']],
            ['image', 'point']
          ],
          'icon-size': 0.40,
          'icon-offset': [60,-30],
        },
      });


      // COMMERCIAL
      map.current.addSource(commercialLayer, {
        type: 'geojson',
        data: {
          "type": "FeatureCollection",
          "features": []
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });

      map.current.addLayer({
        id: 'clusters-'+commercialLayer,
        type: 'circle',
        source: commercialLayer,
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#e28998',
            100,
            '#bc4a5d',
            750,
            '#e42040'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      map.current.addLayer({
        id: 'cluster-count-'+commercialLayer,
        type: 'symbol',
        source: commercialLayer,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      map.current.addLayer({
        id: 'unclustered-point-'+commercialLayer,
        type: 'circle',
        source: commercialLayer,
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#e73c59',
          'circle-radius': 10,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      //OFFICES
      map.current.addSource(officeLayer, {
        type: 'geojson',
        data: {
          "type": "FeatureCollection",
          "features": []
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });

      map.current.addLayer({
        id: 'clusters-'+officeLayer,
        type: 'circle',
        source: officeLayer,
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#6f81dc',
            100,
            '#3249c2',
            750,
            '#012057'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      map.current.addLayer({
        id: 'cluster-count-'+officeLayer,
        type: 'symbol',
        source: officeLayer,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      map.current.addLayer({
        id: 'unclustered-point-'+officeLayer,
        type: 'circle',
        source: officeLayer,
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#0003fc',
          'circle-radius': 10,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });
      /*
      dispatch(getPolyAction()).then((res) => {

        let data = {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": []
          }
        }

        for (let item of res.data) {
          let polyData = JSON.parse(item.json);

          data.data.features.push({
            "type": "Feature",
            "geometry": polyData
          })
        }

        let curSrc = map.current.getSource('poly');

        if (typeof curSrc !== 'undefined') {
          curSrc.setData(data.data);
        }
      })*/

      /*
      dispatch(getHexCrimeErrAction()).then((res) => {
        let jsonData = {
          "type": "FeatureCollection",
          "features": []
        }

        for (let i of res.data) {
          jsonData.features.push({
            "type": "Feature",
            "properties": {"cnt": i.cnt},
            "geometry": {
              "type": "Point",
              "coordinates": [ i.longitude, i.latitude ]
            }
          })
        }

        let src = map.current.getSource('points');

        if (typeof src !== 'undefined') {
          src.setData(jsonData);
        }
      })*/


      /*
      dispatch(getCrimeAction()).then((res) => {

        let jsonData = {
          "type": "geojson",
          "data": {
            "type": "FeatureCollection",
            "features": []
          }
        };

        for (let h of res.data) {
          jsonData.data.features.push({
            "type": "Feature",
            "properties": {"cnt": h.count, "lvl": h.level},
            "geometry": {
              "type": "Polygon",
              "coordinates": [cellToBoundary(h.code, true)]
            }
          });
        }

        let src = map.current.getSource('test');

        if (typeof src !== 'undefined') {
          src.setData(jsonData.data);
        }

      });*/
      geocoder.on('result', (e) => {
        // remove `click` marker if exists

        geocoder.mapMarker.remove();
        setLatLng([e.result.center[1], e.result.center[0]]);
      });

      document.getElementById('geocoder-container').appendChild(geocoder.onAdd(map.current));

    });
  }, [map])

  const options = [
    { key: '1', text: '00:00-00:59', value: '0' },
    { key: '2', text: '01:00-01:59', value: '1' },
    { key: '3', text: '02:00-02:59', value: '2' },
    { key: '4', text: '03:00-03:59', value: '3' },
    { key: '5', text: '04:00-04:59', value: '4' },
    { key: '6', text: '05:00-05:59', value: '5' },
    { key: '7', text: '06:00-06:59', value: '6' },
    { key: '8', text: '07:00-07:59', value: '7' },
    { key: '9', text: '08:00-08:59', value: '8' },
    { key: '10', text: '09:00-09:59', value: '9' },
    { key: '11', text: '10:00-10:59', value: '10' },
    { key: '12', text: '11:00-11:59', value: '11' },
    { key: '13', text: '12:00-12:59', value: '12' },
    { key: '14', text: '13:00-13:59', value: '13' },
    { key: '15', text: '14:00-14:59', value: '14' },
    { key: '16', text: '15:00-15:59', value: '15' },
    { key: '17', text: '16:00-16:59', value: '16' },
    { key: '18', text: '17:00-17:59', value: '17' },
    { key: '19', text: '18:00-18:59', value: '18' },
    { key: '20', text: '19:00-19:59', value: '19' },
    { key: '21', text: '20:00-20:59', value: '20' },
    { key: '22', text: '21:00-21:59', value: '21' },
    { key: '23', text: '22:00-22:59', value: '22' },
    { key: '24', text: '23:00-23:59', value: '23' },
    { key: '25', text: '00:00-23:59', value: '25' },
  ]

  return <React.Fragment>
    <MyAppBar/>

    <div style={{height:'100vh', width:'100%'}}>
      <div className={'place-map-analytics-dropdown two-child-ff'}>
        <div><div id={"geocoder-container"} /></div>

        <div>  <Dropdown
          placeholder='Time Interval'
          selection
          options={options}
          value={timeInterval}
          disabled={loading}
          onChange={(e, v) => {
            setTimeInterval(v.value);
          } }
        /></div>
      </div>
      <div ref={mapContainer} className="map-container" />
      <div className={'map-legend'}>
        <div className={'legend-checkbox-items'}>
          <div>
            <Checkbox disabled={loading} toggle checked={showCommercial} onChange={() => setShowCommercial(!showCommercial)}/>
          </div>
          <div>Show commercial property</div>
          <div><span className={'legend-commercial'}></span></div>
        </div>
        <div className={'legend-checkbox-items'}>
          <div>
            <Checkbox disabled={loading} toggle checked={showOffices} onChange={() => setShowOffices(!showOffices)}/>
          </div>
          <div>Show business centers</div>
          <div><span className={'legend-office'}></span></div>
        </div>
        <div className={'favg-header'} />
        <div className={'legend-dblclck legend-info'}>Select location to show footfall</div>
        <div className={'legend-rclck legend-info'}>Open footfall zone options</div>
        <div className={'legend-gradient-items'}>
          <div style={{width: '50%'}}>No traffic</div>
          <div style={{width: '50%'}}>Very high traffic</div>
        </div>

        <div className={"legend-gradient-container"}>
          {trafficGradient.map((itm, idx) => {
            return <div key={idx} style={{backgroundColor:itm}}/>;
          })}
        </div>
      </div>
    </div>
  </React.Fragment>
}

export default PlaceMapCrime
