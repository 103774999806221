import cfg, {handleResponse, UPDATE_LANGUAGE} from "../constants";
import {authMiddleware, storeLanguage} from "./loginAction";

export const updateProfileAction = (body) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/user-edit-profile', {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      if (body.language) {
        storeLanguage(body.language);
        dispatch({type: UPDATE_LANGUAGE, lang: body.language});
      }
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const updateProfileLanguageAction = (language) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  fetch(cfg.base_url + '/auth/profile-language', {
    method: "POST",
    headers: headers,
    body: JSON.stringify({language})
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return true;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
  }).catch((e) => {
    console.log('Error in saving language', e);
  });
};

export const changePasswordAction = (body) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/user-password', {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const changeEmailAction = (email) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/update-email', {
    method: "POST",
    headers: headers,
    body: JSON.stringify({email})
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const changeTwofaAction = (body) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/user-tfa', {
    method: "POST",
    headers: headers,
    body: JSON.stringify(body)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const generateTwofaSecretAction = (code) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/user-tfa-secret', {
    method: "POST",
    headers: headers,
    body: JSON.stringify({code})
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};
