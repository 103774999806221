import React, {useEffect, useState} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Container, Icon, Table, Button, Grid, Breadcrumb, Message, Dropdown} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {formatErrors} from "../../constants";
import {propertiesAction, propertyDeleteAction} from '../../actions/propertyAction';
import Paging from '../../utils/Paging/Paging';
import {ConfirmModal} from '../../utils/ConfirmModal/ConfirmModal';


const propTypes = [
  'Business Park', 'Cafe', 'Childcare Facility', 'Commercial Development', 'Commercial Property', 'Convenience Store', 
  'Distribution Warehouse', 'Garage', 'Hairdresser / Barber Shop', 'Healthcare Facility', 'Heavy Industrial', 'Hospitality', 
  'Hotel', 'Industrial Development', 'Industrial Park', 'Land', 'Leisure Facility', 'Light Industrial', 'Mixed Use', 'Office', 
  'Place of Worship', 'Pub', 'Residence', 'Residential Development', 'Restaurant', 'Retail Property (high street)', 'Retail Property (out of town)', 
  'Serviced Office', 'Shop', 'Showroom', 'Storage', 'Takeaway', 'Warehouse', 'Workshop', 'flat', 'Apartment'
];

const resourceTypes = [
  {key: 0, text: 'All sources', value: -1},
  {key: 1, text: 'Rightmove', value: 1},
  {key: 2, text: 'Zoopla', value: 2},
];

const propTypesMain =  [ 
  {key: 0, text: 'All properties', value: 0},
  {key: 1, text: 'Commercial', value: 1},
  {key: 2, text: 'Private flats', value: 2},
];

const sourceTypesMain = [
  {key: 0, text: 'All types', value: 0},
  {key: 1, text: 'Sale', value: 1},
  {key: 2, text: 'Rent', value: 2},
];


function Property() {
  const [filter, setFilter] = useState(null);
  const filterName = "m_PropertyFilter";
  const [types, setTypes] = useState([]);

  const handleChangeFilter = (value, name) => {
    if (name === "page") {
      setFilter({...filter, page: value});
    } else if (name === "sort") {
      setFilter({
        ...filter,
        sort: value,
        sortd: filter.sort === value ? (filter.sortd === "asc" ? "desc" : "asc") : "asc",
        page: 1
      });
    } else if (name) {
      setFilter({...filter, [name]: value, page: 1});
    } else {
      setFilter({...filter});
    }
  };

  useEffect(() => { 
    const dFilter = {page: 1, limit: 10, sort: 'created', sortd: 'desc', type_resource: -1, property_type: '', property_type_main: 0};
    let curFilter = localStorage.getItem(filterName);
    curFilter = curFilter ? JSON.parse(curFilter) : dFilter;
    if (!curFilter.type_resource) {
      curFilter = dFilter;
    }

    setFilter(curFilter);

    let pt = [];
    propTypes.map((item, index) => {
      pt.push({key: index, text: item, value: item});
    });
    setTypes(pt);
  }, []);

  return <React.Fragment>
    <MyAppBar/>

      <Container className={'container-wide'}>
          <ButtonsTop handleChange={handleChangeFilter}/>
          {filter && <PropertyList  filterName={filterName} filter={filter} types={types} handleChange={handleChangeFilter}/>}
      </Container>
  </React.Fragment>;
}

const ButtonsTop = ({handleChange}) => {
  return <Grid>
    <Grid.Row columns={2}>
      <Grid.Column>
        <Breadcrumb>
          <Breadcrumb.Section active>
            Commercial properties
          </Breadcrumb.Section>
        </Breadcrumb>
      </Grid.Column>

      <Grid.Column textAlign='right'></Grid.Column>
    </Grid.Row>
  </Grid>;
};

function PropertyList({filter, filterName, types, handleChange}) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);

  const deleteProperty = (id) => {
    return new Promise((resolve, reject) => {
      if (loading) {
        return resolve(false);
      }
      setLoading(true);
      dispatch(propertyDeleteAction(id)).then(() => {
        setLoading(false);
        resolve(true);
        if (Math.ceil(total / filter.limit) === filter.page && filter.page > 1) {
          handleChange(filter.page - 1, "page");
        } else {
          handleChange();
        }
      }).catch(err => {
        setLoading(false);
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
        reject(err);
      });
    })
  };

  useEffect(() => {
    if (!filter) {
      return;
    }

    dispatch(propertiesAction(filter)).then((data) => {
      setItems(data.items || []);
      setTotal(data.total);
      localStorage.setItem(filterName, JSON.stringify(filter));
    }).catch(err => {
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }, [dispatch, filter, filterName]);

  const handleChangeDropDown = (e, data) => {
    const name = data.name;
    let value = data.value;
    if (name === 'type_resource' && value === '') {
      value = -1;
    } else if ((name === 'property_type_main' || name === 'search_source') && value === '') {
      value = 0;
    }
    handleChange(value, name);
  };

  return <React.Fragment>

    <Grid>
      <Grid.Row columns={4}>
        <Grid.Column>
          {filter && <Dropdown clearable options={resourceTypes} search
                selection placeholder='All sources' value={filter.type_resource} button
                name="type_resource" onChange={handleChangeDropDown} />}
        </Grid.Column>
        <Grid.Column>
          {filter && <Dropdown clearable options={types} search
                selection placeholder='All categories' value={filter.property_type} button
                name="property_type" onChange={handleChangeDropDown} />}
        </Grid.Column>
        <Grid.Column>
          {filter && <Dropdown clearable options={propTypesMain} search
                selection placeholder='All realty types' value={filter.property_type_main} button
                name="property_type_main" onChange={handleChangeDropDown} />}
        </Grid.Column>
        <Grid.Column>
          {filter && <Dropdown clearable options={sourceTypesMain} search
                selection placeholder='All types' value={filter.search_source} button
                name="search_source" onChange={handleChangeDropDown} />}
        </Grid.Column>
      </Grid.Row>
    </Grid>


    {total === 0 && <Message color="purple">No records found.</Message>}

    {total > 0 && <Table celled striped sortable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Source</Table.HeaderCell>
          <Table.HeaderCell>Address</Table.HeaderCell>
          <Table.HeaderCell>Lat/Lng</Table.HeaderCell>
          <Table.HeaderCell>Price</Table.HeaderCell>
          <Table.HeaderCell>Category</Table.HeaderCell>
          <Table.HeaderCell>Property</Table.HeaderCell>
          <Table.HeaderCell>Type</Table.HeaderCell>
          <Table.HeaderCell>Data</Table.HeaderCell>
          <Table.HeaderCell>Updated</Table.HeaderCell>
          {/*<Table.HeaderCell width={2}/>*/}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((i) => {
          return <Table.Row key={i.id}>
            <Table.Cell>{i.type_resource === 2 ? 'Zoopla' : 'Rightmove'}</Table.Cell>
            <Table.Cell>{i.address}</Table.Cell>
            <Table.Cell>{i.latitude}/{i.longitude}</Table.Cell>
            <Table.Cell>{i.price}</Table.Cell>
            <Table.Cell>{i.property_type}</Table.Cell>
            <Table.Cell>{i.property_type_main === 1 ? 'Commercial' : (i.property_type_main === 2 ? 'Private flats' : '')}</Table.Cell>
            <Table.Cell>{i.search_source===1 ? 'Sale' : (i.search_source===2 ? 'Rent' : '')}</Table.Cell>
            <Table.Cell>
              {i.type_resource === 1 && <a href={'https://www.rightmove.co.uk/properties/' + i.id_in_resource + '#/'} target="_blank" rel="noopener noreferrer">Open</a>}
              {i.type_resource === 2 && <a href={'https://www.zoopla.co.uk/to-rent/commercial/details/' + i.id_in_resource + '/'} target="_blank" rel="noopener noreferrer">Open</a>}
              </Table.Cell>
            <Table.Cell>{i.updated}</Table.Cell>
            {/*<Table.Cell textAlign="center">
              <ConfirmModal content='Do you wish to delete item?' ok={() => deleteProperty(i.id)}
                            trigger={<Button size='mini' icon><Icon name='delete'/></Button>}/>
            </Table.Cell>*/}
          </Table.Row>;
        })}
      </Table.Body>
    </Table>}

    <Paging total={total} page={filter.page} limit={filter.limit} handleChange={handleChange}/>

  </React.Fragment>;
}

export default Property
