import React, {useEffect, useState} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Breadcrumb, Button, Container, Dropdown, Form, Grid, Table} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import {
  getBrandsAction,
  getBrandProductsAction,
  getProductInfoAction,
  getPlaceInfoAction
} from "../../actions/menuAction";
import {toast} from "react-toastify";
import {formatErrors} from "../../constants";
import {useNavigate} from "react-router-dom";

function MenuComparison() {
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState(null)
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState(null);
  const [places, setPlaces] = useState([]);
  const [place, setPlace] = useState(null);

  const [productInfo, setProductInfo] = useState([]);
  const [placeInfo, setPlaceInfo] = useState([]);

  const [sort, setSort] = useState({column: 'place', sort:'descending'});

  const dispatch = useDispatch();

  useEffect(() => {
    if (brands.length === 0) {
      setLoading(true);
      dispatch(getBrandsAction()).then((res) => {
        if (res?.brands) {
          let tmpBrands = [];
          let key = 1;
          for (let item of res.brands) {
            tmpBrands.push({key: key, value: item.id, text: item.name, description: "Places: "+item.cnt})
            key += 1
          }
          setBrands([...tmpBrands]);
        }

        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [brands])

  useEffect(() => {
    if (!!brand) {
      setLoading(true);
      dispatch(getBrandProductsAction({id: brand})).then((res) => {
        if (res?.products) {
          let tmpProducts = [];
          let key = 1;
          for (let item of res.products) {
            tmpProducts.push({key: key, value: item.code, text: item.name, description: "( "+item.cnt+" )"})
            key += 1
          }
          setProducts([...tmpProducts]);
        }

        if (res?.places) {
          let tmpPlaces = [];
          let key = 1;
          for (let item of res.places) {
            tmpPlaces.push({key: key, value: item.id, text: item.address})
            key += 1
          }
          setPlaces([...tmpPlaces]);
        }
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [brand])

  useEffect(() => {
    if (!!product && !!brand) {
      setLoading(true);
      dispatch(getProductInfoAction({brand, product})).then((res) => {
        if (res?.product_info) {
          setProductInfo(MakeSort(res?.product_info, sort));
        }
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [product, brand]);

  useEffect(() => {
    if (!!place && place > 0) {
      setLoading(true);
      dispatch(getPlaceInfoAction({id: place})).then((res) => {
        if (res?.place_info) {
          setPlaceInfo(MakeSort(res?.place_info, sort));
        }
        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [place]);

  useEffect(() => {
    if (!!productInfo && productInfo.length > 0 && !!sort) {
      let tmp = [...productInfo];

      setProductInfo([...MakeSort(tmp, sort)]);
    } else if (!!placeInfo && placeInfo.length > 0 && !!sort) {
      let tmp = [...placeInfo];

      setPlaceInfo([...MakeSort(tmp, sort)]);
    }
  }, [sort]);

  function MakeSort(obj, sort) {
    if (sort.column === 'place') {
      obj.sort((a, b) => {
        if (sort.sort==='ascending') {
          return a.place_name>b.place_name?-1:1;
        } else {
          return a.place_name<b.place_name?-1:1;
        }
      });
    } else if (sort.column === 'address') {
      obj.sort((a, b) => {
        if (sort.sort==='ascending') {
          return a.address>b.address?-1:1;
        } else {
          return a.address<b.address?-1:1;
        }
      });
    } else if (sort.column === 'item_name') {
      obj.sort((a, b) => {
        if (sort.sort==='ascending') {
          return a.item_name>b.item_name?-1:1;
        } else {
          return a.item_name<b.item_name?-1:1;
        }
      });
    } else {
      obj.sort((a, b) => {
        if (sort.sort==='ascending') {
          return a.price_value>b.price_value?-1:1;
        } else {
          return a.price_value<b.price_value?-1:1;
        }
      });
    }

    return obj;
  }

  function ChangeSort(column) {
    let tmp = {...sort};

    if (tmp.column === column) {
      tmp.sort = tmp.sort==='ascending'?'descending':'ascending';
    } else {
      tmp.column = column;
      tmp.sort = 'ascending';
    }

    setSort({...tmp});
  }

  return <React.Fragment>

    <div className={"loader-screen"} hidden={!loading}>
      <div>Loading ...</div>
    </div>

    <MyAppBar/>

    <Container className={'container-wide'}>
      <ButtonsTop />
      <Grid columns={"equal"}>
        <Grid.Row>
          <Grid.Column>
            <Dropdown
              placeholder='Select network'
              fluid
              search
              selection
              value={brand}
              options={brands}
              onChange={(e, v) => {setBrand(v.value);setProduct(null);setPlace(null);setPlaceInfo([]);setProductInfo([]);}}
            />
          </Grid.Column>
        </Grid.Row>
        {!!products && products.length > 0 && <Grid.Row>
          <Grid.Column>
            <Form>
              <Form.Field>
                <label>Compare prices between stores</label>
                <Dropdown
                  placeholder='Select product'
                  fluid
                  search
                  selection
                  value={product}
                  options={products}
                  onChange={(e, v) => {setProduct(v.value);setPlace(null);setSort({column: 'place', sort:'descending'})}}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <Form.Field>
                <label>View specific store's menu</label>
                <Dropdown
                  placeholder='Select place'
                  fluid
                  search
                  selection
                  value={place}
                  options={places}
                  onChange={(e, v) => {setPlace(v.value);setProduct(null);setSort({column: 'item_name', sort:'descending'})}}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid.Row>}
        {!!product && !!brand && productInfo.length > 0 && <React.Fragment>
          <Grid.Row>
            <Grid.Column>
          <Table sortable celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  sorted={sort.column==="place"?sort.sort:null}
                  onClick={() =>{ChangeSort('place')}}
                >
                  Place</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.column==="address"?sort.sort:null}
                  onClick={() =>{ChangeSort('address')}}
                >
                  Address</Table.HeaderCell>
                <Table.HeaderCell
                  sorted={sort.column==="price"?sort.sort:null}
                  onClick={() =>{ChangeSort('price')}}
                >
                  Price</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {productInfo.map((item, idx) => {
                return <Table.Row key={idx}>
                  <Table.Cell>{item.place_name}</Table.Cell>
                  <Table.Cell>{item.address}</Table.Cell>
                  <Table.Cell>{item.price_value / 100}£</Table.Cell>
                </Table.Row>
              })}
            </Table.Body>
        </Table>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>}
        {!!place && !!brand && placeInfo.length > 0 && <React.Fragment>
          <Grid.Row>
            <Grid.Column>
              <Table sortable celled>
                <Table.Header>
                  <Table.Row>
                  <Table.HeaderCell
                    sorted={sort.column==="item_name"?sort.sort:null}
                    onClick={() =>{ChangeSort('item_name')}}
                  >
                    Item</Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={sort.column==="price"?sort.sort:null}
                    onClick={() =>{ChangeSort('price')}}
                  >
                    Price</Table.HeaderCell>

                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {placeInfo.map((item, idx) => {
                    return <Table.Row key={idx}>
                      <Table.Cell>{item.item_name}</Table.Cell>
                      <Table.Cell>{item.price_value / 100}£</Table.Cell>
                    </Table.Row>
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>}
      </Grid>

    </Container>
  </React.Fragment>
}

const ButtonsTop = ({handleChange}) => {
  const navigate = useNavigate();
  return <Grid>
    <Grid.Row columns={2}>
      <Grid.Column>
        <Breadcrumb>
          <Breadcrumb.Section active link>
            Menu comparison
          </Breadcrumb.Section>
        </Breadcrumb>
      </Grid.Column>

      <Grid.Column textAlign='right'>
      </Grid.Column>
    </Grid.Row>
  </Grid>;
};

export default MenuComparison
