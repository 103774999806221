import React, {useState} from 'react';
import {Accordion, Icon} from "semantic-ui-react";
import {AccordionTabs} from "../../../constants";

function Competitors() {
  const [tabs, setTabs] = useState([]);
  const at = new AccordionTabs();

  return <React.Fragment>
    <Accordion className={"report-qa"}>
      <Accordion.Title active={at.g(0,tabs)}
                       index={0}
                       onClick={() => at.toggle(0, tabs, setTabs)}>
        <Icon name='dropdown' />
        <b>What is the source of the data?</b>
      </Accordion.Title>
      <Accordion.Content active={at.g(0,tabs)}>
        <span>
          We have partnered with Google to provide the most recent and accurate data on the competitors around the chosen location.
        </span>
      </Accordion.Content>
    </Accordion>
  </React.Fragment>
}

export default Competitors;
