import React, {useEffect, useState} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Breadcrumb, Button, Container, Dropdown, Grid, Icon, Message, Table} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import {myReportsAction} from "../../actions/ordersAction";
import {toast} from "react-toastify";
import cfg, {formatErrors, getRolesNames} from "../../constants";
import {ConfirmModal} from "../../utils/ConfirmModal/ConfirmModal";
import Paging from "../../utils/Paging/Paging";

function MyReports() {
  const [filter, setFilter] = useState(null);
  const filterName = "m_MyReportsFilter";

  const handleChangeFilter = (value, name) => {
    if (name === "page") {
      setFilter({...filter, page: value});
    } else if (name === "sort") {
      setFilter({
        ...filter,
        sort: value,
        sortd: filter.sort === value ? (filter.sortd === "asc" ? "desc" : "asc") : "asc",
        page: 1
      });
    } else if (name) {
      setFilter({...filter, [name]: value, page: 1});
    } else {
      setFilter({...filter});
    }
  };

  useEffect(() => {
    const dFilter = {page: 1, limit: 10, sort: 'created', sortd: 'desc'};
    let curFilter = localStorage.getItem(filterName);
    curFilter = curFilter ? JSON.parse(curFilter) : dFilter;

    setFilter(curFilter);
  }, []);

  return <React.Fragment>
    <MyAppBar/>

    <Container className={'container-wide'}>
      <ButtonsTop filter={filter} handleChange={handleChangeFilter}/>
      {filter && <MyReportsList  filterName={filterName} filter={filter} handleChange={handleChangeFilter}/>}
    </Container>
  </React.Fragment>;
}

const ButtonsTop = () => {
  return <Grid>
    <Grid.Row columns={2}>
      <Grid.Column>
        <Breadcrumb>
          <Breadcrumb.Section active>
            My Reports
          </Breadcrumb.Section>
        </Breadcrumb>
      </Grid.Column>
    </Grid.Row>
  </Grid>;
};

function MyReportsList({filter, filterName, handleChange}) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(null);

  useEffect(() => {
    if (!filter) {
      return;
    }

    dispatch(myReportsAction(filter)).then((data) => {
      setItems(data.items || []);
      setTotal(data.total);
      localStorage.setItem(filterName, JSON.stringify(filter));
    }).catch(err => {
      console.log(err)
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }, [dispatch, filter, filterName]);

  return <React.Fragment>
    {total === 0 && <Message color="purple">No records found.</Message>}

    {total > 0 && <Table celled striped sortable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Address</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Link</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((i) => {
          return <Table.Row key={i.id}>
            <Table.Cell>{i.address}</Table.Cell>
            <Table.Cell>{i.created}</Table.Cell>
            <Table.Cell>{i.report_num!==""?<a target={"_blank"} href={"/location-report/"+i.report_num}>{i.report_num}</a>:"-"}</Table.Cell>
          </Table.Row>;
        })}
      </Table.Body>
    </Table>}

    <Paging total={total} page={filter.page} limit={filter.limit} handleChange={handleChange}/>

  </React.Fragment>;
}

export default MyReports
