import React, {forwardRef, useEffect, useState, useRef} from 'react';
import {Dropdown, Grid, Table} from "semantic-ui-react";
import mapboxgl from "mapbox-gl";
import Slider from "rc-slider";
import Tooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import "rc-slider/assets/index.css";
import {useDispatch} from "react-redux";
import {getCompetitorsPois} from "../../../actions/placeMapFootfallAction";
import {toast} from "react-toastify";
import {formatErrors} from "../../../constants";
import Paging from "../../../utils/Paging/Paging";

const CompetitorsTab = forwardRef(function (props, ref) {

  const [enableSearch, setEnableSearch] = useState(false);
  const cLayerName = "c_layer";
  const poiLayerName = "p_layer";
  const tm = useRef(null);

  const dispatch = useDispatch();

  const TypesMap = function (t) {
    if (t === null) return [];

    t.sort((a, b) => a > b?1:-1)

    let tmp = [];
    for (let i in t) {
      tmp.push({text: t[i], key: i, value: t[i]});
    }

    return tmp;
  }(props.types)

  useEffect(() => {
    if ((ref === null || ref.current === null || ref.current.map === null) && props.lat !== null && props.lng !== null) {
      initCompetitorsMap(props.lat, props.lng)
    }
  }, [ref]);

  useEffect(() => {
    if (ref.current.map !== null) {
      let curSrc = ref.current.map.getSource(cLayerName);

      if (typeof curSrc !== 'undefined') {
        let data = getGeoJsonCircle([props.lat, props.lng], props.filter["radius"]);
        curSrc.setData(data.data);
      }
    }
  }, [props.filter.radius])

  useEffect(() => {
    if (props.filter.types.length > 0 && !!enableSearch) {
      clearTimeout(tm.current);
      tm.current = setTimeout(function(){
        getPoints(props.filter);
      }, 500);
    }
  }, [props.filter.types, props.filter.radius, enableSearch]);

  /*
  function GetTypesValues(types) {
    if (!!!types) return "";

    let tmp = [];
    for (let type of types) {
      if ( TypesMap.hasOwnProperty(type)) {
        tmp.push(TypesMap[type]);
      }
    }

    return tmp.join(", ");
  }*/

  function getPoints(cuFilter) {

    if (ref.current.pop !== null) {
      ref.current.pop.remove();
    }

    props.handleLoading(true);
    dispatch(getCompetitorsPois({radius: cuFilter.radius, types: cuFilter.types, report_num: cuFilter.report_num})).then(res => {

      props.handleCompetitors(res.pois);
      props.handleFilter("page", 1);

      let jsonData = {
        "type": "FeatureCollection",
        "features": []
      }

      for (let h of res.pois) {
        jsonData.features.push({
          "type": "Feature",
          "properties": {
            "name": h.name,
            "rating": h.rating,
            "reviews": h.reviews,
            "types": !!h.types?h.types.join(", "):"n/a",
            "address": h.address,
          },
          "geometry": {
            "type": "Point",
            "coordinates": [ h.longitude, h.latitude ]
          }
        })
      }

      let src = ref.current.map.getSource(poiLayerName);

      if (typeof src !== 'undefined') {
        src.setData(jsonData);
      }

      props.handleLoading(false);
    }).catch((err) => {
      props.handleLoading(false);
      console.log(err)
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  function getGeoJsonCircle(latLng, radius, points) {

    if (!points) points = 64;

    let coords = {
      latitude: latLng[0],
      longitude: latLng[1]
    };

    let km = radius / (1000 * 1.09361);

    let ret = [];
    let distanceX = km / (111.320 * Math.cos(coords.latitude * Math.PI / 180));
    let distanceY = km / 110.574;

    let theta, x, y;
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
      "type": "geojson",
      "data": {
        "type": "FeatureCollection",
        "features": [{
          "type": "Feature",
          "geometry": {
            "type": "Polygon",
            "coordinates": [ret]
          }
        }]
      }
    };
  };

  function GetInfoTag(rating, reviews) {

    let r = rating === -1?0:parseFloat(rating);
    r = !!r?r:0;

    reviews = parseInt(reviews);
    reviews = !!reviews?(reviews===500?"500+":reviews+""):"0";

    let rF = Math.floor(r);
    let rP = r - rF;

    let block = `<div class="rating-block"><span class="rating">`+(rating === -1?"n/a":r)+`</span>`;
    for (let i = 1; i <= 5; i++) {

      let cl = "full";
      if (rF+1 === i) {
        if (rP < 0.3) {
          cl = "empty"
        } else if (rP < 0.7) {
          cl = "half"
        }
      } else if (rF < i) {
        cl = "empty"
      }


      block += `<span class="stars `+cl+`"></span>`;
    }

    return block+`<span class="reviews">(`+reviews+`)</span></div>`;
  }

  function initCompetitorsMap(lat, lng) {
    props.handleLoading(true);
    ref.current.map = new mapboxgl.Map({
      container: ref.current.container,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lng, lat],
      zoom: 15,
    });

    ref.current.map.on('load', () => {
      let marker = new mapboxgl.Marker({color: "#4668F2"}).setLngLat([lng, lat]).addTo(ref.current.map);

      ref.current.map.loadImage('/restaurant-ico30.png', (error, image) => {
        if (error) throw error;
        ref.current.map.addImage('restaurant', image);
      });

      ref.current.map.loadImage('/point-ico30.png', (error, image) => {
        if (error) throw error;
        ref.current.map.addImage('point', image);
      });

      ref.current.map.addSource(poiLayerName, {
        type: 'geojson',
        data: {
          "type": "FeatureCollection",
          "features": []
        },
        cluster: true,
        clusterMaxZoom: 16,
        clusterRadius: 50,
      });

      ref.current.map.addLayer({
        id: 'competitors',
        type: 'symbol',
        source: poiLayerName,

        layout: {
          'icon-image': 'point',
          "icon-allow-overlap": true,
        },
      });

      ref.current.map.addLayer({
        id: 'clusters_competitors',
        type: 'circle',
        source: poiLayerName,
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#0a9532',
            100,
            '#024f18',
            750,
            '#012c0e'
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ]
        }
      });

      ref.current.map.addLayer({
        id: 'unclustered_point_'+poiLayerName,
        type: 'circle',
        source: poiLayerName,
        filter: ['!', ['has', 'point_count']],
        paint: {
          'circle-color': '#012c0e',
          'circle-radius': 10,
          'circle-stroke-width': 1,
          'circle-stroke-color': '#fff'
        }
      });

      ref.current.map.addLayer({
        id: 'clusters_competitors_count',
        type: 'symbol',
        source: poiLayerName,
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        }
      });

      ref.current.map.addSource(cLayerName, {
        'type': 'geojson',
        'data': {
          "type": "FeatureCollection",
          "features": [],
        },
      });

      ref.current.map.addLayer({
        'id': cLayerName+"-line",
        'type': 'line',
        'source': cLayerName,
        'layout': {},
        'paint': {
          'line-color': 'rgba(140,138,138,0.45)',
          'line-width': 2
        }
      });

      ref.current.map.addLayer({
        'id': cLayerName+"-fill",
        'type': 'fill',
        'source': cLayerName, // reference the data source
        'layout': {},
        'paint': {
          'fill-color': 'rgba(185,183,183,0.45)',
          'fill-opacity': 0.3
        }
      });

      ref.current.map.on('click', 'unclustered_point_'+poiLayerName, (e) => {
        if (ref.current.pop !== null) {
          ref.current.pop.remove();
        }

        let info = GetInfoTag(e.features[0].properties['rating'], e.features[0].properties['reviews'])+`<br />`;

        let types = e.features[0].properties['types'];
        types = !!types?`<div class='poi-map-types'>`+types+`</div><br/>`:"";

        let address = !!e.features[0].properties['address']?`<div class="poi-map-address">`+e.features[0].properties['address']+`</div>`:"";

        ref.current.pop = new mapboxgl.Popup({'className': 'poi-map-popup'})
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .setHTML("<div style='font-size: 12px;min-width: 250px;'>" +
            "<h4>"+e.features[0].properties['name']+"</h4>" + info + types + address+
            "</div>")
          .addTo(ref.current.map);
      })

      let curSrc = ref.current.map.getSource(cLayerName);

      if (typeof curSrc !== 'undefined') {
        let data = getGeoJsonCircle([props.lat, props.lng], props.filter["radius"]);
        curSrc.setData(data.data);
      }

      props.handleLoading(false);
    })
  }

  function sliderChange(value) {
    if (!props.loading) {
      if (!enableSearch) {
        setEnableSearch(true);
      }

      props.handleFilter("radius", value);
    }
  }

  function dropdownChange(value) {
    if (!props.loading) {
      if (!enableSearch) {
        setEnableSearch(true);
      }

      props.handleFilter("types", value)
    }
  }

  function pageChange(value) {
    props.handleFilter("page", value)
  }

  return <React.Fragment>

    <Grid.Row>
      <Grid.Column>
        <div className={"RE-hdr"}>
          <h2>Competitors</h2>
        </div>
        <br/><br/>
        <div>
          <Grid columns={"equal"}>
            <Grid.Row>
              <Grid.Column>
                <h4>Type of competitors</h4>
                {TypesMap.length > 0 && <Dropdown search selection multiple disabled={props.loading} clearable
                          options={TypesMap}
                          placeholder={"Select competitors type"}
                          value={props.filter["types"]}
                          onChange={(e, v) => {
                            dropdownChange(v.value)
                          }}
                />}
                {TypesMap.length === 0 && <div>No pois found</div>}
                <h4>Radius in yards</h4>
                <Slider value={props.filter['radius']} min={100} max={1000} step={100}
                        marks={{100: {label: '100', style: {left: '0%'}}, 1000: {label: '1000', style: {left: '100%'}}}}
                        onChange={sliderChange}
                        disabled={props.loading || TypesMap.length === 0}
                        draggableTrack={!props.loading}
                        handleRender={(node, handleProps) => {
                          return (
                            <Tooltip
                              overlayInnerStyle={{ minHeight: "auto" }}
                              overlay={handleProps.value}
                              placement="bottom"
                            >
                              {node}
                            </Tooltip>
                          );
                        }}
                />
              </Grid.Column>
              <Grid.Column>

              </Grid.Column>
            </Grid.Row>
          </Grid>

          <br/>
        </div>
        <br/><br />
        <div className={'cu-map-box'} id={'competitors-map-box'}
             ref={(el) => (ref.current.container = el)}></div>
        <div>
          {props.competitors.length > 0 && <React.Fragment>
            <br/><br/>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Poi types</Table.HeaderCell>
                  <Table.HeaderCell>Distance<br/>(yards)</Table.HeaderCell>
                  <Table.HeaderCell>Rating<br/>(out of 5)</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {props.competitors.map((item, idx) => {
                  if (idx >= (props.filter.page-1)*20 && idx < (props.filter.page)*20) {
                    return <Table.Row key={idx}>
                      <Table.Cell>{idx + 1}</Table.Cell>
                      <Table.Cell>{item.name}</Table.Cell>
                      <Table.Cell>{!!item.types?item.types.join(", "):"n/a"}</Table.Cell>
                      <Table.Cell>{item.distance}</Table.Cell>
                      <Table.Cell>{item.rating===-1?"n/a":item.rating}</Table.Cell>
                    </Table.Row>;
                  } else {
                    return ;
                  }
                })}
              </Table.Body>
            </Table>

            <br/>

            <Grid>
            <Grid.Row>
              <Grid.Column>
                <Paging total={props.competitors.length} limit={20} page={props.filter.page} size={'mini'} boundaryRange={0} noLimit={true} handleChange={pageChange}/>
              </Grid.Column>
            </Grid.Row>
          </Grid></React.Fragment>}
          {props.competitors.length === 0 && enableSearch && <React.Fragment><br/><br/><div>No competitors found, try another filters</div></React.Fragment>}
        </div>
      </Grid.Column>
    </Grid.Row>

  </React.Fragment>;
})

export default CompetitorsTab
