import React, {useState, useEffect, useRef} from 'react';
import {Provider, useSelector} from 'react-redux';
import {Route, BrowserRouter as Router, Routes, Navigate, useLocation} from 'react-router-dom';
import {applyMiddleware, createStore} from 'redux';
import cfg, {
  ROLE_ADMIN,
  ROLE_MANAGER,
  ROLE_SUBSCRIBER,
  ROLE_SUBSCRIBER_MAP,
  ROLE_SUBSCRIBER_REPORTS, ROLE_USER
} from "../../constants";
import thunk from 'redux-thunk';
import AppReducer from "../../reducers/index";
import {UPDATE_LANGUAGE, UPDATE_SIGNIN} from "../../constants";
import {getLanguage, getUser} from "../../actions/loginAction";
import {ToastContainer} from "react-toastify";
import RestoreComplete from '../login/RestoreComplete';
import Restore from '../login/Restore';
import SetPassword from '../login/SetPassword';
import Login from '../login/Login';
import Logout from '../login/Logout';
import Contact from '../contact/Contact';
import Profile from '../profile/Profile';
import Users from '../users/Users';
import NotFound from '../not-found/NotFound';
import Forbidden from '../forbidden/Forbidden';
import RegisterComplete from '../login/RegisterComplete';
import Register from '../login/Register';
import Contacts from '../contacts/Contacts';
import Tripadvisor from '../tripadvisor/Tripadvisor';
import Property from '../property/Property';
import Poi from '../poi/Poi';
import Page from '../page/Page';
import PageItem from '../page/PageItem';
import Population from '../population/Population';
import LocationReport from '../location-report/LocationReport';
import Orders from "../orders/Orders";
import PlaceMapCrime from "../place-map/PlaceMapCrime";
import MyReports from "../orders/MyReports";
import PlaceMapDynamicFootfall from "../place-map/PlaceMapDynamicFootfall";
import PlaceMapPopulation from "../place-map/PlaceMapPopulation";
import { Helmet } from 'react-helmet';
import MenuComparison from "../menu/MenuComparison";
import PricePulse from "../menu/PricePulse";
import PlaceMapCompetitive from "../place-map/PlaceMapCompetitive";
import Subscription from "../subscription/Subscription";
import PlaceMapDeliveryZone from "../place-map/PlaceMapDeliveryZone";

const store = createStore(AppReducer, applyMiddleware(thunk));

export default function App() {
  const isMounted = useRef(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (isMounted.current === false) {
      isMounted.current = true;
      cfg.base_url = process.env && process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_PROD_URL : process.env.REACT_APP_LOCAL_URL;
      cfg.public_url = process.env && process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_PROD_PUBLIC_URL : process.env.REACT_APP_LOCAL_PUBLIC_URL;
      Object.freeze(cfg);
    }
    if (ready) {
      return;
    }
    // get user from localStorage && set to global state
    store.dispatch({type: UPDATE_SIGNIN, profile: getUser()});
    store.dispatch({type: UPDATE_LANGUAGE, lang: getLanguage()});
    // store.dispatch(getLanguagesAction());
    setReady(true);

  }, [ready]);

  if (!ready) {
    return <div>Loading ...</div>
  }

  return (
    <React.Fragment>
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/place-map" element={<RequireAuth authorizedRoles={[ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_MAP]}>
            <PlaceMapDynamicFootfall/>
          </RequireAuth>}/>
          <Route path="/" element={<RequireAuth authorizedRoles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_MAP, ROLE_SUBSCRIBER_REPORTS, ROLE_USER]}>
            <Navigate to="/profile"/>
          </RequireAuth>}/>
          <Route path="/profile" element={<RequireAuth authorizedRoles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_MAP, ROLE_SUBSCRIBER_REPORTS, ROLE_USER]}>
            <Profile/>
          </RequireAuth>}/>
          <Route path="/users" element={<RequireAuth>
            <Users/>
          </RequireAuth>}/>
          <Route path="/contacts" element={<RequireAuth>
            <Contacts/>
          </RequireAuth>}/>
          <Route path="/contact" element={<RequireAuth authorizedRoles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_MAP, ROLE_SUBSCRIBER_REPORTS]}>
            <Contact/>
          </RequireAuth>}/>
          <Route path="/orders" element={<RequireAuth>
            <Orders/>
          </RequireAuth>}/>
          <Route path="/tripadvisor" element={<RequireAuth>
            <Tripadvisor/>
          </RequireAuth>}/>
          <Route path="/property" element={<RequireAuth>
            <Property/>
          </RequireAuth>}/>
          <Route path="/poi" element={<RequireAuth>
            <Poi/>
          </RequireAuth>}/>
          <Route path="/population" element={<RequireAuth>
            <Population/>
          </RequireAuth>}/>
          <Route path="/crime-map" element={<RequireAuth>
            <PlaceMapCrime/>
          </RequireAuth>}/>

          <Route path="/delivery-zone" element={<RequireAuth>
            <PlaceMapDeliveryZone/>
          </RequireAuth>}/>

          <Route path="/subscription" element={<RequireAuth authorizedRoles={[ROLE_USER, ROLE_SUBSCRIBER_MAP, ROLE_SUBSCRIBER_REPORTS]}>
            <Subscription/>
          </RequireAuth>}/>

          <Route path="/page" element={<RequireAuth authorizedRoles={[ROLE_MANAGER]}>
            <Page/>
          </RequireAuth>}/>
          <Route path="/page/:pageId" element={<RequireAuth authorizedRoles={[ROLE_MANAGER]}>
            <PageItem/>
          </RequireAuth>}/>

          <Route path="/alcohol-map" element={<RequireAuth authorizedRoles={[ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_MAP]}>
            <PlaceMapPopulation mode={"liquor_stores"}/>
          </RequireAuth>}/>

          <Route path="/poi-map" element={<RequireAuth authorizedRoles={[ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_MAP, ROLE_USER, ROLE_SUBSCRIBER_REPORTS]}>
            <PlaceMapCompetitive/>
          </RequireAuth>}/>

          <Route path="/price-pulse" element={<RequireAuth authorizedRoles={[ROLE_SUBSCRIBER]}>
            <PricePulse/>
          </RequireAuth>}/>

          <Route path="/place-map-tests" element={<RequireAuth authorizedRoles={[ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_MAP]}><PlaceMapDynamicFootfall/></RequireAuth>}/>
          <Route path="/my-reports" element={<RequireAuth authorizedRoles={[ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_REPORTS]}><MyReports/></RequireAuth>}/>
          <Route path="/menu-comparison" element={<RequireAuth><MenuComparison/></RequireAuth>}/>

          <Route path="login" element={<Login/>}/>
          <Route path="login/stripe" element={<Login/>}/>
          <Route path="register" index element={<Register/>}/>
          <Route path="register-complete" element={<RegisterComplete/>}/>
          <Route path="location-report/:reportId" element={<LocationReport/>} />
          <Route path="location-report" element={<RequireAuth authorizedRoles={[ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_REPORTS, ROLE_USER]}><LocationReport/></RequireAuth>} />
          <Route path="restore/:code" element={<SetPassword/>}/>
          <Route path="restore" element={<Restore/>}/>
          <Route path="restore-complete" element={<RestoreComplete/>}/>
          <Route path="logout" element={<Logout/>}/>
          <Route path="forbidden" element={<Forbidden/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
        <ToastContainer />

        <Helmet>
          <title>GetPlace</title>
          <meta name="description" content="Find the best place for your business" />
        </Helmet>
      </Router>
    </Provider>
      <Footer />
    </React.Fragment>
  );
}

function Footer() {
  return <div className={"location-report-footer"}><div>© 2023 getplace.io</div></div>;
}

function RequireAuth({children, authorizedRoles = [], redirectTo = '/login'}) {

  authorizedRoles = authorizedRoles.concat([ROLE_ADMIN]);

  const t = useSelector(state => state);

  const isAuthenticated = useSelector(state => !!state.user.profile.token)
  const isAuthorized = useSelector(state => !!state.user.profile.token && authorizedRoles.indexOf(state.user.profile.role_id) !== -1)
  const location = useLocation();
  if (!isAuthenticated) {
    return <Navigate to={redirectTo} state={{from: location}} replace  />
  }
  if (!isAuthorized) {
    return <Navigate to={'/forbidden'} state={{from: location}} replace  />
  }

  return children;
}

