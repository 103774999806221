import React, {useState, useRef} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Link} from "react-router-dom";
import {Container, Button, Form, Segment, Message} from "semantic-ui-react";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {formatErrors, validateEmail} from "../../constants";
import {restoreAction} from "../../actions/loginAction";
import ReCAPTCHA from "react-google-recaptcha";
import cfg from "../../constants";

function Restore(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  const [sent, setSent] = useState(false);
  const [form, handleChange] = useState({
    email: '', captcha: '',
  });

  const recaptchaRef = useRef(null);

  const handleChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({...form, [name]: value});
    setErrors({...errors, [name]: value.trim() === ''});
  }

  const handleCaptcha = (value) => {
    setErrors({...errors, captcha: value.trim() === ''});
    handleChange({...form, captcha: value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return false;
    }
    let errs = {};
    if (form.email === '' || validateEmail(form.email) !== true) {
      errs.email = true;
    }
    if (captcha && !form.captcha) {
      errs.captcha = true
    }
    setErrors(errs);

    if (Object.keys(errs).length > 0) {
      return false;
    }
    setLoading(true);

    dispatch(restoreAction({email: form.email, captcha: form.captcha})).then(() => {
      setLoading(false);
      setSent(true);
    }).catch(err => {
      setLoading(false);
      if (captcha) {
        handleChange({...form, captcha: ''});
        recaptchaRef.current.reset();
      }
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      if (err.message === 'wrong:email') {
        setErrors({email: true});
      } else if (err.message === 'need:captcha' || err.message === 'wrong:captcha' || err.message === 'empty:captcha') {
        setCaptcha(true);
        setErrors({captcha: true});
      }
    });
  }

  return <React.Fragment>
    <MyAppBar/>

    <Container className={'container-narrow'}>
      <Header as={'h3'} textAlign='center'>Password reset</Header>

       <Form size='large' noValidate>
         {sent && <Message positive>
           Password reset instructions have been sent to your email address {form.email}
         </Message>}

         {!sent &&<Segment stacked>
          <Form.Input error={!!errors.email} label="Email Address" name={'email'} type="text" value={form.email}
                      onChange={handleChangeForm} />

          {captcha && <Form.Field>
             <div>
               <ReCAPTCHA
                 sitekey={cfg.recaptcha}
                 onChange={handleCaptcha}
                 ref={recaptchaRef}
               />
             </div>
             {!!errors.captcha && <Message negative>Please confirm that you are not a robot</Message>}
           </Form.Field>}

          <Button type="button" onClick={handleSubmit} fluid size='medium' color="black">Continue</Button>
        </Segment>}

        <Grid>
          <Grid.Column>
            <Link to="/login" className={'purple'}>{"Sign in"}</Link>
          </Grid.Column>
        </Grid>
      </Form>
    </Container>
  </React.Fragment>;
}

export default Restore
