import React, {useEffect, useState} from 'react';
import {Menu, Container, Icon, Dropdown} from "semantic-ui-react";
import {Link, NavLink} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import "./MyAppBar.css";
import {
  LIMIT_FREE_SERVICE_USE,
  ROLE_ADMIN,
  ROLE_MANAGER,
  ROLE_SUBSCRIBER,
  ROLE_SUBSCRIBER_MAP,
  ROLE_SUBSCRIBER_REPORTS, ROLE_USER
} from '../../constants';
import {getUser, getPaymentSes} from "../../actions/loginAction";
import {Track} from "../Overlay/Track";
import BuyOverlay from "../Overlay/BuyOverlay";
import LoginPopup from "../../components/login/popup/LoginPopup";

function MyAppBar(props) {
  const auth = useSelector(state => !!state.user.profile.token);
  const roleID = useSelector(state => state.user.profile.role_id || 0);

  return (([ROLE_ADMIN, ROLE_MANAGER].indexOf(roleID) >= 0)?
    <AppBarClassic auth={auth} roleID={roleID}/>:
  <AppBarWhite fixed={props?.fixed} newReportNoAuth={props?.newReportNoAuth} newReportAuth={props?.newReportAuth} buyFullSub={props?.buyFullSub} showReportCnt={props?.showReportCnt} 
    disableLogo={props?.disableLogo} showPopup={props?.showPopup} closeCallback={props?.closeCallback} showGuestInterface={props?.showGuestInterface} reportSess={props?.reportSess}/>)
}

function AppBarClassic({roleID, auth}) {

  return <Menu inverted className={"appbar-nomargin"}>
    <Container style={{minWidth:"600px"}}>
      <Menu.Item content={<b style={{fontSize: '18px'}}>GetPlace.io</b>} name='name' className={'purple'} as={Link} to="/"/>

      <Dropdown item text='Administration' style={{zIndex:"10000"}}>
        <Dropdown.Menu>
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/users">Users</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/contacts">Contacts</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/orders">Orders</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/property">Commercial properties</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/tripadvisor">Tripadvisor data</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/poi">POI</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/page">Articles</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>

      <Dropdown item text='App features' style={{zIndex:"10000"}}>
        <Dropdown.Menu>
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/location-report">Reports</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/poi-map">Competitive areas</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/place-map">Heat map</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/delivery-zone">Delivery zones map</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/alcohol-map">Alcohol map</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/price-pulse">Price pulse</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/menu-comparison">Menu comparison</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/crime-map">Crime map(broken)</Dropdown.Item>}
          {(roleID === ROLE_ADMIN) && <Dropdown.Item as={Link} to="/population">Population</Dropdown.Item>}
        </Dropdown.Menu>
      </Dropdown>

      <Menu.Menu position='right'>
        <NavLink to="/contact" className={({ isActive }) => "item" + (isActive ? " active" : "")}><Icon
          name='mail'/></NavLink>

        {auth && <NavLink to="/profile" className={({ isActive }) => "item" + (isActive ? " active" : "")}><Icon
          name='user'/></NavLink>}

        {auth && <Menu.Item name='logout' as={Link} to="/logout" icon><Icon name='log out'/></Menu.Item>}

        {!auth && <React.Fragment>
          <NavLink to="/login" className={({ isActive }) => "item" + (isActive ? " active" : "")}>Login</NavLink>
        </React.Fragment>}
      </Menu.Menu>
    </Container>
  </Menu>;
}

// TODO::wrap params into option obj
// TODO::appbar-white-links
// TODO::update user data after any changes in profile
function AppBarWhite({fixed, newReportNoAuth, newReportAuth, showReportCnt, buyFullSub, disableLogo, showPopup, closeCallback, showGuestInterface, reportSess}) {
  const dispatch = useDispatch();
  const [controlActive, setControlActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showPopupSelf, setShowPopupSelf] = useState(false)
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const profile = getUser();

  const alcoUsers = ["alex.d@yepstore.co.uk"];

  function Buy() {
    if (loading) {
      return;
    }
    setLoading(true);
    dispatch(getPaymentSes()).then((res) => {
      setLoading(false);
      window.open("https://buy.stripe.com/00g15X53X1F59ws5kH?client_reference_id=" + res);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (!!showPopup) {
      setShowPopupSelf(true);
    }
    if (!!!showPopupSelf && showPopup) {
      closeCallback();
    }
  }, [showPopup, showPopupSelf]);

  // TODO::refactor html to rem this
  const appbarLinksWidth = (function(profile){
    let width = 800;

    if ([ROLE_SUBSCRIBER_MAP].indexOf(profile?.role_id) >= 0) {
      width = 600;
    }

    if ([ROLE_SUBSCRIBER].indexOf(profile?.role_id) >= 0) {
      if (alcoUsers.indexOf(profile.email) >= 0) {
        width = 800;
      } else {
        width = 750;
      }
    }

    return width+"";
  })(profile)

  function ChangeControlActive(control) {
    if (controlActive === control) {
      setControlActive(0);
    } else {
      setControlActive(control);
    }
  }

  const getAccess = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
      dispatch(getPaymentSes()).then((res) => {
        setLoading(false);
        document.location.href = "https://buy.stripe.com/9AQ3e5fIBbfF4c828u?client_reference_id=" + res;
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const handleClosePopup = () => {
    setShowPopupSelf(!showPopupSelf);
  }

  const controlProfile = 1;

  // tmp
  function a(e) {
    if (!e.target.classList.contains("active")) {
      Track(dispatch, profile, "Reports Tab Clicked");
    } else {
      // e.preventDefault();
      // window.location.reload();
    }
  }

  function RunLoginPopup(e) {
    e.preventDefault();
    setShowLoginPopup(true);
  }

  function HandleCloseLoginPopup() {
    setShowLoginPopup(false);
  }

  return <React.Fragment>
    {showLoginPopup && <LoginPopup handleClose={HandleCloseLoginPopup} reportSess={reportSess}/>}
    {showPopupSelf && <BuyOverlay handleClose={handleClosePopup} buyCallback={Buy}/>}
    <div className={'appbar-white'+(fixed?' appbar-white-fixed':'')}>
      {disableLogo !== true && <div className={'appbar-white-ico'} ><a href={'https://getplace.io'} id="navLogo" target="_blank">getplace.io</a></div>}
      <div className={'appbar-white-links'} style={{width: appbarLinksWidth+"px"}}>
        {/*<a href="https://getplace.io" target="_blank">Home</a>*/}

        {!profile?.id && !!showGuestInterface && <NavLink onClick={(e) => {RunLoginPopup(e)}} to="/poi-map" className={({ isActive }) => (isActive ? "active" : "")}>Competitive Areas</NavLink>}
        {!profile?.id && !!showGuestInterface && <NavLink onClick={(e) => {RunLoginPopup(e)}} to="/location-report" className={({ isActive }) => (isActive ? "active" : "")}>Reports</NavLink>}

        {!!profile?.id && [ROLE_SUBSCRIBER_MAP, ROLE_SUBSCRIBER, ROLE_USER, ROLE_SUBSCRIBER_REPORTS].indexOf(profile.role_id) >= 0 && <NavLink reloadDocument to="/poi-map" className={({ isActive }) => (isActive ? "active" : "")}>Competitive Areas</NavLink>}
        {!!profile?.id && [ROLE_SUBSCRIBER_REPORTS, ROLE_SUBSCRIBER, ROLE_SUBSCRIBER_REPORTS, ROLE_USER].indexOf(profile.role_id) >= 0 &&
          <NavLink reloadDocument onClick={(e) => {a(e)}} to="/location-report" className={({ isActive }) => (isActive ? "active" : "")}>Reports</NavLink>}

        {!!profile?.id && [ROLE_SUBSCRIBER_MAP, ROLE_SUBSCRIBER].indexOf(profile.role_id) >= 0 && <NavLink to="/place-map" className={({ isActive }) => (isActive ? "active" : "")}>Map</NavLink>}
        {!!profile?.id && [ROLE_SUBSCRIBER_MAP, ROLE_SUBSCRIBER].indexOf(profile.role_id) >= 0  && alcoUsers.indexOf(profile.email) >= 0 && <NavLink reloadDocument to="/alcohol-map" className={({ isActive }) => (isActive ? "active" : "")}>Alcohol Map</NavLink>}
        
        {!!profile?.id && [ROLE_SUBSCRIBER].indexOf(profile.role_id) >= 0 && <NavLink onClick={() => {Track(dispatch, profile, "Price Pulse Tab Clicked")}} to="/price-pulse" className={({ isActive }) => (isActive ? "active" : "")}>Price Pulse</NavLink>}

        {/* ROLE_USER */}
        {!!profile?.id && [ROLE_USER].indexOf(profile.role_id) >= 0 &&
          <NavLink onClick={(e) => {Track(dispatch, profile, "Price Pulse Tab Clicked");}} to="/subscription" className={({ }) => ("")}><span className={'gray-menu'}>Price Pulse</span>
            <img width={20} src="/icons/lock.png" /></NavLink>}
        {!!profile?.id && [ROLE_USER].indexOf(profile.role_id) >= 0 &&
          <NavLink onClick={(e) => { Track(dispatch, profile, "HeatMap Tab Clicked");}} to="/subscription" className={({ }) => ("")}><span className={'gray-menu'}>Footfall Heat Map</span>
            <img width={20} src="/icons/lock.png" /></NavLink>}

        {/* ROLE_SUBSCRIBER_REPORTS */}
        {!!profile?.id && [ROLE_SUBSCRIBER_REPORTS].indexOf(profile.role_id) >= 0 &&
          <NavLink onClick={(e) => { Track(dispatch, profile, "Price Pulse Tab Clicked");}} to="/subscription" className={({ }) => ("")}><span className={'gray-menu'}>Price Pulse</span>
            <img width={20} src="/icons/lock.png" /></NavLink>}
        {!!profile?.id && [ROLE_SUBSCRIBER_REPORTS].indexOf(profile.role_id) >= 0 &&
          <NavLink onClick={(e) => { Track(dispatch, profile, "HeatMap Tab Clicked");}} to="/subscription" className={({ }) => ("")}><span className={'gray-menu'}>Footfall Heat Map</span>
            <img width={20} src="/icons/lock.png" /></NavLink>}

        {/*!!profile?.id && [ROLE_USER].indexOf(profile.role_id) >= 0 && <NavLink onClick={() => {Track(dispatch, profile, "Footfall Tab Clicked")}} to="/" className={({ isActive }) => (isActive ? "active" : "")}>Footfall Heat Map</NavLink>*/}
        {/*!!profile?.id && [ROLE_USER].indexOf(profile.role_id) >= 0 && <NavLink onClick={() => {Track(dispatch, profile, "Food Businesses Tab Clicked")}} to="/" className={({ isActive }) => (isActive ? "active" : "")}>Food Businesses Heat Map</NavLink>*/}
      </div>
      <div className={'appbar-white-spacer'} />
      {!!newReportAuth && <div className={'appbar-white-controls'}>
        <a className={'appbar-new-report gb-magenta'} href={'/location-report'}>Get New Report</a>
      </div>}
      {!!newReportNoAuth && <div className={'appbar-white-controls'}>
        <a className={'appbar-new-report gb-magenta'} href={'https://getplace.io/report'}>Get New Report</a>
      </div>}
      {!!buyFullSub && <div className={'appbar-white-controls'}>
        <a href="#" onClick={(e) => getAccess(e)} className={'appbar-buy-fullsub gb-magenta'}>Get unlimited access</a>
      </div>}
      {!!showReportCnt && <div className={'appbar-white-controls'}>
        <div className={'appbar-report-counter'}><span className={'bold'}>{profile?.free_service_use?profile.free_service_use:0}</span> / {Math.max(LIMIT_FREE_SERVICE_USE, profile?.free_service_use?profile.free_service_use:0)} reports left</div>
      </div>}

      {!!profile?.id && [ROLE_USER].indexOf(profile.role_id) >= 0 && <div className={'appbar-white-controls'}>
        <a className={'appbar-new-report gb-magenta'} href={'https://calendly.com/denis_getplace/30min'} target={"_blank"}>Get Live Demo</a>
      </div>}

      {!!profile?.id && <div className={'appbar-white-controls'}>
        <span className={'appbar-white-controls-profile'} onClick={() => {ChangeControlActive(controlProfile);}} />
        <div className={'appbar-white-controls-profile-popup'+(controlActive===controlProfile?'':' hidden')}>
          <div className={'appbar-profile clearfix'}>
            <div>
              <div className={'appbar-white-controls-profile'} />
            </div>
            <div>
              <span className={'appbar-profile-name'}>{profile?.name?profile.name:'-'}</span>
              <span className={'appbar-profile-email'}>{profile?.email?profile.email:'-'}</span>
              <NavLink to="/profile" className={'profile-button gb-white'}>Edit Profile</NavLink>
              {[ROLE_USER, ROLE_SUBSCRIBER_REPORTS, ROLE_SUBSCRIBER_MAP].indexOf(profile.role_id) >= 0 && <NavLink to="/subscription" className={'profile-button gb-white'}>Subscription plans</NavLink>}
            </div>
          </div>
          <NavLink to="/logout" className={'profile-nav'}>Log Out</NavLink>
        </div>
      </div>}
    </div>
  </React.Fragment>;
}

export default MyAppBar
