import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Container, Icon, Table, Button, Grid, Breadcrumb, Message, Label} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {formatErrors} from "../../constants";
import {pagesAction, pageDeleteAction} from '../../actions/pageAction';
import Paging from '../../utils/Paging/Paging';
import {ConfirmModal} from '../../utils/ConfirmModal/ConfirmModal';


function Page() {
  const [filter, setFilter] = useState(null);
  const filterName = "m_pageFilter";
  
  const handleChangeFilter = (value, name) => {
    if (name === "page") {
      setFilter({...filter, page: value});
    } else if (name === "sort") {
      setFilter({
        ...filter,
        sort: value,
        sortd: filter.sort === value ? (filter.sortd === "asc" ? "desc" : "asc") : "asc",
        page: 1,
      });
    } else if (name) {
      setFilter({...filter, [name]: value, page: 1});
    } else {
      setFilter({...filter});
    }
  };

  useEffect(() => { 
    const dFilter = {page: 1, limit: 10, sort: 'id', sortd: 'desc'};
    let curFilter = localStorage.getItem(filterName);
    curFilter = curFilter ? JSON.parse(curFilter) : dFilter;
  
    setFilter(curFilter);
  }, []);

  return <React.Fragment>
    <MyAppBar/>
      <Container className={'container-wide'}>
          <ButtonsTop handleChange={handleChangeFilter}/>

          {filter && <PagesList  filterName={filterName} filter={filter}
                                   handleChange={handleChangeFilter}/>}
      </Container>
  </React.Fragment>;
}

const ButtonsTop = ({handleChange}) => {
  const navigate = useNavigate();
  return <Grid>
    <Grid.Row columns={2}>
      <Grid.Column>
        <Breadcrumb>
          <Breadcrumb.Section active link>
            Blog articles
          </Breadcrumb.Section>
        </Breadcrumb>
      </Grid.Column>

      <Grid.Column textAlign='right'>
        <Button onClick={() => navigate('/page/new')}>Add a new article</Button>
      </Grid.Column>
    </Grid.Row>
  </Grid>;
};

function PagesList({filter, filterName, handleChange}) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);

  const deletePage = (id) => {
    return new Promise((resolve, reject) => {
      if (loading) {
        return resolve(false);
      }
      setLoading(true);
      dispatch(pageDeleteAction(id)).then(() => {
        setLoading(false);
        resolve(true);
        if (Math.ceil(total / filter.limit) === filter.page && filter.page > 1) {
          handleChange(filter.page - 1, "page");
        } else {
          handleChange();
        }
      }).catch(err => {
        setLoading(false);
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
        reject(err);
      });
    })
  };

  useEffect(() => {
    if (!filter) {
      return;
    }

    dispatch(pagesAction(filter)).then((data) => {
      setItems(data.items || []);
      setTotal(data.total);
      localStorage.setItem(filterName, JSON.stringify(filter));
    }).catch(err => {
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }, [dispatch, filter, filterName]);

  return <React.Fragment>
    {total === 0 && <Message color="purple">
      No articles found.
    </Message>}

    {total > 0 && <Table celled striped sortable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell sorted={filter.sort === "id" ? filter.sortd + "ending" : null}
                            onClick={() => handleChange("id", "sort")}>ID</Table.HeaderCell>
          <Table.HeaderCell sorted={filter.sort === "title" ? filter.sortd + "ending" : null}
                            onClick={() => handleChange("title", "sort")}>Title</Table.HeaderCell>
          <Table.HeaderCell sorted={filter.sort === "navigation" ? filter.sortd + "ending" : null}
                            onClick={() => handleChange("navigation", "sort")}>Unuique name</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell width={2}/>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((i) => {
          return <Table.Row key={i.id}>
            <Table.Cell>{i.id}</Table.Cell>
            <Table.Cell>{i.title_en}</Table.Cell>
            <Table.Cell>{i.navigation}</Table.Cell>
            <Table.Cell>
              {i.active && <Label color='green'>{'Active'}</Label>}
              {!i.active && <Label color='yellow'>{'Not active'}</Label>}
            </Table.Cell>
            <Table.Cell textAlign="center">
              <Button size='mini' icon disabled={loading} as={Link} to={'/page/' + i.id}><Icon name="edit"/></Button>
              <ConfirmModal content='Do you wish to delete this article?' ok={() => deletePage(i.id)}
                            trigger={<Button size='mini' icon><Icon name='delete'/></Button>}/>
            </Table.Cell>
          </Table.Row>;
        })}
      </Table.Body>
    </Table>}

    <Paging total={total} page={filter.page} limit={filter.limit} handleChange={handleChange}/>

  </React.Fragment>;
}

export default Page
