import React, {useEffect, useState} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Grid} from "semantic-ui-react";
import {getOrderIntent, getUser, getRole, storeUser} from "../../actions/loginAction";
import {
  formatErrors,
  OI_FULL_ACCESS, OI_FULL_ACCESS_MONTHLY, OI_FULL_ACCESS_YEARLY, OI_NO_PLAN,
  OI_UNLIMITED_REPORTS,
  ROLE_SUBSCRIBER,
  ROLE_SUBSCRIBER_REPORTS,
  ROLE_USER
} from "../../constants";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";

function Subscription() {

  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState(null);
  const profile = getUser();

  const dispatch = useDispatch();

  useEffect(() => {
    if (role === null) {
      dispatch(getRole()).then((res) => {
        if ([ROLE_USER, ROLE_SUBSCRIBER_REPORTS, ROLE_SUBSCRIBER].indexOf(res.role_id) >= 0) {
          if (res.role_id !== profile.role_id) {
            profile.role_id = res.role_id;
            storeUser(profile);
          }

          setRole(res.role_id);
        } else {
          setRole(profile.role_id);
        }
      }).catch((err) => {
        console.log(err);
        setLoading(false);
        setRole(profile.role_id);

        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [role]);

  const GetIntent = function(e, product) {
    e.preventDefault()
    setLoading(true);
    dispatch(getOrderIntent({product})).then((res) => {
      if (product === OI_FULL_ACCESS_MONTHLY) {
        document.location.href = "https://buy.stripe.com/fZeeWN9kddnNeQM7t5?client_reference_id="+res

        //TEST document.location.href = "https://buy.stripe.com/test_cN26ss2qpgkM3CM8wx?client_reference_id="+res
      } else if (product === OI_FULL_ACCESS_YEARLY) {
        document.location.href = "https://buy.stripe.com/aEU6qhaoh83tgYU6p2?client_reference_id="+res

        //TEST document.location.href = "https://buy.stripe.com/test_cN26ss2qpgkM3CM8wx?client_reference_id="+res
      }
    }).catch((err) => {
      console.log(err);
      setLoading(false);

      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  const SendDowngradeEmail = function (e) {
    e.preventDefault()
    window.location.href = "mailto:info@getplace.io?subject=Subscription plan downgrade";
  }

  const ProductNoPlanStatus = function (role_id) {
    if (role_id === ROLE_USER) {
      return ["disabled", "Current subscription", () => {}];
    } else {
      return ["", "Downgrade", SendDowngradeEmail];
    }
  }(profile.role_id)

  const ProductUnlimitedReportsStatus = function(role_id) {
    if (role_id === ROLE_USER) {
      return ["active", "Upgrade", GetIntent]
    } else if (role_id === ROLE_SUBSCRIBER_REPORTS) {
      return ["disabled", "Current subscription", () => {}]
    } else {
      return ["", "Downgrade", SendDowngradeEmail];
    }
  }(profile.role_id)

  const ProductFullAccessStatus = function(role_id) {
    if (role_id === ROLE_SUBSCRIBER) {
      return ["disabled", "Current subscription", () => {}]
    } else if (role_id === ROLE_SUBSCRIBER_REPORTS){
      return ["active", "Upgrade", GetIntent];
    } else {
      return ["", "Upgrade", GetIntent];
    }
  }(profile.role_id)

  const ProductFullAccessNewStatus = function(role_id) {
    if (role_id === ROLE_SUBSCRIBER) {
      return ["disabled", "Current subscription", () => {}]
    } else {
      return ["", "Upgrade", GetIntent];
    }
  }(profile.role_id)

  return <React.Fragment>

    <div className={"loader-screen"} hidden={!loading}>
      <div>Loading ...</div>
    </div>

    <MyAppBar />

    <div className={"subscriptions"}>
      <h1>Subscription Plans</h1>
      <Grid columns={"equal"}>
        <Grid.Row>
          <Grid.Column>
            <div className={"sub-block"}>
              <div className={"sub-block-header rep1"}>Basic</div>
              <div className={"sub-block-perfect"}>Perfect for getting started</div>
              <div className={"sub-block-pricing"}>£&nbsp;
                <span className={"price"}>0</span>
                <span className={"sub"}>&nbsp;/forever</span>
              </div>
              <div className={"sub-block-buy " + ProductNoPlanStatus[0]}
                onClick={(e) => {ProductNoPlanStatus[2](e, OI_NO_PLAN)}}
              >{ProductNoPlanStatus[1]}</div>
              <div className={"sub-block-includes"}>
                <span>Includes</span>
                <ul>
                  <li>1 Free Report</li>
                  <li>Competitive Areas</li>
                  <li>London Households Density</li>
                </ul>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={"sub-block"}>
              <div className={"sub-block-header rep2"}>Full Access - monthly</div>
              <div className={"sub-block-perfect"}>Best choice to get data-driven</div>
              <div className={"sub-block-pricing"}>£&nbsp;
                <span className={"price"}>499</span>
                <span className={"sub"}>&nbsp;/month</span>
              </div>
              <div className={"sub-block-buy " + ProductFullAccessNewStatus[0]}
                   onClick={(e) => {ProductFullAccessNewStatus[2](e, OI_FULL_ACCESS_MONTHLY)}}
              >{ProductFullAccessNewStatus[1]}</div>
              <div className={"sub-block-includes"}>
                <span>Includes</span>
                <ul>
                  <li>Footfall Heat Map</li>
                  <li>Household Density Heat Map</li>
                  <li>Competitors Density Map</li>
                  <li>Competitors pricing analysis tool</li>
                  <li>Unlimited reports</li>
                </ul>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column>
            <div className={"sub-block"}>
              <div className={"sub-block-header rep3"}>Full Access - Yearly</div>
              <div className={"sub-block-perfect"}>All-year analytics for scaling</div>
              <div className={"sub-block-pricing"}>£&nbsp;
                <span className={"price"}>4990</span>
                <span className={"sub"}>&nbsp;/year</span>
              </div>
              <div className={"sub-block-buy "+ProductFullAccessNewStatus[0]}
                   onClick={(e) => {ProductFullAccessNewStatus[2](e, OI_FULL_ACCESS_YEARLY)}}
              >{ProductFullAccessNewStatus[1]}</div>
              <div className={"sub-block-includes"}>
                <span>Includes</span>
                <ul>
                  <li>Everything in Monthly Full Access</li>
                  <li>Custom solutions</li>
                  <li>Dedicated advisory</li>
                  <li>Priority access to new features and data sets</li>
                </ul>
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <div className={"sub-block-help"}>
              Need help?&nbsp;<a href={"https://calendly.com/denis_getplace"} target={"_blank"}>Contact us</a>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>

  </React.Fragment>;
}

export default Subscription
