import React, {useEffect, useState} from 'react';
import {Grid, Radio, Table} from "semantic-ui-react";
import {CalcDiff} from "../../../constants";

function RealEstateReport({report, tab, handleChange, location}) {

  const tabSale = 0;
  const tabRent = 1;

  const [diffs, setDiffs] = useState(null);

  useEffect(() => {

    if (!!report && diffs === null) {
      setDiffs({
        saleCommercial5m: CalcDiff(report.sale_commercial_median_5_m, report.sale_commercial_median_1_m),
        saleCommercialCity: CalcDiff(report.sale_commercial_median_city, report.sale_commercial_median_1_m),

        rentCommercial5m: CalcDiff(report.rent_commercial_median_5_m, report.rent_commercial_median_1_m),
        rentCommercialCity: CalcDiff(report.rent_commercial_median_city, report.rent_commercial_median_1_m),

        saleResident5m: CalcDiff(report.sale_resident_median_5_m, report.sale_resident_median_1_m),
        saleResidentCity: CalcDiff(report.sale_resident_median_city, report.sale_resident_median_1_m),

        rentResident5m: CalcDiff(report.rent_resident_median_5_m, report.rent_resident_median_1_m),
        rentResidentCity: CalcDiff(report.rent_resident_median_city, report.rent_resident_median_1_m),
      });
    }
  }, [diffs, report]);

  useEffect(() => {
    if (tab === null) {
      handleChange(tabSale);
    }
  }, [tab]);

  function GetClass(val) {
    if (val === 0) {
      return ""
    } else {
      return val > 0?"plus":"minus";
    }
  }

  function CheckData(val) {
    return val === 0?"no data":"£"+val;
  }

  return (tab !== null && !!diffs && <React.Fragment>
    <Grid.Row>
      <Grid.Column>
        <div className={"RE-hdr"}>
          <h2>Real Estate Analysis</h2>
          <div>
            <span>To Sale</span>
            <Radio toggle checked={tab===tabRent} onChange={(e,v) => {handleChange(tab===tabRent?tabSale:tabRent)}}/>
            <span>To Rent</span>
          </div>
        </div>

        <br />
        <h3>Median prices</h3>
        <p>Median prices of 1 square feet per calendar month around the location, {tab===tabRent?'To Rent':'For Sale'}</p>
        <br />
        <div className={"RE-table"}>
          <div>
            <h4>Commercial</h4>
            {tab===tabSale && <Table celled>
              <Table.Body>
                <Table.Row className={"bold"}>
                  <Table.Cell className={"w150p"}>Radius</Table.Cell>
                  <Table.Cell className={"w150p"}>
                    1 mile
                  </Table.Cell>
                  <Table.Cell>5 miles</Table.Cell>
                  <Table.Cell>{location}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className={"bold"}>Median Price</Table.Cell>
                  <Table.Cell>
                    {CheckData(report.sale_commercial_median_1_m)}
                  </Table.Cell>
                  <Table.Cell>
                    <div className={"RE-flex"}>
                      {CheckData(report.sale_commercial_median_5_m)}<br/>
                      <div className={"DFA-diff"}>
                        <div className={GetClass(diffs.saleCommercial5m)}>{diffs.saleCommercial5m}%</div>
                        <p>{diffs.saleCommercial5m >= 0?"more":"less"} than <b>1 mile</b></p>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className={"RE-flex"}>
                      {CheckData(report.sale_commercial_median_city)}
                      <div className={"DFA-diff"}>
                        <div className={GetClass(diffs.saleCommercialCity)}>{diffs.saleCommercialCity}%</div>
                        <p>{diffs.saleCommercialCity >= 0?"more":"less"} than <b>1 mile</b></p>
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>}

            {tab===tabRent && <Table celled>
              <Table.Body>
                <Table.Row className={"bold"}>
                  <Table.Cell className={"w150p"}>Radius</Table.Cell>
                  <Table.Cell className={"w150p"}>
                    1 mile
                  </Table.Cell>
                  <Table.Cell>5 miles</Table.Cell>
                  <Table.Cell>{location}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className={"bold"}>Median Price</Table.Cell>
                  <Table.Cell>
                    {CheckData(report.rent_commercial_median_1_m)}
                  </Table.Cell>
                  <Table.Cell>
                    <div className={"RE-flex"}>
                      {CheckData(report.rent_commercial_median_5_m)}<br/>
                      <div className={"DFA-diff"}>
                        <div className={GetClass(diffs.rentCommercial5m)}>{diffs.rentCommercial5m}%</div>
                        <p>{diffs.rentCommercial5m >= 0?"more":"less"} than <b>1 mile</b></p>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className={"RE-flex"}>
                      {CheckData(report.rent_commercial_median_city)}
                      <div className={"DFA-diff"}>
                        <div className={GetClass(diffs.rentCommercialCity)}>{diffs.rentCommercialCity}%</div>
                        <p>{diffs.rentCommercialCity >= 0?"more":"less"} than <b>1 mile</b></p>
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>}

          </div>
          <div>
            <h4>Residential</h4>
            {tab===tabSale && <Table celled>
              <Table.Body>
                <Table.Row className={"bold"}>
                  <Table.Cell className={"w150p"}>Radius</Table.Cell>
                  <Table.Cell className={"w150p"}>1 mile</Table.Cell>
                  <Table.Cell>5 miles</Table.Cell>
                  <Table.Cell>{location}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className={"bold"}>Median Price</Table.Cell>
                  <Table.Cell>
                    {CheckData(report.sale_resident_median_1_m)}
                  </Table.Cell>
                  <Table.Cell>
                    <div className={"RE-flex"}>
                      {CheckData(report.sale_resident_median_5_m)}
                      <div className={"DFA-diff"}>
                        <div className={GetClass(diffs.saleResident5m)}>{diffs.saleResident5m}%</div>
                        <p>{diffs.saleResident5m >= 0?"more":"less"} than <b>1 mile</b></p>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className={"RE-flex"}>
                      {CheckData(report.sale_resident_median_city)}
                      <div className={"DFA-diff"}>
                        <div className={GetClass(diffs.saleResidentCity)}>{diffs.saleResidentCity}%</div>
                        <p>{diffs.saleResidentCity >= 0?"more":"less"} than <b>1 mile</b></p>
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>}

            {tab===tabRent && <Table celled>
              <Table.Body>
                <Table.Row className={"bold"}>
                  <Table.Cell className={"w150p"}>Radius</Table.Cell>
                  <Table.Cell className={"w150p"}>1 mile</Table.Cell>
                  <Table.Cell>5 miles</Table.Cell>
                  <Table.Cell>{location}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell className={"bold"}>Median Price</Table.Cell>
                  <Table.Cell>
                    {CheckData(report.rent_resident_median_1_m)}
                  </Table.Cell>
                  <Table.Cell>
                    <div className={"RE-flex"}>
                      {CheckData(report.rent_resident_median_5_m)}
                      <div className={"DFA-diff"}>
                        <div className={GetClass(diffs.rentResident5m)}>{diffs.rentResident5m}%</div>
                        <p>{diffs.rentResident5m >= 0?"more":"less"} than <b>1 mile</b></p>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div className={"RE-flex"}>
                      {CheckData(report.rent_resident_median_city)}
                      <div className={"DFA-diff"}>
                        <div className={GetClass(diffs.rentResidentCity)}>{diffs.rentResidentCity}%</div>
                        <p>{diffs.rentResidentCity >= 0?"more":"less"} than <b>1 mile</b></p>
                      </div>
                    </div>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>}
          </div>
        </div>
      </Grid.Column>
    </Grid.Row>
    <Grid.Row>
      <Grid.Column>
      </Grid.Column>
    </Grid.Row>
  </React.Fragment>);
}

export default RealEstateReport
