import React, {useEffect, useState} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Breadcrumb, Button, Container, Dropdown, Grid, Icon, Input, Message, Table} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import {orderDeleteAction, ordersAction} from "../../actions/ordersAction";
import {toast} from "react-toastify";
import cfg, {EmptyUUID, formatErrors, getRolesNames, roles} from "../../constants";
import {ConfirmModal} from "../../utils/ConfirmModal/ConfirmModal";
import Paging from "../../utils/Paging/Paging";

function Orders() {
  const [filter, setFilter] = useState(null);
  const filterName = "m_OrdersFilter";

  const handleChangeFilter = (value, name) => {
    if (name === "page") {
      setFilter({...filter, page: value});
    } else if (name === "sort") {
      setFilter({
        ...filter,
        sort: value,
        sortd: filter.sort === value ? (filter.sortd === "asc" ? "desc" : "asc") : "asc",
        page: 1
      });
    } else if (name === "type") {
      setFilter({
        ...filter,
        type: value===""?0:value,
        page: 1
      });
    } else if (name) {
      setFilter({...filter, [name]: value, page: 1});
    } else {
      setFilter({...filter});
    }
  };

  useEffect(() => {
    const dFilter = {page: 1, limit: 10, sort: 'created', sortd: 'desc', type: 0, search:""};
    let curFilter = localStorage.getItem(filterName);
    curFilter = curFilter ? JSON.parse(curFilter) : dFilter;

    setFilter(curFilter);
  }, []);


  return <React.Fragment>
    <MyAppBar/>

    <Container className={'container-wide'}>
      {filter && <ButtonsTop filter={filter} handleChange={handleChangeFilter}/>}
      {filter && <OrdersList  filterName={filterName} filter={filter} handleChange={handleChangeFilter}/>}
    </Container>
  </React.Fragment>;
}

const ButtonsTop = ({filter, handleChange}) => {
  return <Grid>
    <Grid.Row columns={2}>
      <Grid.Column>
        <Breadcrumb>
          <Breadcrumb.Section active>
            Orders
          </Breadcrumb.Section>
        </Breadcrumb>
      </Grid.Column>

      <Grid.Column textAlign='right'>
        <SearchFilter handleChange={handleChange} filter={filter}/>
        <OrdersFilter filter={filter} handleChange={handleChange} />
      </Grid.Column>
    </Grid.Row>
  </Grid>;
};

function SearchFilter({filter, handleChange}) {

  const [val, setVal] = useState(null);

  useEffect(() => {
    if (val === null) {
      setVal(!filter?.search?"":filter.search);
    }
  }, [val])

  const Search = () => {
    if (val.length > 2 || val === "") {
      handleChange(val, "search");
    }
  }

  return <Input
    icon={{ name: 'search', circular: true, link: true, onClick: () => {Search()} }}
    placeholder='Email filter'
    className={"orders-email-filter"}
    value = {val===null?"":val}
    onChange={(e,v) => {setVal(v.value);}}
  >

  </Input>;
}

function OrdersList({filter, filterName, handleChange}) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);

  const deleteOrder = (id) => {
    return new Promise((resolve, reject) => {
      if (loading) {
        return resolve(false);
      }
      setLoading(true);
      dispatch(orderDeleteAction(id)).then(() => {
        setLoading(false);
        resolve(true);
        if (Math.ceil(total / filter.limit) === filter.page && filter.page > 1) {
          handleChange(filter.page - 1, "page");
        } else {
          handleChange();
        }
      }).catch(err => {
        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
        reject(err);
      });
    })
  };

  useEffect(() => {
    if (!filter) {
      return;
    }

    dispatch(ordersAction(filter)).then((data) => {
      setItems(data.items || []);
      setTotal(data.total);
      localStorage.setItem(filterName, JSON.stringify(filter));
    }).catch(err => {
      console.log(err)
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }, [dispatch, filter, filterName]);

  return <React.Fragment>
    {total === 0 && <Message color="purple">No records found.</Message>}

    {total > 0 && <Table celled striped sortable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>User Email</Table.HeaderCell>
          <Table.HeaderCell>Role</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Full name</Table.HeaderCell>
          <Table.HeaderCell>Company</Table.HeaderCell>
          <Table.HeaderCell>Customer Email</Table.HeaderCell>
          <Table.HeaderCell>Paid</Table.HeaderCell>
          <Table.HeaderCell>Stripe Intent id</Table.HeaderCell>
          <Table.HeaderCell>Link</Table.HeaderCell>
          <Table.HeaderCell width={2}/>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((i) => {
          return <Table.Row key={i.id}>
            <Table.Cell>{i.user_email?i.user_email:'-'}</Table.Cell>
            <Table.Cell>{i.role_id<0?'Customer':getRolesNames[i.role_id]}</Table.Cell>
            <Table.Cell>{i.created}</Table.Cell>
            <Table.Cell>{i.full_name}</Table.Cell>
            <Table.Cell>{i.company_name}</Table.Cell>
            <Table.Cell>{i.email}</Table.Cell>
            <Table.Cell className={i.is_paid?'lc-green':'lc-red'}>{i.is_paid?'Yes':'No'}</Table.Cell>
            <Table.Cell>{i.payment_intent}</Table.Cell>
            <Table.Cell>{i.report_num!==""?<a target={"_blank"} href={cfg.base_url+"/location-report/"+i.report_num}>{i.report_num}</a>:"-"}</Table.Cell>
            <Table.Cell textAlign="center">
              <ConfirmModal content='Do you wish to delete order?' ok={() => deleteOrder(i.id)}
                            trigger={<Button size='mini' icon><Icon name='delete'/></Button>}/>
            </Table.Cell>
          </Table.Row>;
        })}
      </Table.Body>
    </Table>}

    <Paging total={total} page={filter.page} limit={filter.limit} handleChange={handleChange}/>

  </React.Fragment>;
}

function OrdersFilter({filter, handleChange}) {

  const handleChangeDropDown = (e, data) => {
    const name = data.name;
    let value = data.value;
    handleChange(value, name);
  };

  const userTypes = [
    {key: 1, text: 'Any user', value: 0},
    {key: 2, text: 'Customer', value: 1},
    {key: 3, text: 'Any user with role', value: 2},
    {key: 4, text: 'Subscriber - all options', value: 3},
    {key: 5, text: 'Subscriber - reports only', value: 4},
  ];

  return (
    <Dropdown clearable options={userTypes} selection placeholder='Select type' value={!!filter?filter.type:0}
              name="type" onChange={handleChangeDropDown} />
  )
}

export default Orders
