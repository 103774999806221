import React, {useState} from 'react'
import {Button, Modal} from 'semantic-ui-react'

export function ConfirmModal({ok, trigger, title = '', content = ''}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOK = () => {
    setLoading(true);
    Promise.resolve(ok(false)).then((res) => {
      setLoading(false);
      if (res) {
        setOpen(false);
      }
    }).catch(() => {
      setLoading(false);
    })
  };

  return (
    <Modal closeIcon={false}
           closeOnDocumentClick={false}
           closeOnDimmerClick={false}
           onOpen={() => setOpen(true)}
           open={open}
           trigger={trigger}
           size={'tiny'}>
      <React.Fragment>
        <Modal.Header>{title ? '' : 'Please confirm'}</Modal.Header>
        <Modal.Content>
          <p>{content}</p>
         </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)} disabled={loading}>Cancel</Button>
          <Button type="button" color="orange" onClick={handleOK} loading={loading} disabled={loading}>Ok</Button>
        </Modal.Actions>
      </React.Fragment>
    </Modal>
  )
}