import React, {useEffect, useRef, useState} from 'react';
import {Link, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Button, Form, Header, Segment, Container, Message, Grid} from "semantic-ui-react";
import {authAction} from "../../actions/loginAction";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import { Helmet } from 'react-helmet';
import {
  formatErrors,
  ROLE_SUBSCRIBER,
  ROLE_SUBSCRIBER_MAP,
  ROLE_SUBSCRIBER_REPORTS, ROLE_USER,
  validateEmail
} from "../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import cfg from "../../constants";

export function Login(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [errors, setErrors] = useState({});
  const [searchParams] = useSearchParams();
  const [form, handleChange] = useState({
    email: '',
    password: '',
    code: '',
    captcha: ''
  });
  const [htag, setHtag] = useState(null);
  const [sessLogLimit, setSessLogLimit] = useState(5);
  
  useEffect(() => {
    if (htag === null) {
      setHtag(Math.random());
    }
  }, [htag]);

  useEffect(() => {
    if (searchParams.get('sess') !== null && searchParams.get('sess')!=='' && !loading && sessLogLimit > 0) {
      setLoading(true);
      dispatch(authAction({sess: searchParams.get('sess')})).then((res) => {
        setLoading(false);

        if (res.role_id===ROLE_USER) {
          if (res?.welcome === 1) {
            navigate('/poi-map', {state:{welcome: 1}});
          } else {
            navigate('/poi-map');
          }
        } else if (res.role_id===ROLE_SUBSCRIBER_REPORTS) {
          if (res?.welcome === 1) {
            navigate('/location-report', {state:{welcome: 1}});
            // navigate('/location-report');
          } else {
            navigate('/location-report');
          }
        }else if (res.role_id===ROLE_SUBSCRIBER_MAP || res.role_id===ROLE_SUBSCRIBER) {
          if (res?.welcome === 1) {
            navigate('/place-map', {state:{welcome: 1}});
            // navigate('/location-report');
          } else {
            navigate('/place-map');
          }
        } else {
          navigate(location.state?.from?.pathname || '/');
        }
      }).catch(err => {
         setSessLogLimit(sessLogLimit-1);
         setLoading(false);
      });
    }
  }, [loading, dispatch, sessLogLimit]);
  
  const recaptchaRef = useRef(null);

  const handleChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({...form, [name]: value});
    setErrors({...errors, [name]: value.trim() === ''});
  }

  const handleCaptcha = (value) => {
    setErrors({...errors, captcha: value.trim() === ''});
    handleChange({...form, captcha: value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return false;
    }
    let errs = {};
    if (form.email === '' || validateEmail(form.email) !== true) {
      errs.email = true;
    }
    if (form.password === '') {
      errs.password = true;
    }
    if (code && form.code === '') {
      errs.code = true;
    }
    if (captcha && !form.captcha) {
      errs.captcha = true
    }

    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      return;
    }

    setLoading(true);
    dispatch(authAction(form)).then((res) => {
      setLoading(false);
      if (res.role_id===ROLE_SUBSCRIBER_REPORTS) {
        navigate('/location-report');
      }else if (res.role_id===ROLE_SUBSCRIBER_MAP) {
        navigate('/place-map');
      } else if (res.role_id===ROLE_SUBSCRIBER || res.role_id===ROLE_USER) {
        navigate('/profile');
      } else {
        navigate(location.state?.from?.pathname || '/');
      }
    }).catch(err => {
      setLoading(false);
      if (captcha) {
        recaptchaRef.current.reset();
      }
      if (captcha || code) {
        handleChange({...form, captcha: '', code: ''});
      }
      switch (err.message) {
        case 'wrong:auth':
          setErrors({email: true, password: true});
          toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
          break;
        case 'need:captcha':
          setCaptcha(true);
          recaptchaRef.current.reset();
          break;
        case 'wrong:captcha':
        case 'empty:captcha':
          setErrors({captcha: true});
          break;
        case 'need:tfa':
        case 'tfa:wrong_code':
        case 'tfa:timeout':
        case 'tfa:used_code':
          setCode(true);
          setErrors({...errors, code: true});
          toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
          break;
        case 'user:inactive':
          navigate('/email-verify', {email: form.email});
          break;
        default:
          toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      }
    });
  };

  return <>
    <MyAppBar/>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          <Container className={'container-narrow'}>
            {htag !== null && <Helmet key={htag}>
              <title>Login - GetPlace</title>
              <meta name="description" content="Login to GetPlace and access powerful location analytics tools. Our platform offers real-time data analysis, footfall heat maps, and location reports to help you optimize your business strategy." />
            </Helmet>}

            <Header as='h3' textAlign='center'>Log in to your account | <Link to="/register" className={'purple'}>Sign Up</Link></Header>
            <Form size='large' noValidate onSubmit={handleSubmit}>
              <Segment stacked>
                <Form.Input label={'E-mail'} fluid icon='user' iconPosition='left' placeholder='E-mail address'
                            name='email' value={form.email} onChange={handleChangeForm} error={!!errors.email}/>

                <Form.Input label={'Password'} fluid icon='lock' iconPosition='left' placeholder='Password'
                            name='password' type='password' value={form.password} onChange={handleChangeForm} error={!!errors.password}/>

                {code && <Form.Input label={'Enter two-factor authentication code'} fluid placeholder='xxxxxx' error={!!errors.code}
                                     name='code' value={form.code} onChange={handleChangeForm}/>}

                {captcha && <Form.Field>
                  <div>
                    <ReCAPTCHA sitekey={cfg.recaptcha}
                               onChange={handleCaptcha}
                               ref={recaptchaRef}
                    />
                  </div>
                  {!!errors.captcha && <Message negative>Please confirm that you are not a robot</Message>}
                </Form.Field>}

                <Button fluid size='medium' color="black">Login</Button>
              </Segment>

              <div>
                <Link to="/restore" className={'purple'}>Forgot password?</Link>
              </div>
            </Form>
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>

  </>;
}

export default Login
