import {
  UPDATE_SIGNIN, UPDATE_USER_PROFILE
} from "../constants";

const initialUserState = {
    profile: {},
    profile_updated: null
};

export default function user(state = initialUserState, action) {
    switch (action.type) {
        case UPDATE_SIGNIN:
            return {
                ...initialUserState,
                profile: action.profile,
                profile_updated: null
            };
      case UPDATE_USER_PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                  ...action.profile
                },
                profile_updated: Math.random().toString(36)
            };
        default:
            return state;
    }
}
