import React, {useState, useEffect, useCallback} from 'react';
import {Button, Form} from "semantic-ui-react";

const TfaCode = (props) => {
  const {error, handleSubmit, loading} = props;
  const [invalid, setInvalid] = useState(false);
  const [code, setCode] = useState('');

  const handleCode = useCallback((e) => {
    const value = e.target.value;
    setCode(value);
    setInvalid(value.length !== 6);
  }, []);

  const onSubmit = useCallback(() => {
    if (code.length !== 6) {
      return;
    }
    handleSubmit(code);
  }, [code, handleSubmit]);

  useEffect(() => {
    if (error) {
      setInvalid(true);
      setCode('');
    }
  }, [error]);

  return <Form size='large' noValidate>
    <Form.Input label={'Enter two-factor authentication code'} fluid placeholder='xxxxxx' error={invalid} required={true}
                name='code' value={code} onChange={handleCode}/>

    <Button fluid size='medium' color="orange" onClick={onSubmit} loading={loading} disabled={loading || !code || invalid}>Confirm</Button>
  </Form>;
};

export default TfaCode;
