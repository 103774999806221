import React, {useEffect, useState} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Container, Icon, Table, Button, Grid, Breadcrumb, Message, Dropdown} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {formatErrors} from "../../constants";
import {poisAction, poiDeleteAction} from '../../actions/poiAction';
import Paging from '../../utils/Paging/Paging';
import {ConfirmModal} from '../../utils/ConfirmModal/ConfirmModal';

const poiIndustries = [
  'Agriculture', 
  'Sports', 
  'Banking & Insurance', 
  'Healthcare', 
  'Services', 
  'Entertainment', 
  'Industrial', 
  'Utilities', 
  'Real estate', 
  'Government', 
  'Food & Beverage', 
  'Other', 
  'Retail', 
  'Short Term Rentals', 
  'Transportation', 
  'Hospitality', 
  'Freelance', 
  'Personal care', 
  'Attractions', 
  'Education'
];

const poiCategories = [
  'Accessories', 'Accommodation', 'Accountant', 'Administration', 'Advertising', 'Advisor', 'Agency', 'Agricultural machinery', 
  'Airplane', 'Airport', 'Amusement center', 'Animal hospital', 'Antique store', 'Appliance store', 'Architect', 'Archive', 'Art', 
  'Art ', 'Art gallery', 'Art school', 'Art store', 'At home nursing care', 'Atm', 'Attorney', 'Attraction', 'Auction house', 
  'Audiologist', 'Audiovisual', 'Auto body shop', 'Auto parts store', 'Bakery', 'Bank', 'Bar', 'Barber shop', 'B & B', 'Beauty salon', 
  'Bicycle rack', 'Bike sharing', 'Bike store', 'Bistro', 'Boat', 'Boat rental', 'Books', 'Book store', 'Boutique', 'Boxing', 
  'Brasserie', 'Bridge', 'Building', 'Buildings', 'Bus', 'Bus company', 'Business center', 'Business management consultant', 
  'Bus station', 'Bus stop', 'Bus ticket agency', 'Cafe', 'Cake shop', 'Camera store', 'Camping', 'Candy store', 'Car dealer', 
  'Carpentry', 'Car rental', 'Car repair', 'Car service', 'Car washing', 'Casino', 'Castle', 'Catering service', 'Charity organization', 
  'Chemical industry', 'Children', 'Chiropractor', 'Chocolate shop', 'Church', 'Cinema', 'Cityhall', 'Civil engineering', 'Cleaning', 
  'Clothing', 'Clothing children', 'Club', 'Coaching center', 'Cocktail bar', 'College', 'Community center', 'Company', 'Computer', 
  'Concrete', 'Confectionery', 'Construction', 'Construction company', 'Construction materials supplier', 'Consulate', 'Consultant', 
  'Contractor', 'Convenience store', 'Convention center', 'Corporate office', 'Cosmetics', 'Council', 'Courier service', 'Coworking', 
  'Cultural center', 'Custom house developer', 'Dance school', 'Decor accessories', 'Deli', 'Delivery', 'Delivery of meals', 'Dental center', 
  'Dentist', 'Department store', 'Depilation', 'Dermatology', 'Design agency', 'Diagnostic center', 'Discount supermarket', 'Doctor', 'Donut shop', 
  'Drilling', 'Driving school', 'Ecommerce service', 'Education', 'Educational institution', 'Education center', 'Electrician', 'Electric utility company', 
  'Electric vehicle charging station', 'Electronics', 'Embassy', 'Employment', 'Energy', 'Engineer', 'Entertainment', 'Environmental', 'Esthetication', 
  'Event', 'Events', 'Excavation company', 'Experiences', 'Fabrics company', 'Factory', 'Farm', 'Fashion', 'Fast food', 'Festival', 'Finance', 
  'Financial institution', 'Fishing', 'Fitness center', 'Floring', 'Florist', 'Food beverage', 'Food beverage supply', 'Formation center', 'Fountain', 
  'Funeral home', 'Furniture', 'Games', 'Garage', 'Gardening', 'Gas company', 'Gas station', 'Gastropub', 'General practitioner', 'Gift shop', 'Government office', 
  'Graphic designer', 'Grocery store', 'Gun shop', 'Gym', 'Hairdresser', 'Handbag store', 'Hardware store', 'Health beauty', 'Health insurance agency', 
  'Heating ventilation-air conditioning', 'High school', 'Hindu temple', 'Hobbies sport', 'Holding company', 'Holiday apartment', 'Home goods', 
  'Home improvement store', 'Home services', 'Hospital', 'Hostel', 'Hotel', 'House Flat', 'House painter', 'Housing', 'Housing development', 'Hygenic', 
  'Ice-cream Shop', 'Info point', 'Insolvency service', 'Insurance agency', 'Insurance broker', 'Insurance company', 'Interior designer', 'Internet', 
  'Internet cafe', 'Investment bank', 'Jewelry', 'Jewerly', 'Juice shop', 'Kiosk', 'Laboratory', 'Lake', 'Landmark', 'Landscape', 'Language school',
   'Laundry', 'Lawyer', 'Legal services', 'Library', 'Life insurance agency', 'Lighting store', 'Limousine service', 'Liquor store', 'Loan agency', 
   'Locksmith', 'Lodge', 'Logistics', 'Manufacturer', 'Marinas', 'Market', 'Marketing', 'Marriage', 'Martial arts', 'Massage spa', 'Materials', 'Mattress store', 
   'Mechanical field', 'Medical center', 'Medical clinic', 'Medical practitioner', 'Medical specialist', 'Medical supply', 'Metalwork', 'Military', 
   'Money transf exch service', 'Monument', 'Mortgage', 'Mosque', 'Motorcycle', 'Museum', 'Music', 'Musician', 'Music school', 'Music venue', 'Nail salon', 
   'News', 'Newsstand', 'Nightclub', 'Nightlife', 'Notary public', 'Nursery', 'Nursing', 'Nursing home', 'Nutritionist', 'Office supplies', 'Optician', 
   'Organization', 'Osteopath', 'Other', 'Outlet', 'Packaging company', 'Paper store', 'Park', 'Parking lot', 'Pastry', 'Pawn shop', 'Pediatrics', 'Perfumery',
   'Personal care', 'Personal trainer', 'Pet shop supply', 'Pharmaceutical company.', 'Pharmacy', 'Phone', 'Photography', 'Physical fitness wellness', 
   'Physical therapy clinic', 'Physiotherapist', 'Pilates', 'Pizzeria', 'Place of worship', 'Plastic fabrication', 'Playground', 'Plumber', 'Podiatrist', 
   'Police', 'Police department', 'Postal', 'Post office', 'Primary school', 'Printers', 'Private school', 'Professional', 'Professionals', 'Professional services', 
   'Property management', 'Property management company', 'Psychiatry', 'Psychology', 'Psychotherapist', 'Pub', 'Public garden', 'Public relations firm', 
   'Radio broadcaster', 'Real estate appraisal agency', 'Real estate builder', 'Recording studio', 'Rehabilitation center', 'Religion', 'Rental', 'Repair', 
   'Reproductive medicine center', 'Resort', 'Restaurant', 'Roofing', 'Room Rental', 'Safety equipment', 'Sandwich Shop', 'School', 'Sculpture', 'Security', 
   'Shipping company', 'Shoe store', 'Shopping', 'Shopping mall', 'Soccer club', 'Social services', 'Software company', 'Spa', 'Specialist ambulatory ', 
   'Specialist clinic', 'Specialization school', 'Sport', 'Sports club', 'Stationery store', 'Steak house', 'Storage', 'Student dormitory', 'Subway station', 
   'Supermarket', 'Supply', 'Surgery', 'Surveyor', 'Swimming', 'Synagogue', 'Tailor', 'Tanning salon', 'Tax', 'Taxi', 'Tax services', 'Tea house', 'Technology',
    'Telecommunications', 'Telephone', 'Temp agency', 'Theatre', 'Tire shop', 'Tobacco shop', 'Tourism', 'Tour operator', 'Trading', 'Train', 'Training centre', 
    'Train station', 'Transit area', 'Transport', 'Transportation', 'Travel', 'Treding', 'Tutoring service', 'University', 'Van rental', 'Vision care',
    'Waste management', 'Watch', 'Water utility', 'Web designer', 'Wedding venue', 'Wellness', 'Wholesaler', 'Window supplier', 'Wine bar', 'Winery', 'Wine store',
     'Women\'s clothing', 'Work', 'Yoga'
];


function Poi() {
  const [filter, setFilter] = useState(null);
  const filterName = "m_PoiFilter";
  const [industries, setIndustries] = useState([]);
  const [categories, setCategories] = useState([]);

  const handleChangeFilter = (value, name) => {
    if (name === "page") {
      setFilter({...filter, page: value});
    } else if (name === "sort") {
      setFilter({
        ...filter,
        sort: value,
        sortd: filter.sort === value ? (filter.sortd === "asc" ? "desc" : "asc") : "asc",
        page: 1
      });
    } else if (name) {
      setFilter({...filter, [name]: value, page: 1});
    } else {
      setFilter({...filter});
    }
  };

  useEffect(() => { 
    const dFilter = {page: 1, limit: 10, sort: 'created', sortd: 'desc', industry: '', category: '', search: ''};
    let curFilter = localStorage.getItem(filterName);
    curFilter = curFilter ? JSON.parse(curFilter) : dFilter;
    if (!curFilter.industry) {
      curFilter = dFilter;
    }
    setFilter(curFilter);

    let industry = [];
    poiIndustries.map((item, index) => {
      industry.push({key: index, text: item, value: item});
    });
    setIndustries(industry);

    let category = [];
    poiCategories.map((item, index) => {
      category.push({key: index, text: item, value: item});
    });
    setCategories(category);
  }, []);

  return <React.Fragment>
    <MyAppBar/>

      <Container className={'container-wide'}>
          <ButtonsTop handleChange={handleChangeFilter}/>
          {filter && <PoiList  filterName={filterName} filter={filter} industries={industries} categories={categories} handleChange={handleChangeFilter}/>}
      </Container>
  </React.Fragment>;
}

const ButtonsTop = ({handleChange}) => {
  return <Grid>
    <Grid.Row columns={2}>
      <Grid.Column>
        <Breadcrumb>
          <Breadcrumb.Section active>
           POIs
          </Breadcrumb.Section>
        </Breadcrumb>
      </Grid.Column>

      <Grid.Column textAlign='right'></Grid.Column>
    </Grid.Row>
  </Grid>;
};

function PoiList({filter, filterName, industries, categories, handleChange}) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);

  const deletePoi = (id) => {
    return new Promise((resolve, reject) => {
      if (loading) {
        return resolve(false);
      }
      setLoading(true);
      dispatch(poiDeleteAction(id)).then(() => {
        setLoading(false);
        resolve(true);
        if (Math.ceil(total / filter.limit) === filter.page && filter.page > 1) {
          handleChange(filter.page - 1, "page");
        } else {
          handleChange();
        }
      }).catch(err => {
        setLoading(false);
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
        reject(err);
      });
    })
  };

  useEffect(() => {
    if (!filter) {
      return;
    }

    dispatch(poisAction(filter)).then((data) => {
      setItems(data.items || []);
      setTotal(data.total);
      localStorage.setItem(filterName, JSON.stringify(filter));
    }).catch(err => {
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }, [dispatch, filter, filterName]);

  const handleChangeDropDown = (e, data) => {
    const name = data.name;
    let value = data.value;
    handleChange(value, name);
  };

  return <React.Fragment>

    <Grid>
      <Grid.Row columns={4}>
        <Grid.Column>
          {filter && <Dropdown clearable options={industries} search
                selection placeholder='All industries' value={filter.industry} button
                name="industry" onChange={handleChangeDropDown} />}
        </Grid.Column>
        <Grid.Column>
          {filter && <Dropdown clearable options={categories} search
                selection placeholder='All categories' value={filter.category} button
                name="category" onChange={handleChangeDropDown} />}
        </Grid.Column>
      </Grid.Row>
    </Grid>


    

    {total === 0 && <Message color="purple">No records found.</Message>}

    {total > 0 && <Table celled striped sortable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Address</Table.HeaderCell>
          <Table.HeaderCell>Lat/Lng</Table.HeaderCell>
          <Table.HeaderCell>Industry</Table.HeaderCell>
          <Table.HeaderCell>Category</Table.HeaderCell>
          <Table.HeaderCell>Sentiment</Table.HeaderCell>
          <Table.HeaderCell>Footfall</Table.HeaderCell>
          <Table.HeaderCell width={2}/>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((i) => {
          return <Table.Row key={i.id}>
            <Table.Cell>{i.name}</Table.Cell>
            <Table.Cell>{i.street_address}, {i.city}, {i.county}, {i.country}</Table.Cell>
            <Table.Cell>{i.latitude}/{i.longitude}</Table.Cell>
            <Table.Cell>{i.industry}</Table.Cell>
            <Table.Cell>{i.category}</Table.Cell>
            <Table.Cell>{i.sentiment}</Table.Cell>
            <Table.Cell>{i.footfall}</Table.Cell>
            <Table.Cell textAlign="center">
              {/*<ConfirmModal content='Do you wish to delete item?' ok={() => deletePoi(i.id)}
                            trigger={<Button size='mini' icon><Icon name='delete'/></Button>}/>*/}
            </Table.Cell>
          </Table.Row>;
        })}
      </Table.Body>
    </Table>}

    <Paging total={total} page={filter.page} limit={filter.limit} handleChange={handleChange}/>

  </React.Fragment>;
}

export default Poi
