import React, {useEffect, useState} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Button, Dropdown, Grid, Table} from "semantic-ui-react";
import {
  getBrandProductsAction,
  getBrandsAction, getCitiesAction,
  getPricePulseReportAction,
  getPricingResearchReportAction
} from "../../actions/menuAction";
import {toast} from "react-toastify";
import {formatErrors} from "../../constants";
import {useDispatch} from "react-redux";
import {latLngToCell} from "h3-js";

function PricePulse() {

  const PricePulseModeComparison = 0;
  const PricePulseModeResearch = 1;

  const [pricePulseMode, setPricePulseMode] = useState(PricePulseModeComparison);
  const [yourBrand, setYourBrand] = useState(null);
  const [otherBrand, setOtherBrand] = useState(null);
  const [yourMenuItem, setYourMenuItem] = useState(null);
  const [yourMenuItems, setYourMenuItems] = useState([]);
  const [otherMenuItem, setOtherMenuItem] = useState(null);
  const [otherMenuItems, setOtherMenuItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [compareReport, setCompareReport] = useState(null);
  const [showPointsOver, setShowPointsOver] = useState(false);

  const [brands, setBrands] = useState([]);
  const [prBrands, setPRBrands] = useState([]);
  const [sort, setSort] = useState({column: 'distance', sort: 'ascending'});

  // pr - price research
  const [prBrand, setPRBrand] = useState(null);
  const [prMenuItem, setPRMenuItem] = useState([]);
  const [prMenuItems, setPRMenuItems] = useState([]);
  const [prReport, setPRReport] = useState(null);
  const [prSort, setPRSort] = useState({column: 'price', sort: 'descending'});

  const [city, setCity] = useState("");
  const [prCity, setPRCity] = useState("");
  const [cities, setCities] = useState(null);

  const dispatch = useDispatch()

  useEffect(() => {
    if (cities === null) {
      setLoading(true);
      dispatch(getCitiesAction()).then(res => {
        if (!!res.cities) {
          setLoading(false);
          let tmp = [];
          for (let idx in res.cities) {
            tmp.push({key: idx, value: res.cities[idx], text: res.cities[idx]})
          }
          setCities(tmp);
        }
      }).catch((err) => {
        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [cities])

  useEffect(() => {
    if (!!cities && !!city) {
      setLoading(true);
      ResetVars("city");
      dispatch(getBrandsAction({city: city})).then((res) => {
        if (res?.brands) {
          let tmpBrands = [];
          let key = 1;
          for (let item of res.brands) {
            tmpBrands.push({key: key, value: item.id, text: item.name})
            key += 1
          }
          setBrands([...tmpBrands]);
        }

        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [city]);

  useEffect(() => {
    if (!!cities && !!prCity) {
      setLoading(true);
      ResetVars("prcity");
      dispatch(getBrandsAction({city: prCity})).then((res) => {
        if (res?.brands) {
          let tmpBrands = [];
          let key = 1;
          for (let item of res.brands) {
            tmpBrands.push({key: key, value: item.id, text: item.name})
            key += 1
          }
          setPRBrands([...tmpBrands]);
        }

        setLoading(false);
      }).catch((err) => {
        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [prCity]);

  // TODO:: add function
  useEffect(() => {
    if (!!yourBrand) {
      setLoading(true);
      setYourMenuItem(null);
      dispatch(getBrandProductsAction({id: yourBrand, city: city, quick: true})).then((res) => {
        if (res?.products) {

          let tmpProducts = [];
          let key = 1;
          for (let item of res.products) {
            tmpProducts.push({key: key, value: item.code, text: item.name})
            key += 1
          }
          setYourMenuItems([...tmpProducts]);
        }

        setLoading(false);
      }).catch((err) => {
        setYourMenuItems([]);
        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [yourBrand]);

  useEffect(() => {
    if (!!otherBrand) {
      setLoading(true);
      setOtherMenuItem(null);
      dispatch(getBrandProductsAction({id: otherBrand, city: city, quick: true})).then((res) => {
        if (res?.products) {

          let tmpProducts = [];
          let key = 1;
          for (let item of res.products) {
            tmpProducts.push({key: key, value: item.code, text: item.name})
            key += 1
          }
          setOtherMenuItems([...tmpProducts]);
        }

        setLoading(false);
      }).catch((err) => {
        setOtherMenuItems([]);
        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [otherBrand]);

  useEffect(() => {
    if (!!prBrand) {
      setLoading(true);
      setOtherMenuItem(null);
      dispatch(getBrandProductsAction({id: prBrand, city: prCity, quick: true})).then((res) => {
        if (res?.products) {

          let tmpProducts = [];
          let key = 1;
          for (let item of res.products) {
            tmpProducts.push({key: key, value: item.code, text: item.name})
            key += 1
          }
          setPRMenuItems([...tmpProducts]);
          setPRMenuItem([]);
        }

        setLoading(false);
      }).catch((err) => {
        setPRMenuItems([]);
        setPRMenuItem([]);
        setLoading(false);
        console.log(err)
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [prBrand]);

  useEffect(() => {
    if (!!compareReport) {
      setCompareReport(MakeSort(sort, compareReport))
    }
  }, [sort])

  useEffect(() => {
    if (!!prReport) {
      setPRReport(MakePRSort(prSort, prReport))
    }
  }, [prSort])

  function ResetVars(level) {
    switch (level) {
      case "city":
        setYourBrand(null);
        setOtherBrand(null);
        setYourMenuItem(null);
        setOtherMenuItem(null);
        setYourMenuItems([]);
        setOtherMenuItems([]);
        break;

      case "prcity":
        setPRBrand(null);
        setPRMenuItem([]);
        setPRMenuItems([]);
        break;
    }
  }

  function MakePRSort(sort, sortable) {
    let tmp = JSON.parse(JSON.stringify(sortable));

    if (sort.column === 'menu_item') {
      tmp.items.sort((a, b) => {
        if (sort.sort === 'descending') {
          return a.menu_item_name > b.menu_item_name ? -1 : 1;
        } else {
          return a.menu_item_name < b.menu_item_name ? -1 : 1;
        }
      });
    } else if (sort.column === 'price') {
      for (let idx in tmp.items) {
        tmp.items[idx].places.sort((a, b) => {
          if (sort.sort === 'descending') {
            return a.price_value > b.price_value ? -1 : 1;
          } else {
            return a.price_value < b.price_value ? -1 : 1;
          }
        });
      }
    } else if (sort.column === 'location') {
      for (let idx in tmp.items) {
        tmp.items[idx].places.sort((a, b) => {
          if (sort.sort === 'descending') {
            return a.address > b.address ? -1 : 1;
          } else {
            return a.address < b.address ? -1 : 1;
          }
        });
      }
    }

    return tmp;
  }

  function MakeSort(sort, sortable) {
    let tmp = JSON.parse(JSON.stringify(sortable));

    if (sort.column === 'distance') {
      for (let idx in tmp.points) {
        tmp.points[idx].other.sort((a, b) => {
          if (sort.sort === 'descending') {
            return a.distance > b.distance ? -1 : 1;
          } else {
            return a.distance < b.distance ? -1 : 1;
          }
        });
      }

      tmp.points_over.sort((a, b) => {
        if (sort.sort === 'descending') {
          return a.distance > b.distance ? -1 : 1;
        } else {
          return a.distance < b.distance ? -1 : 1;
        }
      });

    } else if (sort.column === 'reviews') {
      for (let idx in tmp.points) {
        tmp.points[idx].other.sort((a, b) => {
          if (sort.sort === 'descending') {
            return a.reviews > b.reviews ? -1 : 1;
          } else {
            return a.reviews < b.reviews ? -1 : 1;
          }
        });
      }

      tmp.points_over.sort((a, b) => {
        if (sort.sort === 'descending') {
          return a.reviews > b.reviews ? -1 : 1;
        } else {
          return a.reviews < b.reviews ? -1 : 1;
        }
      });
    } else if (sort.column === 'rating') {
      for (let idx in tmp.points) {
        tmp.points[idx].other.sort((a, b) => {
          if (sort.sort === 'descending') {
            return a.rating > b.rating ? -1 : 1;
          } else {
            return a.rating < b.rating ? -1 : 1;
          }
        });
      }

      tmp.points_over.sort((a, b) => {
        if (sort.sort === 'descending') {
          return a.rating > b.rating ? -1 : 1;
        } else {
          return a.rating < b.rating ? -1 : 1;
        }
      });
    } else if (sort.column === 'price') {
      for (let idx in tmp.points) {
        tmp.points[idx].other.sort((a, b) => {
          if (sort.sort === 'descending') {
            return a.menu_item_price > b.menu_item_price ? -1 : 1;
          } else {
            return a.menu_item_price < b.menu_item_price ? -1 : 1;
          }
        });
      }

      tmp.points_over.sort((a, b) => {
        if (sort.sort === 'descending') {
          return a.menu_item_price > b.menu_item_price ? -1 : 1;
        } else {
          return a.menu_item_price < b.menu_item_price ? -1 : 1;
        }
      });
    }

    return tmp;
  }

  function getReport() {
    setLoading(true)
    setShowPointsOver(false);
    dispatch(getPricePulseReportAction({
      brand: yourBrand,
      brand_other: otherBrand,
      item: yourMenuItem,
      item_other: otherMenuItem,
      city: city
    })).then(res => {
      setLoading(false);
      setCompareReport(res.report);
    }).catch((err) => {
      setLoading(false);
      console.log(err)
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  function getPricingResearchReport() {
    setLoading(true)
    let codes = [];
    for (let itm of prMenuItem) {
      codes.push(itm.value);
    }
    dispatch(getPricingResearchReportAction({brand: prBrand, codes: codes, city: prCity})).then(res => {
      setLoading(false);
      setPRReport(res.report);
    }).catch((err) => {
      setLoading(false);
      console.log(err)
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  function GetPriceDiff(priceYour, priceOther, addPlus) {
    return priceYour > priceOther ? "-£" + ((priceYour - priceOther) / 100) : ((!!addPlus ? "+" : "") + "£" + ((priceOther - priceYour) / 100));
  }

  function GetPercentsCompareP1ofP2(price1, price2) {
    return price2 > 0 ? (Math.round(((price1 - price2) / price2) * 100 * 100) / 100 + "%") : "0";
  }

  function GetReportLink(lat, lng) {
    return "/location-report?hex=" + latLngToCell(lat, lng, 10)
  }

  function GetMapLink(lat, lng) {
    return "/place-map?lat=" + lat + "&lng=" + lng
  }

  function handlePRItemSelect(value) {
    let prItem = prMenuItems.find(itm => itm.value === value);
    setPRMenuItem([...prMenuItem, prItem]);
    let tmp = prMenuItems;
    tmp[prItem.key - 1].disabled = true;
    setPRMenuItems([...tmp]);
  }

  function handlePRItemDelete(idx) {
    let prItem = prMenuItems.find(itm => itm.value === prMenuItem[idx].value);
    let tmp = prMenuItems;
    tmp[prItem.key - 1].disabled = false;
    setPRMenuItems([...tmp]);

    tmp = prMenuItem;
    tmp.splice(idx, 1);
    setPRMenuItem([...tmp]);
  }


  function ChangeSort(column, mode) {
    let tmp = mode === PricePulseModeComparison ? {...sort} : {...prSort};

    if (tmp.column === column) {
      tmp.sort = tmp.sort === 'ascending' ? 'descending' : 'ascending';
    } else {
      tmp.column = column;
      tmp.sort = 'ascending';
    }

    if (mode === PricePulseModeComparison) {
      setSort({...tmp});
    } else {
      setPRSort({...tmp})
    }
  }

  return <React.Fragment>
    <div className={"loader-screen"} hidden={!loading}>
      <div>Loading ...</div>
    </div>

    <MyAppBar/><br/>

    <div className={"price-pulse-container"}>
      <div className={"price-pulse-header"}>
        <div className={pricePulseMode === PricePulseModeComparison ? "active" : ""} onClick={() => {
          setPricePulseMode(PricePulseModeComparison)
        }}>
          <div className={"price-pulse-ico price-pulse-ico-compare"}>Prices Comparison</div>
          <p className={"price-pulse-t-descr"}>Compare your prices to competitors</p>
        </div>
        <div className={pricePulseMode === PricePulseModeResearch ? "active" : ""} onClick={() => {
          setPricePulseMode(PricePulseModeResearch)
        }}>
          <div className={"price-pulse-ico price-pulse-ico-research"}>Pricing Research</div>
          <p className={"price-pulse-t-descr"}>Research the prices of a particular restaurant in detail</p>
        </div>
      </div>
      {pricePulseMode === PricePulseModeComparison && <React.Fragment>
        <div className={"price-pulse-menu"}>
          <Grid columns='equal'>
            <Grid.Row>
              <Grid.Column>
                <label>Your chain</label>
                <Dropdown search selection options={brands} value={yourBrand}
                          disabled={brands.length === 0 || loading}
                          onChange={(e, v) => {
                            setYourBrand(v.value)
                          }}
                          placeholder={"Type or select"}
                />
              </Grid.Column>
              <Grid.Column>
                <label>Your menu item</label>
                <Dropdown search selection options={yourMenuItems} value={yourMenuItem}
                          placeholder={"Type or select"}
                          onChange={(e, v) => {
                            setYourMenuItem(v.value)
                          }}
                          disabled={yourMenuItems.length === 0 || loading}
                />
              </Grid.Column>
              <Grid.Column>
                <label>Chain of interest</label>
                <Dropdown search selection options={brands} value={otherBrand}
                          disabled={brands.length === 0 || loading}
                          onChange={(e, v) => {
                            setOtherBrand(v.value)
                          }}
                          placeholder={"Type or select"}
                />
              </Grid.Column>
              <Grid.Column>
                <label>It's menu item</label>
                <Dropdown search selection options={otherMenuItems} value={otherMenuItem}
                          onChange={(e, v) => {
                            setOtherMenuItem(v.value)
                          }}
                          placeholder={"Type or select"}
                          disabled={otherMenuItems.length === 0 || loading}
                />
              </Grid.Column>
              <Grid.Column>
                <label>City</label>
                {!!cities && <Dropdown search selection options={cities} value={city} disabled={loading}
                                       onChange={(e, v) => {
                                         setCity(v.value)
                                       }}/>}
              </Grid.Column>
              <Grid.Column>
                <label>&nbsp;</label>
                {!!yourBrand && !!otherBrand && !!yourMenuItem && !!otherMenuItem &&
                  <a className={"price-pulse-refresh-button"} onClick={() => {
                    getReport();
                  }}>
                    <i className={"price-pulse-refresh"}/>
                  </a>}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <br/><br/>
        {!compareReport &&
          <div className={"price-pulse-no-report"}>Input the parameters above to compare the prices</div>}
        {!!compareReport && <div style={{padding: "0 30px"}}><Grid>
          <Grid.Row>
            <Grid.Column>
              <div className={"price-pulse-overall"}>
                <div>
                  <p><b>{compareReport.menu_item_name}</b></p>
                  <p>Median price&nbsp;&nbsp;&nbsp;<b>£{compareReport.median_price / 100}</b></p>
                </div>
                <div>
                  <p><b>{compareReport.menu_item_name_other}</b></p>
                  <p>Median price&nbsp;&nbsp;&nbsp;<b>£{compareReport.median_price_other / 100}</b>
                  </p>
                </div>
                <div>
                  <p><b>Median price margin</b></p>
                  <p className={"price-pulse-margin"}>
                    <span><b>{GetPriceDiff(compareReport.median_price, compareReport.median_price_other)}</b></span>
                    <span>{GetPercentsCompareP1ofP2(compareReport.median_price_other, compareReport.median_price)}</span>
                  </p>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Column>
            <Grid.Row>
              &nbsp;
            </Grid.Row>
          </Grid.Column>
          <Grid.Column>
            <Grid.Row>
              &nbsp;
            </Grid.Row>
          </Grid.Column>
          {compareReport.points.map((item, idx) => {
            return <React.Fragment key={idx}>
              <Grid.Row>
                <Grid.Column>
                  <Table basic={'very'}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          Location
                        </Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.HeaderCell>
                          <Sortable
                            text={"Distance (miles)"}
                            onClick={() => {
                              ChangeSort('distance', pricePulseMode)
                            }}
                            sort={sort.column === "distance" ? sort.sort : null}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Sortable
                            text={"Rating"}
                            onClick={() => {
                              ChangeSort('rating', pricePulseMode)
                            }}
                            sort={sort.column === "rating" ? sort.sort : null}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Sortable
                            text={"Reviews"}
                            onClick={() => {
                              ChangeSort('reviews', pricePulseMode)
                            }}
                            sort={sort.column === "reviews" ? sort.sort : null}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell>Menu item</Table.HeaderCell>
                        <Table.HeaderCell>
                          <Sortable
                            text={"Price"}
                            onClick={() => {
                              ChangeSort('price', pricePulseMode)
                            }}
                            sort={sort.column === "price" ? sort.sort : null}
                          />
                        </Table.HeaderCell>
                        <Table.HeaderCell>Margin</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row className={"colored"}>
                        <Table.Cell><b>{item.name}</b><br/><b>{item.address}</b></Table.Cell>
                        <Table.Cell>
                          {!!item.latitude && item.longitude && <a className={"pp-link"}
                                                                   href={GetMapLink(item.latitude, item.longitude)}
                                                                   target={"_blank"}><i
                            className={"price-pulse-mark price-pulse-map-mark"}/></a>}
                          {!!item.latitude && item.longitude && <a className={"pp-link"}
                                                                   href={GetReportLink(item.latitude, item.longitude)}
                                                                   target={"_blank"}><i
                            className={"price-pulse-mark price-pulse-report-mark"}/></a>}
                        </Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell></Table.Cell>
                        <Table.Cell><b>{compareReport.menu_item_name}</b></Table.Cell>
                        <Table.Cell>£{item.menu_item_price / 100}</Table.Cell>
                        <Table.Cell></Table.Cell>
                      </Table.Row>
                      {item.other.map((item2, idx2) => {
                        return <React.Fragment key={idx2}>
                          <Table.Row>
                            <Table.Cell><b>{item2.name}</b><br/><b>{item2.address}</b></Table.Cell>
                            <Table.Cell>
                              {!!item2.latitude && item2.longitude &&
                                <a className={"pp-link"}
                                   href={GetMapLink(item2.latitude, item2.longitude)}
                                   target={"_blank"}><i
                                  className={"price-pulse-mark price-pulse-map-mark"}/></a>}
                              {!!item2.latitude && item2.longitude &&
                                <a className={"pp-link"}
                                   href={GetReportLink(item2.latitude, item2.longitude)}
                                   target={"_blank"}><i
                                  className={"price-pulse-mark price-pulse-report-mark"}/></a>}
                            </Table.Cell>
                            <Table.Cell>{Math.round((item2.distance / 1609.34) * 100) / 100}</Table.Cell>
                            <Table.Cell>{item2.rating === -1 ? "-" : item2.rating}/5</Table.Cell>
                            <Table.Cell>{item2.reviews > -1 ? (item2.reviews === 500 ? "500+" : item2.reviews) : "-"}</Table.Cell>
                            <Table.Cell><b>{compareReport.menu_item_name_other}</b></Table.Cell>
                            <Table.Cell>£{item2.menu_item_price / 100}</Table.Cell>
                            <Table.Cell>{GetPriceDiff(item.menu_item_price, item2.menu_item_price)}&nbsp;&nbsp;&nbsp;&nbsp;
                              {Math.round(((item2.menu_item_price - item.menu_item_price) / item.menu_item_price) * 100 * 100) / 100}%</Table.Cell>
                          </Table.Row>
                        </React.Fragment>
                      })}
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  &nbsp;
                </Grid.Column>
              </Grid.Row>
            </React.Fragment>
          })}
          {!!compareReport && compareReport.points_over.length > 0 && !showPointsOver && <React.Fragment>
            <Grid.Row>
              <Grid.Column>
                <div className={"price-pulse-show-points"} onClick={() => {
                  setShowPointsOver(true);
                }}>
                  Show information on {compareReport.place}’s locations that are more than 2 miles
                  away
                </div>
              </Grid.Column>
            </Grid.Row>
            <br />
          </React.Fragment>}
          {!!compareReport && compareReport.points_over.length > 0 && showPointsOver && <React.Fragment>
            <Grid.Row>
              <Grid.Column>
                <Table basic={'very'}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Location</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell>
                        <Sortable
                          text={"Distance(miles)"}
                          onClick={() => {
                            ChangeSort('distance', pricePulseMode)
                          }}
                          sort={sort.column === "distance" ? sort.sort : null}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Sortable
                          text={"Rating"}
                          onClick={() => {
                            ChangeSort('rating', pricePulseMode)
                          }}
                          sort={sort.column === "rating" ? sort.sort : null}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Sortable
                          text={"Reviews"}
                          onClick={() => {
                            ChangeSort('reviews', pricePulseMode)
                          }}
                          sort={sort.column === "reviews" ? sort.sort : null}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>Menu item</Table.HeaderCell>
                      <Table.HeaderCell>
                        <Sortable
                          text={"Price"}
                          onClick={() => {
                            ChangeSort('price', pricePulseMode)
                          }}
                          sort={sort.column === "price" ? sort.sort : null}
                        />
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {compareReport.points_over.map((item, idx) => {
                      return <React.Fragment key={idx}>
                        <Table.Row className={idx === 0 ? "colored" : ""}>
                          <Table.Cell><b>{item.name}</b><br/><b>{item.address}</b></Table.Cell>
                          <Table.Cell>
                            {!!item.latitude && item.longitude && <a className={"pp-link"}
                                                                     href={GetMapLink(item.latitude, item.longitude)}
                                                                     target={"_blank"}><i
                              className={"price-pulse-mark price-pulse-map-mark"}/></a>}
                            {!!item.latitude && item.longitude && <a className={"pp-link"}
                                                                     href={GetReportLink(item.latitude, item.longitude)}
                                                                     target={"_blank"}><i
                              className={"price-pulse-mark price-pulse-report-mark"}/></a>}
                          </Table.Cell>
                          <Table.Cell>{Math.round((item.distance / 1609.34) * 100) / 100}</Table.Cell>
                          <Table.Cell>{item.rating === -1 ? "-" : item.rating}/5</Table.Cell>
                          <Table.Cell>{item.reviews > -1 ? (item.reviews === 500 ? "500+" : item.reviews) : "-"}</Table.Cell>
                          <Table.Cell><b>{compareReport.menu_item_name_other}</b></Table.Cell>
                          <Table.Cell>£{item.menu_item_price / 100}</Table.Cell>
                        </Table.Row>
                      </React.Fragment>
                    })}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </React.Fragment>}
        </Grid><br /></div>}

      </React.Fragment>}
      {pricePulseMode === PricePulseModeResearch && <React.Fragment>
        <div className={"price-pulse-menu"}>
          <Grid columns='equal'>
            <Grid.Row>
              <Grid.Column>
                <label>Chain of interest</label>
                <Dropdown search selection options={prBrands} value={prBrand}
                          disabled={prBrands.length === 0 || loading}
                          onChange={(e, v) => {
                            setPRBrand(v.value)
                          }}
                          placeholder={"Type or select"}
                />
              </Grid.Column>
              <Grid.Column>
                <label>Menu items</label>
                <Dropdown search selection options={prMenuItems} value={-1}
                          placeholder={"Type or select"}
                          onChange={(e, v) => {
                            handlePRItemSelect(v.value);
                          }}
                          disabled={prMenuItems.length === 0 || loading}
                          key={prMenuItems.key}
                />
              </Grid.Column>
              <Grid.Column>
                <label>City</label>
                {cities && <Dropdown search selection options={cities} value={prCity} disabled={loading}
                                     onChange={(e, v) => {
                                       setPRCity(v.value)
                                     }}/>}
              </Grid.Column>
              <Grid.Column>
                <label>&nbsp;</label>
                {!!brands && !!prMenuItem && prMenuItem.length > 0 &&
                  <a className={"price-pulse-refresh-button"} onClick={() => {
                    getPricingResearchReport();
                  }}>
                    <i className={"price-pulse-refresh"}/>
                  </a>}
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {prMenuItem.length > 0 && <Grid>
            <Grid.Row>
              <Grid.Column>
                <label>Chosen menu items</label>
                <div className={"pp-selected-items"}>
                  {prMenuItem.map((item, idx) => {
                    return <div key={idx}>{item.text}<i onClick={() => {
                      handlePRItemDelete(idx);
                    }}>&#x2715;</i></div>;
                  })}
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>}
        </div>

        <br/><br/>
        {!prReport &&
          <div className={"price-pulse-no-report"}>Customize the research parameters to get a result</div>}
        {!!prReport && <div style={{padding: "0 30px"}}>
          <Grid>
            {prReport.items.map((item, idx) => {
              return <React.Fragment key={idx}>
                <Grid.Row>
                  <Grid.Column className={"six wide column"}>
                    <Table className={"ps-info-table"} basic={"very"}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>
                            <Sortable
                              text={"Menu item"}
                              onClick={() => {
                                ChangeSort('menu_item', PricePulseModeResearch)
                              }}
                              sort={prSort.column === "menu_item" ? prSort.sort : null}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell>Pricing summary</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell><h3>{item.menu_item_name}</h3></Table.Cell>
                          <Table.Cell>
                            <p className={"ps-margin"}>Median: &nbsp;&nbsp;
                              <b>£{item.median / 100}</b></p>
                            <p className={"ps-margin"}>Min: &nbsp;&nbsp;<b
                              className={"ps-min"}>£{item.min / 100}</b></p>
                            <p className={"ps-margin"}>Max: &nbsp;&nbsp;<b
                              className={"ps-max"}>£{item.max / 100}</b></p>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                  <Grid.Column className={"ten wide column"}>
                    <Table className={"ps-data-table"} basic={"very"}>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell className={"ps-10"}>
                            <Sortable
                              text={"Price"}
                              onClick={() => {
                                ChangeSort('price', PricePulseModeResearch)
                              }}
                              sort={prSort.column === "price" ? prSort.sort : null}
                            />
                          </Table.HeaderCell>
                          <Table.HeaderCell className={"ps-15"}>Margin</Table.HeaderCell>
                          <Table.HeaderCell>
                            <Sortable
                              text={"Location"}
                              onClick={() => {
                                ChangeSort('location', PricePulseModeResearch)
                              }}
                              sort={prSort.column === "location" ? prSort.sort : null}
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell colSpan={3}>
                            <div style={{
                              maxHeight: "300px",
                              width: "100%",
                              overflowY: "scroll"
                            }}>
                              <Table basic={"very"} className={"ps-inner-table"} striped>
                                <Table.Body>
                                  {item.places.map((place, placeIdx) => {
                                    return <React.Fragment key={placeIdx}>
                                      <Table.Row>
                                        <Table.Cell
                                          className={"ps-10" + (item.max === place.price_value ? " ps-max " : "") + (item.min === place.price_value ? " ps-min " : "")}><b>£{place.price_value / 100}</b></Table.Cell>
                                        <Table.Cell className={"ps-15"}>
                                          <div className={"ps-margin"}>
                                            <span><b>{GetPriceDiff(item.median, place.price_value, true)}</b></span>
                                            <span>{GetPercentsCompareP1ofP2(place.price_value, item.median)}</span>
                                          </div>
                                        </Table.Cell>
                                        <Table.Cell><b>{place.name}</b><br/><b>{place.address}</b></Table.Cell>
                                        <Table.Cell>
                                          {!!place.latitude && place.longitude &&
                                            <a className={"pp-link"}
                                               href={GetMapLink(place.latitude, place.longitude)}
                                               target={"_blank"}><i
                                              className={"price-pulse-mark price-pulse-map-mark"}/></a>}
                                          {!!place.latitude && place.longitude &&
                                            <a className={"pp-link"}
                                               href={GetReportLink(place.latitude, place.longitude)}
                                               target={"_blank"}><i
                                              className={"price-pulse-mark price-pulse-report-mark"}/></a>}
                                        </Table.Cell>
                                      </Table.Row>
                                    </React.Fragment>;
                                  })}
                                </Table.Body>
                              </Table>
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>;
            })}
          </Grid>
          <br/>
        </div>}

      </React.Fragment>}
    </div>
  </React.Fragment>;
}

export function Sortable({text, sort, onClick}) {
  return <React.Fragment>
    <div
      className={"pp-sortable" + (["ascending", "descending"].indexOf(sort) > -1 ? " pp-sortable-on " : " pp-sortable-off ") + (sort === "ascending" ? " asc " : "")}
      onClick={onClick}>{text}</div>
  </React.Fragment>;
}

export default PricePulse
