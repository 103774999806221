import React, {useState, useCallback} from 'react';
import {Button, Grid, Header, List, Image, Label, Message} from "semantic-ui-react";
import {changeTwofaAction, generateTwofaSecretAction} from "../../actions/profileAction";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import {formatErrors} from "../../constants";
import TfaCode from "../../utils/TfaCode/TfaCode";

const TwofaTab = (props) => {
  const {onUpdate, updateTwofa, twofa, generateTwofaSecret} = props;
  const [error, setError] = useState(false);
  const [secret, setSecret] = useState(null);
  const [mode, setMode] = useState('');
  const [loading, setLoading] = useState(false);

  const handleTwofaSecret = useCallback((mode, code) => {
    if (loading) {
      return;
    }
    setLoading(true);
    setError(false);
    generateTwofaSecret(code).then((res) => {
      setMode(mode);
      setLoading(false);
      setSecret({
        secret: res.twofaSecret,
        qr: 'https://chart.googleapis.com/chart?chs=200x200&chld=L|0&cht=qr&chl=' + encodeURIComponent(res.qrcode)
      });
    }).catch(err => {
      setLoading(false);
      switch (err.message) {
        case 'need:tfa':
          break;
        case 'tfa:wrong_code':
        case 'tfa:timeout':
        case 'tfa:used_code':
          setError(true);
          break;
        default:
          break;
      }

      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });

  }, [loading, generateTwofaSecret]);

  const handleTwofa = useCallback((mode, code) => {
    if (loading) {
      return;
    }
    setLoading(true);
    setError(false);
    const value = mode === 'disable' ? false : true;
    updateTwofa({twofa: value, code: code || ''}).then(() => {
      setMode('');
      setLoading(false);
      setSecret(null);
      onUpdate(value);
      toast.success("Two-factor authentication settings updated!", {position: toast.POSITION.BOTTOM_RIGHT});
    }).catch(err => {
      setLoading(false);
      switch (err.message) {
        case 'secret:not_found':
          setMode('');
          break;
        case 'need:tfa':
        case 'tfa:wrong_code':
        case 'tfa:timeout':
        case 'tfa:used_code':
          setError(true);
          break;
        default:
          break;
      }
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });

  }, [loading, updateTwofa, onUpdate])

  const handleMode = useCallback((value) => {
    if (value === 'enable') {
      handleTwofaSecret(value);
    } else if (value !== '') {
      setSecret(null);
      setMode(value);
    } else {
      setSecret(null);
      setMode(value);
    }
  }, [handleTwofaSecret]);

  return <React.Fragment>
    {
      mode === '' && <React.Fragment>
        <Header as={'h3'}>Two-factor authentication <Label color={twofa ? 'green' : 'red'}>{twofa ? 'Enabled' : 'Disabled'}</Label></Header>

        {twofa && !mode && <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Button fluid size='medium' onClick={() => handleMode('secret')} disabled={loading}>Re-generate secret code</Button>
            </Grid.Column>
            <Grid.Column>
              <Button fluid size='medium' negative onClick={() => handleMode('disable')} disabled={loading}>Disable</Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>}

        {!twofa && !mode && <Button fluid size='medium' positive onClick={() => handleMode('enable')} disabled={loading} loading={loading}>Enable</Button>}
      </React.Fragment>
    }


    {mode !== 'disable' && secret && <React.Fragment>

      <Header as={'h3'}>{mode === 'enable' ? 'Enabling' : 'Changing'} two-factor authentication <Button size='mini' basic color="black" floated="right" onClick={() => handleMode('')}>Back</Button></Header>

      <Header as={'h4'}>Please follow the instructions to continue</Header>
      <List ordered>
        <List.Item>Download and install a two-factor authenticator app if you don't already have one.</List.Item>
        <List.Item>
          <p>Scan the QR code with the authenticator.</p>
          <p><Image src={secret.qr} size='small' bordered /></p>

          <TfaCode loading={loading} error={error} handleSubmit={(value) => handleTwofa(mode, value)} />
        </List.Item>
      </List>

      <Message size={'tiny'}>
        <Message.Header as={'h5'}>Security secret code: {secret.secret}</Message.Header>
        <Message.List>
          <Message.Item>Write down or print a copy of your 16-digit secret code and keep it somewhere safe</Message.Item>
          <Message.Item>If your phone is lost or stolen, you will need this code to link your new 2FA app.</Message.Item>
          <Message.Item>Don't share your code with anyone.<br />Beware of phishing scams.<br />We will never ask you to disclose this key to us.</Message.Item>
        </Message.List>
      </Message>


    </React.Fragment>}

    {mode === 'secret' && !secret && <React.Fragment>
      <Header as={'h3'}>Changing two-factor authentication <Button size='mini' basic color="black" floated="right" onClick={() => handleMode('')}>Back</Button></Header>
      <TfaCode loading={loading} error={error} handleSubmit={(value) => handleTwofaSecret(mode, value)} />
    </React.Fragment>}

    {mode === 'disable' && <React.Fragment>
      <Header as={'h3'}>Disabling two-factor authentication <Button size='mini' basic color="black" floated="right" onClick={() => handleMode('')}>Back</Button></Header>
      <TfaCode loading={loading} error={error} handleSubmit={(value) => handleTwofa(mode, value)} />
    </React.Fragment>}



  </React.Fragment>;
}

const mapStateToProps = (state) => ({
  lang: state.common.lang,
});

const mapDispatchToProps = (dispatch) => {
  return {
    updateTwofa(data) {
      return dispatch(changeTwofaAction(data));
    },
    generateTwofaSecret(code) {
      return dispatch(generateTwofaSecretAction(code));
    }
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(TwofaTab)
