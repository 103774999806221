export const UPDATE_COMPLETE = 'UPDATE_COMPLETE';
export const UPDATE_ERROR = 'UPDATE_ERROR';

// signin
export const UPDATE_SIGNIN = 'UPDATE_SIGNIN';

// users
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

// common
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_LANGUAGES = 'UPDATE_LANGUAGES';
export const UPDATE_COMMON_ERROR = 'UPDATE_COMMON_ERROR';

// countries
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES';


export const UPDATE_KITCHENS = 'UPDATE_KITCHENS';
export const UPDATE_DISHES = 'UPDATE_DISHES';

export const OI_FULL_ACCESS = 1
export const OI_UNLIMITED_REPORTS = 2
export const OI_NO_PLAN = 3
export const OI_FULL_ACCESS_MONTHLY = 4
export const OI_FULL_ACCESS_YEARLY = 5

const cfg = {
  path: '',
  base_url: '',
  public_url: '',
  recaptcha: '6LeeNf4UAAAAAHKEdQuO9SkxE5exvC0_IAtM6_ea'
};
export default cfg;

export function handleResponse(response) {
  return new Promise((resolve, reject) => {
    // console.log(response);
    if (response.status === 200) {
      return resolve(response.json());
    } else if (response.status === 401) {
      window.location = '/login';
      return reject(new Error('Unauthorized'));
    }
    response.text().then((txt) => {
      if (txt.trim()) {
        return reject(new Error(txt.trim()));
      }
      return reject(formatStatusError(response.status));
    });
  });
}

export function handleResponseText(response) {
  return new Promise((resolve, reject) => {
    if (response.status === 200) {
      return resolve(response.text());
    } else if (response.status === 401) {
      window.location = '/login';
      return reject(new Error('Unauthorized'));
    }
    response.text().then((txt) => {
      if (txt.trim()) {
        return reject(new Error(txt.trim()));
      }
      return reject(formatStatusError(response.status));
    });
  });
}

export function formatStatusError(status) {
  switch (status) {
    case 403:
      return new Error('Access denied!');
    case 404:
      return new Error('Request URI is not found!');
    default:
      return new Error('Something goes wrong! Please try again.');
  }
}

// error formatting function
export function formatErrors(errors) {
  let err = '';
  for (const i of errors) {
    switch (i) {
      case 'error_other':
        err += 'Something goes wrong! Please try again.';
        break;
      case 'used:email':
        err += 'The email address is already used by another user';
        break;
      case 'empty:name':
        err += 'Please specify name';
        break;
      case 'invalid:form':
        err += 'The form is invalid! Please, check the fields and try again.';
        break;
      case 'wrong:auth':
        err += 'Wrong e-mail or password!';
        break;
      case 'empty:password':
        err += 'Empty password.';
        break;
      case 'wrong:user':
        err += 'Your session expired';
        break;
      case 'limit:not_email_confirm':
        err += 'Your e-mail is not confirmed';
        break;
      case 'wrong:email':
        err += 'E-mail not found';
        break;
      case 'tfa:wrong_code':
        err += 'That code was invalid. Please try again.';
        break;
      case 'tfa:used_code':
        err += 'A security token can be used only once. ' +
          'The code you entered has already been used, please wait until the code changes and try again. ' +
          'It usually takes about 30 seconds.';
        break;
      case 'tfa:timeout':
        err += 'You have used up all your attempts, please try again in 5 minutes.';
        break;
      case 'need:tfa':
        err += 'Please enter two-factor authentication code';
        break;
      case 'secret:not_found':
        err += 'Something goes wrong! Please try again.';
        break;
      case 'wrong:sort':
        err += 'Fields sorting error. Fields must go in a row, this is important to start training.';
        break
      case 'wrong:invite_code':
        err += 'The invite code not found';
        break;
      case 'wrong:old_password':
        err += 'Wrong current password';
        break;
      case 'need:captcha':
      case 'empty:captcha':
      case 'wrong:captcha':
        err += 'Please confirm that you are not a robot';
        break;
      default:
        err += i;
        break;
    }
  }
  return err
}

export const validateEmail = (value) => {
  const email_filter = /^[\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/;
  return email_filter.test(value);
};

export const limits = [
  {key: 10, text: '10', value: 10},
  {key: 25, text: '25', value: 25},
  {key: 50, text: '50', value: 50},
  {key: 100, text: '100', value: 100}
];

// user roles
export const ROLE_USER = 0;
export const ROLE_OWNER = 1;
export const ROLE_MANAGER = 2;
//TODO::sync roles with backend

export const ROLE_SUBSCRIBER = 4;
export const ROLE_SUBSCRIBER_MAP = 5;
export const ROLE_SUBSCRIBER_REPORTS = 6;
export const ROLE_ADMIN = 10;

export const roleNames = ['User without access', 'Manager', 'Administrator'];

export const LIMIT_FREE_SERVICE_USE = 1;

export const roles = [
  {key: -1, text: "Role", value: -1},
  {key: ROLE_USER, text: 'User without access', value: ROLE_USER},
  {key: ROLE_MANAGER, text: 'Manager', value: ROLE_MANAGER, disabled: true},
  {key: ROLE_SUBSCRIBER, text: 'Subscriber - all options', value: ROLE_SUBSCRIBER},
  {key: ROLE_SUBSCRIBER_MAP, text: 'Subscriber - map only', value: ROLE_SUBSCRIBER_MAP, disabled: true},
  {key: ROLE_SUBSCRIBER_REPORTS, text: 'Subscriber - reports only', value: ROLE_SUBSCRIBER_REPORTS, disabled: true},
  {key: ROLE_ADMIN, text: 'Administrator', value: ROLE_ADMIN},
];

export const getRolesNames = (function () {
  let _roles = {};
  for (let item of roles) {
    _roles[item.value] = item.text;
  }
  return _roles
})()

// user statuses
export const user_statuses = [
  {key: -1, text: "Status", value: -1},
  {key: 0, text: 'Inactive', value: 0},
  {key: 1, text: 'Active', value: 1},
];

export const business_types = [
  {key: 1, text: 'Restaurant', value: 1},
  {key: 2, text: 'Cafe', value: 2},
  {key: 3, text: 'Bar', value: 3},
  {key: 4, text: 'Hotel', value: 4},
  {key: 5, text: 'Laundry', value: 5},
  {key: 6, text: 'Other', value: 6},
];

export const population_dimensions = [

];

export function getTimes() {
  let times = [];
  for (let i = 0; i < 24; i++) {
    times.push({key: i, value: (i < 10 ? '0' : '') + i + ':00', text: (i < 10 ? '0' : '') + i + ':00'});
  }
  return times;
}

export function getBusinessCategories() {
  let categories_json = localStorage.getItem('place_categories');
  let categories_list = JSON.parse(categories_json?categories_json:'')

  categories_list.sort((a, b) => {return a.text > b.text ?1:-1;});

  let business_types_all = [];

  for(let i = 0; i < categories_list.length; i++) {

    business_types_all.push({
      key: i,
      text: categories_list[i].text,
      value: categories_list[i].value,
    });
  }

  return business_types_all;
}

export function AccordionTabs() {
  this.toggle = function(elem, elems, saveHandler) {
    if (elems.indexOf(elem) >= 0) {
      elems.splice(elems.indexOf(elem), 1)
    } else {
      elems.push(elem);
    }

    saveHandler([...elems])
  }
  this.g = function(elem, elems) {
    return elems.indexOf(elem) >= 0;
  }
}

export const EmptyUUID = "00000000-0000-0000-0000-000000000000";

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function checkCookie(key) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
}
export const trafficGradient = [
   'gray',
   '#c6d08a',
   '#E0FF00',
   '#FFB500',
   '#FF8800',
   '#FF6A00',
   '#FF4A00',
   '#FF2500',
   '#FF0900',
   '#930410',
   '#77020b',
 ];

export  const monthsShort = {
  1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
};

export function CalcDiff(price1, price2) {
  return price2 > 0 ?(Math.round(((price1 - price2)/price2)*100*100)/100): 0;
}

export function BeautyNumber(num) {

  num = ""+num;

  let nums = num.split(".");

  let res = "";
  for (let i =  nums[0].length-1; i >= 0; i--) {
    if ( nums[0].length-1 - i !== 0 && (nums[0].length-1 - i) % 3 === 0) {
      res = ","+res;
    }
    res = nums[0][i] + res;
  }

  return res + (nums.length>1?"."+nums[1]:"");
}
