import React, {useEffect, useState} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Container, Icon, Table, Button, Grid, Breadcrumb, Message} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {formatErrors} from "../../constants";
import {populationsAction, populationDeleteAction} from '../../actions/populationAction';
import Paging from '../../utils/Paging/Paging';
import {ConfirmModal} from '../../utils/ConfirmModal/ConfirmModal';


function Population() {
  const [filter, setFilter] = useState(null);
  const filterName = "m_PopulationFilter";

  const handleChangeFilter = (value, name) => {
    if (name === "page") {
      setFilter({...filter, page: value});
    } else if (name === "sort") {
      setFilter({
        ...filter,
        sort: value,
        sortd: filter.sort === value ? (filter.sortd === "asc" ? "desc" : "asc") : "asc",
        page: 1
      });
    } else if (name) {
      setFilter({...filter, [name]: value, page: 1});
    } else {
      setFilter({...filter});
    }
  };

  useEffect(() => { 
    const dFilter = {page: 1, limit: 10, sort: 'created', sortd: 'desc'};
    let curFilter = localStorage.getItem(filterName);
    curFilter = curFilter ? JSON.parse(curFilter) : dFilter;

    setFilter(curFilter);
  }, []);

  return <React.Fragment>
    <MyAppBar/>

      <Container className={'container-wide'}>
          <ButtonsTop handleChange={handleChangeFilter}/>
          {filter && <PopulationList  filterName={filterName} filter={filter} handleChange={handleChangeFilter}/>}
      </Container>
  </React.Fragment>;
}

const ButtonsTop = ({handleChange}) => {
  return <Grid>
    <Grid.Row columns={2}>
      <Grid.Column>
        <Breadcrumb>
          <Breadcrumb.Section active>
            Population data
          </Breadcrumb.Section>
        </Breadcrumb>
      </Grid.Column>

      <Grid.Column textAlign='right'></Grid.Column>
    </Grid.Row>
  </Grid>;
};

function PopulationList({filter, filterName, handleChange}) {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);

  const deletePopulation = (id) => {
    return new Promise((resolve, reject) => {
      if (loading) {
        return resolve(false);
      }
      setLoading(true);
      dispatch(populationDeleteAction(id)).then(() => {
        setLoading(false);
        resolve(true);
        if (Math.ceil(total / filter.limit) === filter.page && filter.page > 1) {
          handleChange(filter.page - 1, "page");
        } else {
          handleChange();
        }
      }).catch(err => {
        setLoading(false);
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
        reject(err);
      });
    })
  };

  useEffect(() => {
    if (!filter) {
      return;
    }

    dispatch(populationsAction(filter)).then((data) => {
      setItems(data.items || []);
      setTotal(data.total);
      localStorage.setItem(filterName, JSON.stringify(filter));
    }).catch(err => {
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }, [dispatch, filter, filterName]);

  return <React.Fragment>
    {total === 0 && <Message color="purple">No records found.</Message>}

    {total > 0 && <Table celled striped sortable compact>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Code</Table.HeaderCell>
          <Table.HeaderCell>Area name</Table.HeaderCell>
          <Table.HeaderCell>Total</Table.HeaderCell>
          <Table.HeaderCell>Male/Female</Table.HeaderCell>
          <Table.HeaderCell>Density</Table.HeaderCell>
          <Table.HeaderCell>Number of households<br />
                            with 1 resident min.</Table.HeaderCell>
          <Table.HeaderCell width={2}/>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((i) => {
          return <Table.Row key={i.id}>
            <Table.Cell>{i.area_code}</Table.Cell>
            <Table.Cell>
              {i.parent_id === 0 && <b style={{color: "green"}}>{i.area_name}</b>}
              {i.parent_id > 0 && i.is_sub && <b style={{color: "blue", paddingLeft: "10px"}}>{i.area_name}</b>}
              {i.parent_id > 0 && !i.is_sub && <span style={{paddingLeft: "20px"}}>{i.area_name}</span>}
            </Table.Cell>
            <Table.Cell>{i.all_persons}</Table.Cell>
            <Table.Cell>{i.males} / {i.females}</Table.Cell>
            <Table.Cell>{i.population_density}</Table.Cell>
            <Table.Cell>{i.number_of_households_with_at_least_one_usual_resident}</Table.Cell>
            <Table.Cell textAlign="center">
              {/*<ConfirmModal content='Do you wish to delete item?' ok={() => deletePopulation(i.id)}
                            trigger={<Button size='mini' icon><Icon name='delete'/></Button>}/>*/}
            </Table.Cell>
          </Table.Row>;
        })}
      </Table.Body>
    </Table>}

    <Paging total={total} page={filter.page} limit={filter.limit} handleChange={handleChange}/>

  </React.Fragment>;
}

export default Population
