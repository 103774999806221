import {authMiddleware} from "./loginAction";
import cfg, {formatStatusError, handleResponse} from "../constants";

export const getFootfallAction = (data) => (dispatch, getState) => {

  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/footfall', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getFootfallDynamicAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/footfall-dynamic', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getHouseholdsDynamicAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/households-dynamic', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getFootfallResolutionsAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/footfall-resolutions', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getPopulationResAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/population-resolutions', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getPopulationAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/population', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getCrimeAction = () => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/crime', {
    method: "GET",
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getHexCrimeAction = (hex) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/crime/'+hex, {
    method: "GET",
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getHexCrimeErrAction = (hex) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/crime-err', {
    method: "GET",
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getPolyAction = (hex) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/all-poly', {
    method: "GET",
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getLiquorStoresStatAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/liquor-stores-stat', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getPopulationPoisMapAction = (data) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/population-map-pois', {
    method: "POST",
    headers,
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getCompetitorsPois = (data) => (dispatch, getState) => {
  return fetch(cfg.base_url + '/competitors', {
    method: "POST",
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getDeliveryZoneAction = (data) => (dispatch, getState) => {
  //const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/delivery-zone', {
    method: "POST",
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};
