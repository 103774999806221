import React, {useState, useEffect} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Container, Button, Form, Segment, Message} from "semantic-ui-react";
import Header from "semantic-ui-react/dist/commonjs/elements/Header";
import {useDispatch} from "react-redux";
import {Link, useNavigate, useParams} from "react-router-dom";
import {restoreAction} from "../../actions/loginAction";
import {toast} from "react-toastify";
import {formatErrors} from "../../constants";

export function SetPassword(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {code} = useParams();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [sent, setSent] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [form, handleChange] = useState({code: '', password: '', password2: ''});

  useEffect(() => {
    if (!code) {
      navigate('/login');
      return;
    }

    dispatch(restoreAction({code})).then((data) => {
      if (data.can_change) {
        setSent(true);
      } else {
        navigate('/login');
      }
    }).catch(() => {
      navigate('/login');
    });
  }, [code, navigate, dispatch]);

  const handleChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({...form, [name]: value});
    setErrors({...errors, [name]: value.trim() === ''});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return false;
    }

    let errs = {};
    if (form.password === '') {
      errs.password = true;
    }
    if (form.password2 === '') {
      errs.password2 = true;
    }

    if (form.password !== form.password2) {
      errs.password2 = true;
    }

    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      return false;
    }

    setLoading(true);
    dispatch(restoreAction({password: form.password, code})).then(() => {
      setLoading(false);
      setConfirm(true);
    }).catch(err => {
      setLoading(false);
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  return <React.Fragment>
    <MyAppBar/>
    <Container className={'container-narrow'}>
      <Header as={'h3'}>Password reset</Header>

      {sent && confirm && <Message positive>
        Password changed. Please <Link to={'/login'} className={'purple'}><b>sign in</b></Link>.
      </Message>}

      {sent && !confirm && <Form size='large' noValidate>
        <Segment stacked>
          <Form.Input error={!!errors.password} label="Password" name="password" type="password" value={form.password}
                      onChange={handleChangeForm}/>

          <Form.Input error={!!errors.password2} label="Repeat password" name="password2" type="password" value={form.password2}
                      onChange={handleChangeForm}/>

          <Button type="submit" fluid size='medium' color="black" onClick={handleSubmit}>Update</Button>
        </Segment>
      </Form>}
    </Container>
  </React.Fragment>;
}


export default SetPassword
