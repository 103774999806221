import React from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Segment, Message, Container} from "semantic-ui-react";

export function Forbidden() {

  return <React.Fragment>
    <MyAppBar/>
    <Container className={'container-narrow'}>
      <Segment padded basic className="segment-narrow">
          <Message icon="ban" info header="Forbidden" content="You don't have access to requested URL"/>
      </Segment>
    </Container>
  </React.Fragment>;
}

export default Forbidden
