import React, {useState, useEffect} from 'react'
import {Button, Dropdown, Form, Modal} from 'semantic-ui-react'
import {toast} from "react-toastify";
import {formatErrors, roles, user_statuses, validateEmail} from "../../../constants";
import { useDispatch } from 'react-redux';
import { userEditAction, userGetAction } from '../../../actions/userAction';

function UserModal({trigger, itemID, ok}) {
  const [open, setOpen] = React.useState(false)
  return (
    <Modal closeIcon
           onClose={() => setOpen(false)}
           onOpen={() => setOpen(true)}
           open={open}
           trigger={trigger}
           size={'tiny'}
    >
      <UserModalContent itemID={itemID} setOpen={setOpen} ok={ok} />
    </Modal>
  )
}

function UserModalContent({itemID, ok, setOpen}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [form, handleChange] = useState({
    id: '',
    name: '',
    email: '',
    phone: '',
    role_id: -1,
    status: 0,
    password: '',
  });

  const handleChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const required = e.target.required;
    handleChange({...form, [name]: value});
    setErrors({...errors, [name]: required && value.trim() === ''});
  };

  const handleChangeDropDown = (e, data) => {
    const name = data.name;
    const value = data.value;
    handleChange({...form, [name]: value});
    setErrors({...errors, [name]: value < 0});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return false;
    }

    let errs = {};
    if (typeof form.role_id !== 'number' || form.role_id < 0) {
      errs.role_id = true;
    }
    if (form.name.trim() === '') {
      errs.firsr_name = true;
    }
    if (form.email.trim() === '' || !validateEmail(form.email.trim())) {
      errs.email = true;
    }

    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      return;
    }
    setLoading(true);

    dispatch(userEditAction({...form, role_id: form.role_id + 1})).then(() => {
      setLoading(false);
      ok();
      setOpen(false);
    }).catch((err) => {
      setLoading(false);
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  useEffect(() => {
    if (itemID) {
      dispatch(userGetAction(itemID)).then((res) => {
        handleChange({
          id: itemID,
          name: res.name,
          email: res.email,
          phone: res.phone,
          role_id: res.role_id,
          status: res.status,
          password: '',
        });
      }).catch(err => {
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
      });
    }
  }, [itemID, dispatch]);


  return <React.Fragment>
    <Modal.Header>Edit user</Modal.Header>
    <Modal.Content>
      <Form noValidate>

        <Form.Input label="Name" name="name" value={form.name} onChange={handleChangeForm} required error={!!errors.name} />

        <Form.Input label="E-mail" name="email" value={form.email} onChange={handleChangeForm} required error={!!errors.email} />

        <Form.Input label="Phone" name="phone" value={form.phone} onChange={handleChangeForm} error={!!errors.phone} />

        <Form.Field required>
          <label>Role</label>
          <Dropdown options={roles} selection placeholder='please select' error={!!errors.role_id}
                    name='role_id' value={form.role_id} onChange={handleChangeDropDown} />
        </Form.Field>

        <Form.Field required>
          <label>Status</label>
          <Dropdown options={user_statuses} selection placeholder='please select' error={!!errors.status}
                    name='status' value={form.status} onChange={handleChangeDropDown} />
        </Form.Field>

        <Form.Input label="Password (if you want to change it)" name="password" type="password" value={form.password}
                      onChange={handleChangeForm}/>


      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={() => setOpen(false)} disabled={loading}>Close</Button>
      <Button type="button" color="black" onClick={handleSubmit} disabled={loading} loading={loading}>Save</Button>
    </Modal.Actions>
  </React.Fragment>
}

export default UserModal
