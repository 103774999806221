import React, {useState, useEffect} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Button, Form, Container, Menu} from "semantic-ui-react";
import {userGetAction} from "../../actions/userAction";
import {changePasswordAction, updateProfileAction} from "../../actions/profileAction";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {formatErrors, ROLE_USER} from "../../constants";
import TwofaTab from "./ProfileTwofaTab";

function Profile(props) {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.user.profile.id);
  const roleId = useSelector(state => state.user.profile.role_id);
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState('profile');
  const [errors, setErrors] = useState({});
  const [form, handleChange] = useState({
    email: '', name: '', phone: '', password: '', password2: '', old_password: '', tfa: null
  });

  useEffect(() => {
    if (!userID) {
      return;
    }
    dispatch(userGetAction(userID)).then((item) => {
      if (item) {
        handleChange({name: item.name,
          email: item.email, phone: item.phone,
          password: '', password2: '', old_password: '', tfa: item.tfa});
      }
    }).catch(err => {
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }, [userID, dispatch]);

  const handleChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const required = e.target.required;
    handleChange({...form, [name]: value});
    setErrors({...errors, [name]: required && value.trim() === ''});
  }

  const handleBasic = (e) => {
    e.preventDefault();
    if (loading) {
      return false;
    }

    let errs = {};
    if (form.name.trim() === '') {
      errs.name = true;
    }
    if (form.email.trim() === '') {
      errs.email = true;
    }

    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      return;
    }

    // save
    setLoading(true);
    dispatch(updateProfileAction({name: form.name, email: form.email})).then(() => {
      setLoading(false);
      toast.success("Profile was updated!", {position: toast.POSITION.BOTTOM_RIGHT});
    }).catch((e) => {
      setLoading(false);
      toast.error(formatErrors([e.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  const handlePassword = (e) => {
    e.preventDefault();
    if (loading) {
      return false;
    }

    let errs = {};
    if (form.old_password === '' && roleId !== ROLE_USER) {
      errs.old_password = true;
    }
    if (form.password === '') {
      errs.password = true;
    }
    if (form.password2 === '') {
      errs.password2 = true;
    }

    if (form.password !== form.password2) {
      errs.password2 = true;
    }
    setErrors(errs);

    if (Object.keys(errs).length > 0) {
      return false;
    }

    setLoading(true);
    dispatch(changePasswordAction({password: form.password, old_password: form.old_password})).then(() => {
      setLoading(false);
      handleChange({...form, old_password: '', password: '', password2: ''});
      toast.success("Password was updated!", {position: toast.POSITION.BOTTOM_RIGHT});
    }).catch(err => {
      setLoading(false);
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  const handleUpdateTfa = (tfa) => {
    handleChange({...form, tfa});
  }

  const handleTab = (tab) => {
    setItem(tab);
  }

  return <React.Fragment>
    <MyAppBar/>

    <Container className={'container-narrow'}>

      <Menu secondary>
        <Menu.Item
          name='Profile'
          active={item === 'profile'}
          onClick={() => handleTab('profile')}
        />

        <Menu.Item
          name='Change password'
          active={item === 'password'}
          onClick={() => handleTab('password')}
        />

        {/*<Menu.Item
          name='2FA'
          active={item === 'tfa'}
          onClick={() => handleTab('tfa')}
        />*/}
      </Menu>

      {item === 'profile' && <ProfileTab handleChangeForm={handleChangeForm} handleSubmit={handleBasic}
                              form={form} errors={errors} />}

      {item === 'password' &&
      <PasswordTab handleChangeForm={handleChangeForm} handleSubmit={handlePassword} form={form} errors={errors} roleId={roleId}/>}

      {item === 'tfa' &&
      <TwofaTab onUpdate={handleUpdateTfa} twofa={form.tfa} />}

    </Container>
  </React.Fragment>;
}

const ProfileTab = (props) => {
  const form = props.form;
  const errors = props.errors;

  return <Form size='large' noValidate>
    <Form.Input label={'Name'} fluid placeholder='Name' error={!!errors.name} required
                name='name' value={form.name} onChange={props.handleChangeForm}/>

    <Form.Input label={'E-mail'} fluid placeholder='E-mail address' readOnly={true} className='disabled-input'
                name='email' value={form.email} onChange={props.handleChangeForm}/>

    {/*<Form.Input label={'Phone'} fluid placeholder='Phone'
                name='phone' value={form.phone} onChange={props.handleChangeForm}/>*/}

    <Button fluid size='medium' color='black' onClick={props.handleSubmit}>Update</Button>
  </Form>;
}

const PasswordTab = (props) => {
  const form = props.form;
  const errors = props.errors;

  return <Form size='large' noValidate>
    {props?.roleId !== ROLE_USER && <Form.Input label={'Old Password'} fluid placeholder='' error={!!errors.old_password} required
                name='old_password' type='password' value={form.old_password} onChange={props.handleChangeForm}/>}

    <Form.Input label={'New Password'} fluid placeholder='' error={!!errors.password} required
                name='password' type='password' value={form.password} onChange={props.handleChangeForm}/>

    <Form.Input label={'Repeat Password'} fluid placeholder='' error={!!errors.password2} required
                name='password2' type='password' value={form.password2} onChange={props.handleChangeForm}/>

    <Button fluid size='medium' color="black" onClick={props.handleSubmit}>Update password</Button>
  </Form>;
}

export default Profile
