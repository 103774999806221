import {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {UPDATE_SIGNIN} from "../../constants";
import {removeUser} from "../../actions/loginAction";

function Logout(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    removeUser();
    dispatch({type: UPDATE_SIGNIN, profile: {}, manual: true})
    setTimeout(() => {
      window.location = '/login';
    }, 100);
  }, [dispatch]);
  return null;
}

export default Logout
