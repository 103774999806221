import React, {useEffect, useState} from 'react';

// todo:: able to refactor into map
function DailyFootfallAverage({avgTotal, workdayAvg, weekendAvg, avgObj, month}) {

  const [workdayDiff, setWorkdayDiff] = useState(null);
  const [weekendDiff, setWeekendDiff] = useState(null);

  useEffect(() => {
    if (workdayDiff === null) {
      setWorkdayDiff(CalcDiff(workdayAvg, avgTotal));
    }
  }, [workdayDiff]);

  useEffect(() => {
    if (weekendDiff === null) {
      setWeekendDiff(CalcDiff(weekendAvg, avgTotal));
    }
  }, [weekendDiff]);

  function CalcDiff(price1, price2) {
    return price2 > 0 ?(Math.round(((price1 - price2)/price2)*100*100)/100): 0;
  }

  function GetClass(val) {
    if (val === 0) {
      return ""
    } else {
      return val > 0?"plus":"minus";
    }
  }

  return <React.Fragment>
    <div className={"DFA-table bottom-line main"}>
      <div className={"DFA-table-cell DFA-table-hdr"}>
        <p className={"DFA-h2"}>{avgTotal} people</p>
        <span className={"DFA-h2-subh"}>per day</span>
      </div>
      <div className={"DFA-table-cell"}>

      </div>
      <div className={"DFA-table-cell DFA-table-60"}>
        <div className={"DFA-table"}>
          <div className={"DFA-table-row"}>
            <div className={"DFA-table-col itm"}>
              <div className={"flex-data"}>
                <span>Residents</span><span>{avgObj.DayR}%</span>
              </div>
            </div>
            <div className={"DFA-table-col"}>
              <Line width={avgObj.DayR} color={"rgba(57, 53, 216, 1)"}/>
            </div>
          </div>
          <div className={"DFA-table-row"}>
            <div className={"DFA-table-col itm"}>
              <div className={"flex-data"}>
                <span>Employees</span><span>{avgObj.DayW}%</span>
              </div>
            </div>
            <div className={"DFA-table-col"}>
              <Line width={avgObj.DayW} color={"rgba(138, 134, 232, 1)"}/>
            </div>
          </div>
          <div className={"DFA-table-row"}>
            <div className={"DFA-table-col itm"}>
              <div className={"flex-data"}>
                <span>Passersby</span><span>{avgObj.DayT}%</span>
              </div>
            </div>
            <div className={"DFA-table-col"}>
              <Line width={avgObj.DayT} color={"rgba(196, 194, 243, 1)"}/>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className={"DFA-table"}>
      <div className={"DFA-table-cell DFA-table-hdr"}>
        <span className={"DFA-h2-uph"}>per workday:</span>
        <p className={"DFA-h2"}>{workdayAvg} people</p>
      </div>
      <div className={"DFA-table-cell"}>
        {workdayDiff !== null && <div className={"DFA-diff"}>
          <div className={GetClass(workdayDiff)}>{Math.abs(workdayDiff)}%</div>
          <p>{workdayDiff >= 0?"more":"less"} than <b>{month}</b> average</p>
        </div>}
      </div>
      <div className={"DFA-table-cell DFA-table-60"}>
        <div className={"DFA-table"}>
          <div className={"DFA-table-row"}>
            <div className={"DFA-table-col itm"}>
              <div className={"flex-data"}>
                <span>Residents</span><span>{avgObj.WorkdayR}%</span>
              </div>
            </div>
            <div className={"DFA-table-col"}>
              <Line width={avgObj.WorkdayR} color={"rgba(57, 53, 216, 1)"}/>
            </div>
          </div>
          <div className={"DFA-table-row"}>
            <div className={"DFA-table-col itm"}>
              <div className={"flex-data"}>
                <span>Employees</span><span>{avgObj.WorkdayW}%</span>
              </div>
            </div>
            <div className={"DFA-table-col"}>
              <Line width={avgObj.WorkdayW} color={"rgba(138, 134, 232, 1)"}/>
            </div>
          </div>
          <div className={"DFA-table-row"}>
            <div className={"DFA-table-col itm"}>
              <div className={"flex-data"}>
                <span>Passersby</span><span>{avgObj.WorkdayT}%</span>
              </div>
            </div>
            <div className={"DFA-table-col"}>
              <Line width={avgObj.WorkdayT} color={"rgba(196, 194, 243, 1)"}/>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div className={"DFA-table"}>
      <div className={"DFA-table-cell DFA-table-hdr"}>
        <span className={"DFA-h2-uph"}>per weekend:</span>
        <p className={"DFA-h2"}>{weekendAvg} people</p>
      </div>
      <div className={"DFA-table-cell"}>
        {weekendDiff !== null && <div className={"DFA-diff"}>
          <div className={GetClass(weekendDiff)}>{Math.abs(weekendDiff)}%</div>
          <p>{weekendDiff >= 0?"more":"less"} than <b>{month}</b> average</p>
        </div>}
      </div>
      <div className={"DFA-table-cell DFA-table-60"}>
        <div className={"DFA-table"}>
          <div className={"DFA-table-row"}>
            <div className={"DFA-table-col itm"}>
              <div className={"flex-data"}>
                <span>Residents</span><span>{avgObj.WeekendR}%</span>
              </div>
            </div>
            <div className={"DFA-table-col"}>
              <Line width={avgObj.WeekendR} color={"rgba(57, 53, 216, 1)"}/>
            </div>
          </div>
          <div className={"DFA-table-row"}>
            <div className={"DFA-table-col itm"}>
              <div className={"flex-data"}>
                <span>Employees</span><span>{avgObj.WeekendW}%</span>
              </div>
            </div>
            <div className={"DFA-table-col"}>
              <Line width={avgObj.WeekendW} color={"rgba(138, 134, 232, 1)"}/>
            </div>
          </div>
          <div className={"DFA-table-row"}>
            <div className={"DFA-table-col itm"}>
              <div className={"flex-data"}>
                <span>Passersby</span><span>{avgObj.WeekendT}%</span>
              </div>
            </div>
            <div className={"DFA-table-col"}>
              <Line width={avgObj.WeekendT} color={"rgba(196, 194, 243, 1)"}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>;
}

function Line({width, color, main}) {
  return <React.Fragment>
    <div style={{width: width+"%", backgroundColor: color}} className={"DFA-line"}/>
  </React.Fragment>;
}

export default DailyFootfallAverage
