import React, {useState} from 'react';
import {formatErrors, validateEmail} from "../../../constants";
import {useDispatch} from "react-redux";
import {authAction, registerAction} from "../../../actions/loginAction";
import {toast} from "react-toastify";

function LoginWidget({handleClose, reportSess}) {

  const dispatch = useDispatch();

  const actionSignUp = 0
  const actionSignIn = 1;

  const [loading, setLoading] = useState(false);

  const [action, setAction] = useState(actionSignUp)
  const [signInForm, setSignInForm] = useState({email: "", password: ""});
  const [signUpForm, setSignUpForm] = useState({email: "", password: ""});

  const [signInErr, setSignInErr] = useState({email: false, password: false});
  const [signUpErr, setSignUpErr] = useState({email: false, password: false});

  function validatePass(v) {
    return v.length >= 8;
  }

  function HandleSignInChange(k, v) {
    if (signInErr.email||signInErr.password) {
      setSignInErr({email: false, password: false});
    }
    setSignInForm({...signInForm, [k]: v});
  }

  function SignIn() {
    let errs = {email: false, password: false};

    if (!validateEmail(signInForm.email)) {
      errs.email = true;
    }

    if (!validatePass(signInForm.password)) {
      errs.password = true;
    }

    if (errs.email||errs.password) {
      setSignInErr({...errs});
      return
    }

    setLoading(true);
    dispatch(authAction({email: signInForm.email, password: signInForm.password})).then((res) => {
      setLoading(false);

      window.location.reload();
    }).catch((err) => {
      setLoading(false);

      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  //==== sign up

  function HandleSignUpChange(k, v) {
    if (signUpErr.email||signUpErr.password) {
      setSignUpErr({email: false, password: false});
    }
    setSignUpForm({...signUpForm, [k]: v});
  }

  function SignUp() {

    let errs = {email: false, password: false};
    let name = "";

    if (!validateEmail(signUpForm.email)) {
      errs.email = true;
    }

    if (!validatePass(signUpForm.password)) {
      errs.password = true;
    }

    if (errs.email||errs.password) {
      setSignUpErr({...errs});
      return
    }

    name = signUpForm.email.split("@")[0];

    setLoading(true);
    dispatch(registerAction({email: signUpForm.email, password: signUpForm.password, name: name, report_sess: !!reportSess?reportSess:""})).then((res) => {
      setLoading(false);

      window.location.reload();
    }).catch((err) => {
      setLoading(false);

      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  return <React.Fragment>
    <div className={"login-popup-body"}>
      <div className={"login-popup-close-button"} onClick={() => handleClose()}>&#x2715;</div>
      {action === actionSignUp && <React.Fragment>
        <h2>Sign up</h2>
        <div className={"login-popup-block"}>
          <label>Business E-mail</label>
          <input
            className={((!!signUpForm.email && !validateEmail(signUpForm.email))||signUpErr.email)?"error":""}
            onChange={(e) => {HandleSignUpChange("email", e.target.value)}}
            value={signUpForm.email}
            readOnly={loading}
            type={"text"}
            autoComplete={"on"}
            name={"email"}
          />
        </div>
        <div className={"login-popup-block"}>
          <label>Password</label>
          <input
            type={"password"}
            className={((!!signUpForm.password && !validatePass(signUpForm.password))||signUpErr.password)?"error":""}
            onChange={(e) => {HandleSignUpChange("password", e.target.value)}}
            value={signUpForm.password}
            readOnly={loading}
          />
          <div className={"gtxt"}>At least 8 characters</div>
        </div>
        <a className={"login-popup-button "+(loading?" disabled-link":"")} onClick={() => {SignUp()}}>Create an account</a>
        <div className={"login-popup-grtxt"}>
          <p className={"gtxt"}>By proceeding, I accept the getplace.io</p>
          <a href={"https://getplace.io/privacy.html"} target={"_blank"}>Privacy Policy</a>
        </div>
        <div className={"login-popup-signin"}>
          <span>Already have an account?</span>&nbsp;<a onClick={(e) => {e.preventDefault();setAction(actionSignIn);}} >Sign in here</a>
        </div>
      </React.Fragment>}

      {action === actionSignIn && <React.Fragment>
        <h2>Sign in</h2>
        <div className={"login-popup-block"}>
          <label>E-mail</label>
          <input
            className={((!!signInForm.email && !validateEmail(signInForm.email))||signInErr.email)?"error":""}
            onChange={(e) => {HandleSignInChange("email", e.target.value)}}
            value={signInForm.email}
            readOnly={loading}
            type={"text"}
            autoComplete={"on"}
            name={"email"}
          />
        </div>
        <div className={"login-popup-block"}>
          <label>Password</label>
          <input
            type={"password"}
            className={((!!signInForm.password && !validatePass(signInForm.password))||signInErr.password)?"error":""}
            onChange={(e) => {HandleSignInChange("password", e.target.value)}}
            value={signInForm.password}
            readOnly={loading}
          />
        </div>
        <a className={"login-popup-button-white "+(loading?" disabled-link":"")}   onClick={() => {SignIn()}}>Sign in</a>
        <div className={"login-popup-grtxt"}>
          <p className={"gtxt"}>No account yet?</p>
        </div>
        <a className={"login-popup-button"} onClick={(e) => {e.preventDefault();setAction(actionSignUp);}}>Create an account</a>
      </React.Fragment>}
    </div>
  </React.Fragment>
}

export default LoginWidget
