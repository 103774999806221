import React, {useState} from 'react'
import {Button, Dropdown, Form, Modal} from 'semantic-ui-react'
import {toast} from "react-toastify";
import {formatErrors, roles, validateEmail} from "../../../constants";
import { useDispatch } from 'react-redux';
import { userInviteAction } from '../../../actions/userAction';

function UserInviteModal({trigger, ok}) {
  const [open, setOpen] = React.useState(false)
  return (
    <Modal closeIcon
           onClose={() => setOpen(false)}
           onOpen={() => setOpen(true)}
           open={open}
           trigger={trigger}
           size={'tiny'}
    >
      <UserInviteModalContent setOpen={setOpen} ok={ok} />
    </Modal>
  )
}

function UserInviteModalContent({ok, setOpen}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [form, handleChange] = useState({
    first_name: '',
    last_name: '',
    email: '',
    role_id: -1
  });

  const handleChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({...form, [name]: value});
    setErrors({...errors, [name]: value.trim() === ''});
  };

  const handleChangeDropDown = (e, data) => {
    const name = data.name;
    const value = data.value;
    handleChange({...form, [name]: value});
    setErrors({...errors, [name]: value < 0});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return false;
    }

    let errs = {};
    if (typeof form.role_id !== 'number' || form.role_id < 0) {
      errs.role_id = true;
    }
    if (form.first_name.trim() === '') {
      errs.firsr_name = true;
    }
    if (form.last_name.trim() === '') {
      errs.last_name = true;
    }
    if (form.email.trim() === '' || !validateEmail(form.email.trim())) {
      errs.email = true;
    }

    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      return;
    }
    setLoading(true);

    dispatch(userInviteAction({...form, role_id: form.role_id + 1})).then(() => {
      setLoading(false);
      ok();
      setOpen(false);
    }).catch((err) => {
      setLoading(false);
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  return <React.Fragment>
    <Modal.Header>Invite user</Modal.Header>
    <Modal.Content>
      <Form noValidate>
        <Form.Input label="First Name" name="first_name" value={form.first_name} onChange={handleChangeForm} required error={!!errors.first_name} />
        <Form.Input label="Last Name" name="last_name" value={form.last_name} onChange={handleChangeForm} required error={!!errors.last_name} />
        <Form.Input label="E-mail" name="email" value={form.email} onChange={handleChangeForm} required error={!!errors.email} />

        <Form.Field required>
          <label>Role</label>
          <Dropdown options={roles} selection placeholder='please select' error={!!errors.role_id}
                    name='role_id' value={form.role_id} onChange={handleChangeDropDown} />
        </Form.Field>

      </Form>
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={() => setOpen(false)} disabled={loading}>Close</Button>
      <Button type="button" color="black" onClick={handleSubmit} disabled={loading} loading={loading}>Save</Button>
    </Modal.Actions>
  </React.Fragment>
}

export default UserInviteModal
