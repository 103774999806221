import React, {useRef, useState} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Button, Form, Header, Container, TextArea, Message} from "semantic-ui-react";
import {useDispatch} from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import cfg, {formatErrors, validateEmail} from "../../constants";
import {toast} from "react-toastify";
import {sendContactAction} from "../../actions/contactAction";


function Contact(props) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  const [form, handleChange] = useState({
    first_name: '', last_name: '', email: '', company: '', message: '', captcha: ''
  });
  const recaptchaRef = useRef(null);

  const handleChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    handleChange({...form, [name]: value});
    setErrors({...errors, [name]: value.trim() === ''});
  }

  const handleCaptcha = (value) => {
    setErrors({...errors, captcha: value.trim() === ''});
    handleChange({...form, captcha: value});
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (loading) {
      return false;
    }

    let errs = {};
    for (const f of ['first_name', 'last_name']) {
      if (form[f].trim() === '') {
        errs[f] = true;
      }
    }

    if (form.email === '' || !validateEmail(form.email)) {
      errs.email = true;
    }

    if (captcha && !form.captcha) {
      errs.captcha = true
    }

    setErrors(errs);
    if (Object.keys(errs).length > 0) {
      return;
    }

    setLoading(true);
    dispatch(sendContactAction(form)).then(() => {
      setLoading(false);
      if (captcha) {
        recaptchaRef.current.reset();
      }
      handleChange({first_name: '', last_name: '', email: '', company: '', message: '', captcha: ''});
      toast.success("Message sent!", {position: toast.POSITION.BOTTOM_RIGHT});
    }).catch(err => {
      setLoading(false);
      if (captcha) {
        handleChange({...form, captcha: ''});
        recaptchaRef.current.reset();
      }
      if (err.message === 'need:captcha' || err.message === 'wrong:captcha' || err.message === 'empty:captcha') {
        setCaptcha(true);
        setErrors({captcha: true});
      }
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }

  return <React.Fragment>
    <MyAppBar/>

    <Container className={'container-narrow'}>
      <Header as='h3' textAlign='center'>Contact us</Header>
      <Form size='large' noValidate>
        <Form.Input label={'First Name'} fluid placeholder='First Name'
                    name='first_name' value={form.first_name} onChange={handleChangeForm}  error={!!errors.first_name}/>

        <Form.Input label={'Last Name'} fluid placeholder='Last Name'
                    name='last_name' value={form.last_name} onChange={handleChangeForm}  error={!!errors.last_name}/>

        <Form.Input label={'Company'} fluid placeholder='Company'
                    name='company' value={form.company} onChange={handleChangeForm}/>

        <Form.Input label={'E-mail'} fluid placeholder='E-mail address'
                    name='email' value={form.email} onChange={handleChangeForm}  error={!!errors.email}/>

        <Form.Field error={!!errors.message}>
          <label>Message</label>
          <TextArea label={'Message'} placeholder='Please write a message' name='message'
                    value={form.message} onChange={handleChangeForm}  error={!!errors.message ? 'true' : 'false'}/>
        </Form.Field>

        {captcha && <Form.Field>
          <div>
            <ReCAPTCHA
              sitekey={cfg.recaptcha}
              onChange={handleCaptcha}
              ref={recaptchaRef}
            />
          </div>
          {!!errors.captcha && <Message negative>Please confirm that you are not a robot</Message>}
        </Form.Field>}


        <Button fluid size='medium' color="black" onClick={handleSubmit}>Send message</Button>
      </Form>
    </Container>
  </React.Fragment>;
}

export default Contact
