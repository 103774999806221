import React, {useEffect, useState} from 'react';
import {Dropdown} from "semantic-ui-react";
import {monthsShort} from "../../../constants";

function DateChanger({dates, handleChange, activeDate}) {

  const [datesObj, setDatesObj] = useState({});
  const [years, setYears] = useState([]);
  const [year, setYear] = useState(null);

  useEffect(() => {
    if (Object.keys(datesObj).length === 0 && dates.length > 0) {
      for (let itm of dates) {
        if (!datesObj.hasOwnProperty(itm.year)) {
          datesObj[itm.year] = [];
        }
      }

      for (let i = dates.length-1; i >= 0; i--) {
        datesObj[dates[i].year].push({...dates[i], key: i});
      }

      Object.keys(datesObj).sort((a, b) => a < b?-1:1);
      let tmp = [];
      let idx = 0;
      for (let itm of Object.keys(datesObj)) {
        tmp.push({key: idx++, value: itm+"", text:itm+""})
      }

      setYears(tmp);
      setYear(dates[activeDate].year+"");
      setDatesObj({...datesObj});
    }
  }, [dates]);

  return <React.Fragment>
    {years.length > 0 && !!year && <div>
      <h2 className={"monthly-analytics"}>Monthly analytics</h2>
      <div className={"monthly-analytics-container"}>
        <span>Show data for:</span>
        <Dropdown value={year} selection options={years} onChange={(e,v) => {setYear(v.value)}}/>
        {datesObj[year].map((itm, idx) => {
          return <span key={idx} className={"monthly-analytics-itm "+(itm.key===activeDate?"active":"")} onClick={() => {handleChange(itm.key)}}>{monthsShort[itm.month]}</span>
        })}
      </div>
    </div>}
  </React.Fragment>;
}

export default DateChanger
