import React, {useState} from 'react'
import {Button, Modal} from 'semantic-ui-react'

export function UserDeleteModal({ok, trigger}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOK = () => {
    setLoading(true);
    Promise.resolve(ok(false)).then((res) => {
      setLoading(false);
      if (res) {
        setOpen(false);
      }
    }).catch(() => {
      setLoading(false);
    })
  };

  return (
    <Modal closeIcon={false}
           closeOnDocumentClick={false}
           closeOnDimmerClick={false}
           onOpen={() => setOpen(true)}
           open={open}
           trigger={trigger}
           size={'tiny'}>
      <React.Fragment>
        <Modal.Header>Please confirm</Modal.Header>
        <Modal.Content>
          <p>Do you wish to delete the user?</p>
         </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)} disabled={loading}>Cancel</Button>
          <Button type="button" color="black" onClick={handleOK} loading={loading} disabled={loading}>Ok</Button>
        </Modal.Actions>
      </React.Fragment>
    </Modal>
  )
}

