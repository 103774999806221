import React, { useEffect, useState } from 'react';
import {Grid, Pagination, Form, Dropdown} from "semantic-ui-react";
import {limits} from "../../constants";

function Paging({total, page, limit, handleChange, noTotal = false, size = "small", boundaryRange = 2, noLimit = false}) {

  const [all, setAll] = useState();

  useEffect(() => {
    if (!noTotal) {
      setAll(total);
    } else {
      setAll(total === limit ? (page - 1) * limit + total + 1 : (page - 1) * limit + total);
    }
  }, [total, page, limit, noTotal]);
  
  if (all > 0) {
    return (
      <Grid stackable verticalAlign="middle">
        <Grid.Row>
        <Grid.Column width={11}>
          {!noTotal && <Pagination boundaryRange={boundaryRange} size={size}
                                   activePage={page}
                                   totalPages={Math.ceil(all / limit)}
                                   onPageChange={(e, {activePage}) => handleChange(activePage, "page")} />}
          {noTotal && <Pagination boundaryRange={0}
                                  activePage={page}
                                  siblingRange={0}
                                  firstItem={null}
                                  lastItem={null}
                                  ellipsisItem={null}
                                  totalPages={Math.ceil(all / limit)}
                                  onPageChange={(e, {activePage}) => handleChange(activePage, "page")} />}
          </Grid.Column>
          <Grid.Column width={5} textAlign="right">
            <Form.Field inline>
              {!noTotal && <label>{(page - 1) * limit + 1} to {Math.min(total, page * limit)} of {total}{!noLimit && <>. &nbsp;Show </>}</label>}
              {noTotal && <label>{(page - 1) * limit + 1} to {Math.min((page - 1) * limit + total, page * limit)}{!noLimit && <>. &nbsp;Show </>}</label>}
              {!noLimit && <Dropdown compact selection options={limits}
                        name="limit" value={limit} onChange={(e, {value}) => handleChange(value, "limit")} />}
            </Form.Field>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
  return null;
}

export default Paging
