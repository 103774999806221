import {authMiddleware} from "./loginAction";
import cfg, {formatStatusError, handleResponse} from "../constants";

export const getCitiesAction = () => (dispatch, getState) => {

  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/cities', {
    method: "GET",
    headers,
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getBrandsAction = (filter) => (dispatch, getState) => {

  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/brands', {
    method: "POST",
    body: JSON.stringify(filter),
    headers,
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getBrandProductsAction = (filter) => (dispatch, getState) => {

  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/brand-products', {
    method: "POST",
    body: JSON.stringify(filter),
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getProductInfoAction = (filter) => (dispatch, getState) => {

  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/product-info', {
    method: "POST",
    body: JSON.stringify(filter),
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getPlaceInfoAction = (filter) => (dispatch, getState) => {

  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/place-info', {
    method: "POST",
    body: JSON.stringify(filter),
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getPricePulseReportAction = (filter) => (dispatch, getState) => {

  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/price-pulse-report', {
    method: "POST",
    body: JSON.stringify(filter),
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getPricingResearchReportAction = (filter) => (dispatch, getState) => {

  return fetch(cfg.base_url + '/pricing-research-report', {
    method: "POST",
    body: JSON.stringify(filter)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const getPointsProductsAction = (filter) => (dispatch, getState) => {
  return fetch(cfg.base_url + '/points-products', {
    method: "POST",
    body: JSON.stringify(filter)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};
