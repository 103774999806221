import {trackAction} from "../../actions/loginAction";
import {ROLE_ADMIN, ROLE_MANAGER, ROLE_OWNER} from "../../constants";

export const Track = (dispatch, profile, action, href) => {

  if (process.env.hasOwnProperty("REACT_APP_DISABLE_TRACK")) {
    console.log("TRACK",  profile.email, action, href);
    if (!!href) {
      document.location = href;
    } else {
      return;
    }
  }

  if (!!!profile || profile?.role_id === null || profile?.role_id === undefined || [ROLE_ADMIN, ROLE_MANAGER, ROLE_OWNER].indexOf(profile.role_id)>=0) {
    if (!!href) {
      document.location = href;
    } else {
      return;
    }
  }

  dispatch(trackAction("https://hooks.zapier.com/hooks/catch/15866486/31dd36t/", {'email': profile.email, 'action': action})).then(() => {
    if (!!href) {
      document.location = href;
    }
  }).catch(() => {
    if (!!href) {
      document.location = href;
    }
  });
}
