import React from 'react';
import LoginWidget from "./LoginWidget";

function LoginPopup({handleClose, reportSess}) {

  return <React.Fragment>
    <div className={"login-popup-overlay"}>
      <div className={"login-popup-container"}>
        <LoginWidget handleClose={handleClose} reportSess={reportSess}/>
        <div>
          <div className={"upper-block"}>The Getplace provide a wealth of valuable information enabling me to make well-informed decisions.</div>
          <div className={"bottom-block"}>
            <div className={"bottom-block-photo"}><img src={"/icons/login-popup-portrait.png"} height={48} width={48}/></div>
            <div className={"bottom-block-hdr"}>Hemanth Bollini</div>
            <div>Multi Unit Franchisee German Doner Kebab</div>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>;
}

export default LoginPopup
