import React, {useState, useEffect} from 'react';
import { Dropdown, Grid, Table } from 'semantic-ui-react';
import { Sortable } from '../../menu/PricePulse';
import {latLngToCell} from "h3-js";
import { formatErrors } from '../../../constants';
import {useDispatch} from "react-redux";
import { getPricingResearchReportAction } from '../../../actions/menuAction';
import { toast } from 'react-toastify';

export function ReportPricePulse ({menuItems, report, title, brand, defaultCode, dGeo}) {
    const [prSort, setPRSort] = useState({column: 'distance', sort:'ascending'});
    const [prReport, setPRReport] = useState(null);
    const [loading, setLoading] = useState(false);
    const [prods, setProds] = useState([]);
    const [code, setCode] = useState(null);

    const [dpois, setDpois] = useState(null);
    const [distances, setDistances] = useState(null);
    const dispatch = useDispatch()

    useEffect(() => {
      if (prReport === null) {
        getPricingResearchReport(menuItems, brand, report);
      }
    }, []);

  useEffect(() => {
    if (code === null) {
      setCode(defaultCode);
    }
  }, []);

    useEffect(() => {
      if (!!prReport) {
        setPRReport(MakePRSort(prSort, prReport))
      }
    }, [prSort])

    function ChangeSort(column) {
        let tmp = {...prSort};
    
        if (tmp.column === column) {
          tmp.sort = tmp.sort==='ascending'?'descending':'ascending';
        } else {
          tmp.column = column;
          tmp.sort = 'ascending';
        }
    
        setPRSort({...tmp})
    }

    function GetPriceDiff(priceYour, priceOther, addPlus) {
        return priceYour>priceOther?"-£"+((priceYour-priceOther)/100):((!!addPlus?"+":"")+"£"+((priceOther-priceYour)/100));
    }

    function GetPercentsCompareP1ofP2(price1, price2) {
        return price2 > 0 ?(Math.round(((price1 - price2)/price2)*100*100)/100+"%"): "0";
    }
    
    function GetReportLink(lat, lng) {
        return "/location-report?hex="+latLngToCell(lat, lng, 10)
    }
    
    function GetMapLink(lat, lng) {
        return "/place-map?lat="+lat+"&lng="+lng
    }
    
    function getPricingResearchReport(menuItems) {
        if (!menuItems || menuItems.length === 0) {
            return;
        }
        setLoading(true)
        let prods = [];
        let cc = {}
        for (let itm of menuItems) {
          if (!cc[itm.code]) {
            cc[itm.code] = 1;
            prods.push({key: itm.code, value: itm.code, text:itm.name});
          }
        }
        setProds(prods);
        let dpoisTmp = [];
        let distancesTmp = {};
        //getReport(brand, code);

        let tmpReport = JSON.parse(JSON.stringify(report));

        for (let idx in tmpReport.items) {
          for (let placeIdx in tmpReport.items[idx].places) {
            dpoisTmp.push(tmpReport.items[idx].places[placeIdx].id);
            distancesTmp[tmpReport.items[idx].places[placeIdx].id] = Math.round((tmpReport.items[idx].places[placeIdx].distance / 1609.34) * 100) / 100;
            tmpReport.items[idx].places[placeIdx].distance = distancesTmp[tmpReport.items[idx].places[placeIdx].id]
          }
        }
        setPRReport(MakePRSort(prSort, tmpReport));
        setDpois(dpoisTmp);
        setDistances(distancesTmp);
    }

    function getReport(brand, code) {
        setLoading(true);
        dispatch(getPricingResearchReportAction({brand: brand, codes: [code], dpois: dpois, city: dGeo})).then(res => {
            setLoading(false);
            for (let idx in res.report.items) {
              for (let placeIdx in res.report.items[idx].places) {
                res.report.items[idx].places[placeIdx].distance = distances.hasOwnProperty(res.report.items[idx].places[placeIdx].id)?distances[res.report.items[idx].places[placeIdx].id]:null;
              }
            }
            setPRReport(MakePRSort(prSort, res.report));
        }).catch((err) => {
          setLoading(false);
          console.log(err)
          toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
        });
    }

    function MakePRSort(sort, sortable) {
      let tmp = JSON.parse(JSON.stringify(sortable));
  
      if (sort.column === 'menu_item') {
        tmp.items.sort((a, b) => {
          if (sort.sort==='descending') {
            return a.menu_item_name>b.menu_item_name?-1:1;
          } else {
            return a.menu_item_name<b.menu_item_name?-1:1;
          }
        });
      } else if (sort.column === 'price') {
        for (let idx in tmp.items) {
          tmp.items[idx].places.sort((a, b) => {
            if (sort.sort==='descending') {
              return a.price_value>b.price_value?-1:1;
            } else {
              return a.price_value<b.price_value?-1:1;
            }
          });
        }
      } else if (sort.column === 'location') {
        for (let idx in tmp.items) {
          tmp.items[idx].places.sort((a, b) => {
            if (sort.sort==='descending') {
              return a.address>b.address?-1:1;
            } else {
              return a.address<b.address?-1:1;
            }
          });
        }
      } else if (sort.column === 'distance') {
        for (let idx in tmp.items) {
          tmp.items[idx].places.sort((a, b) => {
            if (sort.sort==='descending') {
              return a.distance>b.distance?-1:1;
            } else {
              return a.distance<b.distance?-1:1;
            }
          });
        }
      }
  
      return tmp;
    }

    const handleChangeForm = (value) => {
        setCode(value);
        getReport(brand, value, dpois);
    };
    

    if (!dpois || !prReport || menuItems.length === 0) {
        return <div />;
    }

    return <div style={{padding:"0 0px"}}>
    <Grid>
      {prReport.items.map((item, idx) => {
        return <React.Fragment key={'brand_' + brand + '_' + idx}>
          <h3>{title}</h3>
          <Grid.Row>
            <Grid.Column className={"six wide column"}>
              <Table className={"ps-info-table"} basic={"very"}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Menu item</Table.HeaderCell>
                    <Table.HeaderCell>{dGeo} pricing summary</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                        <Dropdown key={"dd_"+brand} selection value={code} options={prods} onChange={(e,v) => {handleChangeForm(v.value)}}/>
                    </Table.Cell>
                    <Table.Cell>
                      <p className={"ps-margin"}>Median: &nbsp;&nbsp;<b>£{item.median / 100}</b></p>
                      <p className={"ps-margin"}>Min: &nbsp;&nbsp;<b className={"ps-min"}>£{item.min / 100}</b></p>
                      <p className={"ps-margin"}>Max: &nbsp;&nbsp;<b className={"ps-max"}>£{item.max / 100}</b></p>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column className={"wide column"}>
              <Table className={"ps-data-table"} basic={"very"}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell className={"ps-10"}>
                      <Sortable
                        text={"Price"}
                        onClick={() =>{ChangeSort('price')}}
                        sort={prSort.column==="price"?prSort.sort:null}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell className={"ps-15"}>Median Margin</Table.HeaderCell>
                    <Table.HeaderCell className={"ps-10"}>
                      <Sortable
                        text={"Distance"}
                        onClick={() =>{ChangeSort('distance')}}
                        sort={prSort.column==="distance"?prSort.sort:null}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell className={"ps-10"}>Rating</Table.HeaderCell>
                    <Table.HeaderCell>
                      <Sortable
                        text={"Location"}
                        onClick={() =>{ChangeSort('location')}}
                        sort={prSort.column==="location"?prSort.sort:null}
                      />
                    </Table.HeaderCell>
                    {/*<Table.HeaderCell className={"ps-15"}></Table.HeaderCell>*/}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell colSpan={5}>
                      <div style={{maxHeight:"300px",width:"100%", overflowY:"scroll"}}>
                      <Table basic={"very"} className={"ps-inner-table"} striped>
                        <Table.Body>
                          {item.places.map((place, placeIdx) => {
                            return <React.Fragment key={'place_'+brand+'_'+placeIdx}>
                              <Table.Row>
                                <Table.Cell className={"ps-10"+(item.max===place.price_value?" ps-max ":"")+(item.min===place.price_value?" ps-min ":"")}><b>£{place.price_value / 100}</b></Table.Cell>
                                <Table.Cell className={"ps-15"}>
                                  <div className={"ps-margin"}>
                                    <span><b>{GetPriceDiff(item.median, place.price_value, true)}</b></span>
                                    <span>{GetPercentsCompareP1ofP2(place.price_value, item.median)}</span>
                                  </div>
                                </Table.Cell>
                                <Table.Cell className={"ps-10"}>
                                  {!!place.distance?place.distance:'-'}
                                </Table.Cell>
                                <Table.Cell>
                                  {place.rating ? place.rating + '/5' : '-'}
                                </Table.Cell>
                                <Table.Cell><b>{place.name}</b><br/><b>{place.address}</b></Table.Cell>
                                {/*<Table.Cell>
                                  {!!place.latitude && place.longitude && <a className={"pp-link"} href={GetMapLink(place.latitude, place.longitude)} target={"_blank"}><i className={"price-pulse-mark price-pulse-map-mark"} /></a>}
                                  {!!place.latitude && place.longitude && <a className={"pp-link"} href={GetReportLink(place.latitude, place.longitude)} target={"_blank"}><i className={"price-pulse-mark price-pulse-report-mark"} /></a>}
                                </Table.Cell>*/}
                              </Table.Row>
                            </React.Fragment>;
                          })}
                        </Table.Body>
                      </Table>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </React.Fragment>;
      })}
    </Grid>
  </div>;
  
}
