import React, {useEffect, useState} from 'react';
import {BeautyNumber, CalcDiff} from "../../../constants";
import {Grid} from "semantic-ui-react";

function Snapshot({report, handleChange}) {

  const tabFootfall = 0;
  const tabTraffic = 1;
  const tabDemographic = 2;
  const tabAmenities = 3;
  const tabCompetitors = 4;
  const tabPanorama = 5;
  const tabPricing = 6;
  const tabDelivery = 7;
  const tabRealEstate = 8;
  const tabSnapshot = 9;

  return <React.Fragment>

    {/*!!report?.footfall && !!report?.avgFootfall && !!report.avgFootfall.options && !!report.avgFootfall.series && <Grid.Row>
      <Grid.Column>

    <div className={"ss-avg-footfall"}>
        <h2>Footfall average per month</h2>
        <Chart options={report.avgFootfall.options} series={report.avgFootfall.series} type={"bar"} height={250}/>
    </div>

      </Grid.Column>
    </Grid.Row>*/}
    <Grid.Row>
      <Grid.Column>

    <div className={"ss-container"}>

      {!!report?.footfall && <div className={"ss-card"}>
        <div className={"ss-header"}>Footfall</div>

        {!!report.footfall.avg && <div className={"ss-items-100"}>
          <div className={"main"}>{report.footfall.avg} pedestrians</div>
          <div className={"props"}>per average day</div>
        </div>}

        {(!!report.footfall.hottestDay || !!report.footfall.hottestHour) && <div className={"ss-items-grid"}>
          {!!report.footfall.hottestDay && <div>
            <div className={"main"}>{report.footfall.hottestDay}</div>
            <div className={"props"}>the hottest day</div>
          </div>}

          {!!report.footfall.hottestHour && <div>
            <div className={"main"}>{report.footfall.hottestHour}</div>
            <div className={"props"}>the hottest hour</div>
          </div>}
        </div>}

        <div className={"ss-ins"}><span onClick={() => handleChange(tabFootfall)}>View all insights</span></div>
      </div>}

      {!!report?.traffic && <div className={"ss-card"}>
        <div className={"ss-header"}>Vehicle traffic</div>

        {!!report.traffic.avg && <div className={"ss-items-100"}>
          <div className={"main"}>{report.traffic.avg} vehicles</div>
          <div className={"props"}>per average day</div>
        </div>}

        {(!!report.traffic.hottestDay || !!report.traffic.hottestHour) && <div className={"ss-items-grid"}>
          {!!report.traffic.hottestDay && <div>
            <div className={"main"}>{report.traffic.hottestDay}</div>
            <div className={"props"}>the hottest day</div>
          </div>}

          {!!report.traffic.hottestHour && <div>
            <div className={"main"}>{report.traffic.hottestHour}</div>
            <div className={"props"}>the hottest hour</div>
          </div>}
        </div>}

        <div className={"ss-ins"}><span onClick={() => handleChange(tabTraffic)}>View all insights</span></div>
      </div>}

      {!!report?.demographics && <div className={"ss-card"}>
        <div className={"ss-header"}>Demographics</div>

        {(!!report.demographics.pop500 || !!report.demographics.pop3m) && <div className={"ss-items-grid"}>
          {!!report.demographics.pop500 && <div>
            <div className={"main"}>{BeautyNumber(report.demographics.pop500)}</div>
            <div className={"props"}>Number of people within 500 yards</div>
          </div>}

          {!!report.demographics.pop3m && <div>
            <div className={"main"}>{BeautyNumber(report.demographics.pop3m)}</div>
            <div className={"props"}>Number of people within 3 miles</div>
          </div>}
        </div>}

        {(!!report.demographics.relationship || !!report.demographics.ethnicity) && <div className={"ss-items-grid"}>
          {!!report.demographics.relationship && <div>
            <div className={"main"}>{report.demographics.relationship}</div>
            <div className={"props"}>Relationship status</div>
          </div>}

          {!!report.demographics.ethnicity && <div>
            <div className={"main"}>{report.demographics.ethnicity}</div>
            <div className={"props"}>Ethnicity</div>
          </div>}
        </div>}

        {!!report.demographics.income && <div className={"ss-items-100"}>
          <div className={"main"}>£{BeautyNumber(report.demographics.income)}/ year</div>
          <div className={"props"}>Median household income</div>
        </div>}

        <div className={"ss-ins"}><span onClick={() => handleChange(tabDemographic)}>View all insights</span></div>
      </div>}

      {!!report?.amenities && (!!report.amenities.schools || !!report.amenities.supermarkets || !!report.amenities.subway || !!report.amenities.closest) && <div className={"ss-card"}>
        <div className={"ss-header"}>Amenities</div>

        {(!!report.amenities.schools || !!report.amenities.supermarkets || !!report.amenities.subway) && <div className={"ss-items-100"}>
          {!!report.amenities.subway && <div className={"main"}>{report.amenities.subway} Underground stations</div>}
          {!!report.amenities.supermarkets && <div className={"main"}>{report.amenities.supermarkets} Supermarkets</div>}
          {!!report.amenities.schools && <div className={"main"}>{report.amenities.schools} Schools</div>}
          <div className={"props"}>within 1 mile</div>
        </div>}

        {!!report.amenities.closest && <div className={"ss-items-100"}>
          <div className={"main"}>{report.amenities.closest}</div>
          <div className={"props"}>the closest amenity</div>
        </div>}

        <div className={"ss-ins"}><span onClick={() => handleChange(tabAmenities)}>View all insights</span></div>
      </div>}

      {!!report?.competitors && <div className={"ss-card"}>
        <div className={"ss-header"}>Competitors</div>

        {!!report.competitors.closest && <div className={"ss-items-100"}>
          <div className={"main"}>{report.competitors.closest}</div>
          <div className={"props"}>the closest competitor</div>
        </div>}

        {!!report.competitors.highestGoogleRating && <div className={"ss-items-100"}>
          <div className={"main"}>{report.competitors.highestGoogleRating}</div>
          <div className={"props"}>competitor with the highest Google rating</div>
        </div>}

        {!!report.competitors.highestReviewsNumber && <div className={"ss-items-100"}>
          <div className={"main"}>{report.competitors.highestReviewsNumber}</div>
          <div className={"props"}>competitor with the highest reviews number</div>
        </div>}

        <div className={"ss-ins"}><span onClick={() => handleChange(tabCompetitors)}>View all insights</span></div>
      </div>}

      {!!report?.pricing && ( !!report.pricing.macPrice && !!report.pricing.macMedian || !!report.pricing.bkMedian && !!report.pricing.bkPrice ) && <div className={"ss-card"}>
        <div className={"ss-header"}>Pricing</div>

        {!!report.pricing.macPrice && !!report.pricing.macMedian && <div className={"ss-items-grid"}>
          <div>
            <div className={"main"}>£{report.pricing.macPrice / 100}</div>
            <div className={"props"}>Big Mac price at the closest McDonald's</div>
          </div>

          <div>
            <div className={"main"}>{(report.pricing.macPrice > report.pricing.macMedian?1:-1)*CalcDiff(report.pricing.macPrice / 100, report.pricing.macMedian / 100)}% {report.pricing.macPrice > report.pricing.macMedian?"higher":"lower"}</div>
            <div className={"props"}>than the median London price</div>
          </div>
        </div>}

        {!!report.pricing.bkPrice && !!report.pricing.bkMedian && <div className={"ss-items-grid"}>
          <div>
            <div className={"main"}>£{report.pricing.bkPrice / 100}</div>
            <div className={"props"}>Whopper price at the closest Burger King</div>
          </div>

          <div>
            <div className={"main"}>{(report.pricing.bkPrice > report.pricing.bkMedian?1:-1)*CalcDiff(report.pricing.bkPrice / 100, report.pricing.bkMedian / 100)}% {report.pricing.bkPrice > report.pricing.bkMedian?"higher":"lower"}</div>
            <div className={"props"}>than the median London price</div>
          </div>
        </div>}

        <div className={"ss-ins"}><span onClick={() => handleChange(tabPricing)}>View all insights</span></div>
      </div>}

      {!!report?.realEstate && <div className={"ss-card"}>
        <div className={"ss-header"}>Real Estate</div>

        {(!!report.realEstate.commercialRent || !!report.realEstate.commercialSale) && <div className={"ss-items-100"}>
          <div className={"main"}>
            {report.realEstate.commercialRent?"£"+report.realEstate.commercialRent+" to rent":""}
            {report.realEstate.commercialRent&&report.realEstate.commercialSale?" / ":""}
            {report.realEstate.commercialSale?"£"+report.realEstate.commercialSale+" for sale":""}
          </div>
          <div className={"props"}>Median commercial price for 1 sq feet within 5 miles</div>
        </div>}

        {(!!report.realEstate.residentRent || !!report.realEstate.residentSale) && <div className={"ss-items-100"}>
          <div className={"main"}>
            {report.realEstate.residentRent?"£"+report.realEstate.residentRent+" to rent":""}
            {report.realEstate.residentRent&&report.realEstate.residentSale?" / ":""}
            {report.realEstate.residentSale?"£"+report.realEstate.residentSale+" for sale":""}
          </div>
          <div className={"props"}>Median residential price for 1 sq feet within 5 miles</div>
        </div>}

        <div className={"ss-ins"}><span onClick={() => handleChange(tabRealEstate)}>View all insights</span></div>
      </div>}
    </div>
      </Grid.Column>
    </Grid.Row>
  </React.Fragment>;
}

export default Snapshot
