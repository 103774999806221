import cfg, {handleResponse} from "../constants";
import {authMiddleware} from "./loginAction";

// get orders list
export const ordersAction = (filter) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/orders', {
    method: "POST",
    body: JSON.stringify(filter),
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

// get my reports list
export const myReportsAction = (filter) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/my-reports', {
    method: "POST",
    body: JSON.stringify(filter),
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

// delete order
export const orderDeleteAction = (id) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/order/' + id, {
    method: "DELETE",
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};
