import cfg, {handleResponse} from "../constants";
import {authMiddleware} from "./loginAction";

// get tripadvisors list
export const tripadvisorsAction = (filter) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/tripadvisors', {
      method: "POST",
      body: JSON.stringify(filter),
      headers
  }).then(handleResponse).then((data) => {
      if (data.status === 'ok') {
          return data;
      }
      if (data.errors && data.errors.length > 0) {
          throw new Error(data.errors[0]);
      }
      throw new Error('error_other');
  });
};

// delete tripadvisor
export const tripadvisorDeleteAction = (id) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/tripadvisor/' + id, {
      method: "DELETE",
      headers
  }).then(handleResponse).then((data) => {
      if (data.status === 'ok') {
          return data;
      }
      if (data.errors && data.errors.length > 0) {
          throw new Error(data.errors[0]);
      }
      throw new Error('error_other');
  });
};