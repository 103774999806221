import cfg, {UPDATE_ERROR, UPDATE_SIGNIN, handleResponse, UPDATE_LANGUAGE} from "../constants";

// authAction - auth user with login/password
export const authAction = (body) => (dispatch, getState) => {
    return fetch(cfg.base_url + '/auth/authenticate', {
        method: "POST",
        body: JSON.stringify(body)
    }).then(handleResponse).then((data) => {
        if (data.status === 'ok' && data.user && data.user.token) {
            storeUser(data.user);
            dispatch({type: UPDATE_SIGNIN, profile: data.user});
            if (data.user.language) {
                storeLanguage(data.user.language);
                dispatch({type: UPDATE_LANGUAGE, lang: data.user.language});
            }
            if (data.place_categories) {
              localStorage.setItem('place_categories', data.place_categories);
            }
            if (data?.welcome === 1) {
              data.user.welcome = 1;
            }
            return data.user;
        }
        if (data.errors && data.errors.length > 0) {
          throw new Error(data.errors[0]);
        }
        throw new Error('error_other');
    });
};

// registerAction - user sign up
export const registerAction = (body) => (dispatch, getState) => {
  return fetch(cfg.base_url + '/auth/register', {
      method: "POST",
      body: JSON.stringify(body)
  }).then(handleResponse).then((data) => {
      if (data.status === 'ok') {
          storeUser(data.user);
          dispatch({type: UPDATE_SIGNIN, profile: data.user});
          if (data.user.language) {
              storeLanguage(data.user.language);
              dispatch({type: UPDATE_LANGUAGE, lang: data.user.language});
          }
          return data.user;
      }
      if (data.errors && data.errors.length > 0) {
          throw new Error(data.errors[0]);
      }
      throw new Error('error_other');
  });
};

// inviteCheckAction
export const inviteCheckAction = (invite_code) => (dispatch, getState) => {
  return fetch(cfg.base_url + '/auth/invite-check', {
    method: "POST",
    body: JSON.stringify({invite_code})
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data.id;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

// restoreAction
export const restoreAction = (body) => (dispatch, getState) => {
    return fetch(cfg.base_url + '/auth/restore', {
        method: "POST",
        body: JSON.stringify(body)
    }).then(handleResponse).then((data) => {
        if (data.status === 'ok') {
            return data;
        }
        if (data.errors && data.errors.length > 0) {
            throw new Error(data.errors[0]);
        }
        throw new Error('error_other');
    });
};

// confirmAction
export const confirmAction = (code) => (dispatch, getState) => {
  return fetch(cfg.base_url + '/auth/confirm', {
    method: "POST",
    body: JSON.stringify(code)
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

// resendAction
export const resendAction = (email) => (dispatch, getState) => {
  return fetch(cfg.base_url + '/auth/resend', {
    method: "POST",
    body: JSON.stringify({email: email})
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok' || data.status === 'wait') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

// authMiddleware - prepare headers for POST requests
export function authMiddleware(dispatch, getState, isPublic, onlyToken = false) {
    //const profile = getState().user.profile;
    const profile = getUser();
    if (!profile.token) {
        dispatch({type: UPDATE_SIGNIN, profile: profile});
        if (!isPublic) {
          window.location = '/login';
        }
        return {};
    }
    const duration = (profile.expires - Math.floor(Date.now() / 1000)) / 60;

    const headers = {
        "Accept": "application/json",
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + profile.token
    };

    // if duration less 10 minutes, send refresh
    if ( duration <= 10 ) {
        fetch(cfg.base_url + '/auth/refresh-token', {method: "POST", headers: headers}).then(response => response.json())
          .then((data) => {
              if (data.status === 'ok' && data.user && data.user.token) {
                  profile.token = data.user.token;
                  profile.expires = data.user.expires;
                  storeUser(data.user);
                  dispatch({type: UPDATE_SIGNIN, profile: data.user});
              }
              if (data.errors && data.errors.length > 0) {
                  throw new Error(data.errors[0]);
              }
              throw new Error('error_other');
          }).catch(error => {
            dispatch({type: UPDATE_ERROR, errors: ['error_other']});
        });
    }

    if (onlyToken) {
      return profile.token;
    }
    return headers
}

// authToken - get token for GET requests
export function authToken(dispatch, isPublic) {
  const profile = getUser();
  if (!profile.token) {
    dispatch({type: UPDATE_SIGNIN, profile: profile});
    if (!isPublic) {
      window.location = '/login';
    }
    return '';
  }
}

export function storeUser(user = {}) {
    localStorage.setItem('bm_current_user', JSON.stringify(user));
}

export function removeUser() {
    localStorage.removeItem('bm_current_user');
}

export function getUser() {
    let user =  localStorage.getItem('bm_current_user');
    user = user ? JSON.parse(user) : '';
    const seconds = new Date().getTime() / 1000;
    if (!user || user.expires < seconds) {
        return {};
    }
    return user;
}

export function getLanguage() {
  const lang = localStorage.getItem('bm_current_language');
  if (lang) {
    return lang;
  }
  let def = getDeviceLanguage();
  // def = 'de';
  switch (def) {
    case 'de':
      return 'de';
    default:
      return 'en';
  }
}

export function storeLanguage(lang) {
  localStorage.setItem('bm_current_language', lang);
}

function getDeviceLanguage() {
  let defaultLang = 'en';
  if (typeof navigator === 'undefined') {
    return defaultLang;
  }
  let nav = navigator; // eslint-disable-line no-undef
  if (nav) {
    if (nav.language) {
      return nav.language.split('-')[0].toLowerCase();
    }
    if (!!nav.languages && !!nav.languages[0]) {
      return nav.languages[0].split('-')[0].toLowerCase();
    }
    if (nav.userLanguage) {
      return nav.userLanguage.split('-')[0].toLowerCase();
    }
    if (nav.browserLanguage) {
      return nav.browserLanguage.split('-')[0].toLowerCase();
    }
  }
  return defaultLang;
}

export const getPaymentSes = () => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/stripe-intent', {
      method: "GET",
      headers
  }).then(handleResponse).then((data) => {
      if (data.status === 'ok') {
          return data.payment_ses;
      }
      throw new Error('error_other');
  });
};

export const getOrderIntent = (body) => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/order-intent', {
    method: "POST",
    body: JSON.stringify(body),
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data.payment_ses;
    }
    throw new Error('error_other');
  });
};

// get role
export const getRole = () => (dispatch, getState) => {
  const headers = authMiddleware(dispatch, getState);
  return fetch(cfg.base_url + '/auth/get-role', {
    method: "GET",
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    throw new Error('error_other');
  });
};

// track
export const trackAction = (url, data) => (dispatch, getState) => {
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(handleResponse).then((data) => {
    if (data.status === 'success') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};
