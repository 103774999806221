import React, {useEffect, useState} from 'react';

function LocationReportNavigation({displayMode, activeTab, tabs, handleChange}) {

  const [dict, setDict] = useState([]);
  const displayModeFootfall = [9,0,1,2];
  const displayModeDemographic = [9,2,0,1];

  const [dKeys, setDKeys] = useState([]);

  const [prev, setPrev] = useState(null);
  const [next, setNext] = useState(null);

  useEffect(() => {

    if (dict.length === 0) {

      let mode = displayModeFootfall;
      if (displayMode === "demographic") {
        mode = displayModeDemographic;
      }

      let keys = Object.keys(tabs);

      //
      for (let i in mode) {
        let idx = mode[i]+"";

        dict.push(tabs[idx]);
        dKeys.push(parseInt(idx));
      }

      for (let i of keys) {
        if (mode.indexOf(parseInt(i)) >= 0) {
          continue
        }

        dict.push(tabs[i]);
        dKeys.push(parseInt(i));
      }

      setDict([...dict]);
      setDKeys([...dKeys]);
    }

  }, [displayMode, tabs])

  useEffect(() => {
    if (dict.length > 0) {

      let idx = dKeys.indexOf(activeTab);
      if (idx >= 0) {
        if (idx > 0) {
          setPrev({key: dKeys[idx-1], text: dict[idx-1+""].text})
        } else {
          setPrev(null);
        }
        if (idx < dKeys.length-1) {
          setNext({key: dKeys[idx+1], text: dict[idx+1+""].text})
        } else {
          setNext(null);
        }
      } else {
        setPrev(null);
        setNext(null);
      }
    }
  }, [activeTab, dict]);

  return <React.Fragment>
    {(!!prev || !!next) && <div className={"location-report-nav-container"}>
      {!!prev && <div className={"location-report-nav-button prev"} onClick={() => {handleChange(prev.key);}}>{prev.text}</div>}
      {!!next && <div className={"location-report-nav-button next"} onClick={() => {handleChange(next.key);}}>{next.text}</div>}
    </div>}
  </React.Fragment>;
}

export default LocationReportNavigation;
