import React, {useEffect, useState} from 'react'
import {Button, Form, Icon, Modal, Image, Loader, Segment, Grid} from 'semantic-ui-react'
import {toast} from "react-toastify";
import cfg, {formatErrors} from "../../../constants";
import {connect} from "react-redux";
import FileInput from '../../../utils/Upload/FileInput';
import {userDeleteLogoAction, userGetAction, userUploadLogoAction} from "../../../actions/userAction";

function UserLogoModal(props) {
  const [open, setOpen] = React.useState(false)
  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={props.trigger}
      size={'tiny'}
    >
      <UserLogoModalContent {...props} setOpen={setOpen} />
    </Modal>
  )
}

function UserLogoModalContent(props) {
  const {setOpen, itemId, getUser} = props;
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState('');
  const [form, handleChange] = useState({
    user_id: '',
    file: {},
  });

  const handleFileSelected = (file) => {
    handleChange({...form, file});
  };

  const handleSubmit = () => {
    if (loading) {
      return false;
    }
    try {
      setLoading(true);
      props.upload(form).then((response) => {
        setLoading(false);
        try {
          let data = JSON.parse(response);
          handleFileSelected({});
          setLogo(data.file);
        } catch (e) {
          uploadError(e);
        }
      }).catch((e) => {
        uploadError(e);
      });
    } catch (e) {
      uploadError(e);
    }
  }

  // delete file
  const handleDeleteFile = () => {
    if (loading) {
      return;
    }
    setLoading(true);
    props.deleteLogo(itemId).then((res) => {
      if (res.status === 'ok') {
        setLogo('');
        props.ok();
      }
      setLoading(false);
    }).catch(err => {
      uploadError(err);
    });
  }

  const uploadError = (error) => {
    setLoading(false);
    toast.error(formatErrors([error.message]), {position: toast.POSITION.BOTTOM_RIGHT});
  };

  useEffect(() => {
    setLoading(true);
    getUser(itemId).then((res) => {
      handleChange((form) => {
        return {...form, user_id: itemId};
      });
      setLogo(res.photo);
      setLoading(false);
      props.ok();
    }).catch((err) => {
      uploadError(err);
      setOpen(false);
    });
  }, [getUser, itemId, setOpen]);

  return <React.Fragment>
    <Modal.Header>User logo</Modal.Header>
    <Modal.Content>
      {loading && !form.user_id && <Loader active size="large" />}
      {form.user_id && <Form noValidate>

        <Form.Field>
          <FileInput accept={".jpg,.jpeg,.png"} loading={form.file && loading}
                    handleChange={(file) => handleFileSelected(file)}>Select Logo</FileInput>
        </Form.Field>

        {form.file && form.file.name && <Segment>
          <Grid columns="equal" verticalAlign="middle">
            <Grid.Column width={10}>
              {form.file.name}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button icon onClick={() => handleFileSelected({})} secondary disabled={loading}><Icon name="delete" /></Button>
              <Button icon onClick={handleSubmit} primary disabled={loading}><Icon name="upload" /></Button>
            </Grid.Column>
          </Grid>
        </Segment>}

        {logo && (!form.file || !form.file.name) && <Segment>
          <Grid columns="equal" verticalAlign="middle">
            <Grid.Column width={10}>
              <Image src={cfg.public_url + '/auth/image/' + form.user_id + '/i/' + logo} size="tiny" wrapped />
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button icon onClick={handleDeleteFile} color="black" disabled={loading}><Icon name="delete" /></Button>
            </Grid.Column>
          </Grid>
        </Segment>}

        <br />
      </Form>}
    </Modal.Content>
    <Modal.Actions>
      <Button onClick={() => setOpen(false)} disabled={loading}>Close</Button>
    </Modal.Actions>
  </React.Fragment>
}

function mapStateToProps(state) {
  return {
    //
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUser(id) {
      return dispatch(userGetAction(id));
    },
    upload(data) {
      return dispatch(userUploadLogoAction(data));
    },
    deleteLogo(userId) {
      return dispatch(userDeleteLogoAction(userId));
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserLogoModal);
