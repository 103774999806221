import React from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Segment, Message, Container} from "semantic-ui-react";

export function NotFound() {

  return <React.Fragment>
    <MyAppBar/>
    <Container className={'container-narrow'}>
      <Segment padded basic className="segment-narrow">
          <Message icon="exclamation circle" info header="Not Found" content="The requested URL not found"/>
      </Segment>
    </Container>
  </React.Fragment>;
}

export default NotFound
