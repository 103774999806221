import React from 'react';
import {useLocation} from "react-router-dom";
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {Container, Header, Message} from "semantic-ui-react";

function RegisterComplete(props) {
  const {state} = useLocation();
  return <React.Fragment>
    <MyAppBar/>
    <Container className={'container-narrow'}>
      <Header as={'h3'} textAlign='center'>Registration completed</Header>


      {state && !state.invite && <Message positive>
        <p>You have been sent a link to verify your e-mail. Please click on it.</p>
      </Message>}

    </Container>
  </React.Fragment>;
}

export default RegisterComplete
