import React from 'react';
import {Grid, List} from "semantic-ui-react";

function Consultation() {
  return <React.Fragment>
    <div className={"lr-alert"}>You haven't requested any reports yet — 1 free report is waiting for you!</div>
    <div className={"lr-consultation"}>
      <div className={"lr-photo"}>
        <div className={"lr-photo-title-container"}>
          <div className={"lr-photo-title"}><b>CEO Getplace.io</b><br/>Denis Chernobaev</div>
        </div>
      </div>
      <div className={"lr-text-container"}>
        <div className={"lr-title"}>
          Free consultation with<br/><span className={"magenta"}>the founder of Getplace.io</span>

          <List bulleted className={"lr-consultation-list"}>
            <List.Item>Senior Management of an Eastern-European pizza company</List.Item>
            <List.Item>Growth from 100 to 900 locations across 17 countries worldwide</List.Item>
          </List>
          <a className={"lr-button"} href={"https://calendly.com/denis_getplace"} target={"_blank"}>Set up a Call Now</a>
          <div className={"lr-understand"}>Denis will help you understand how to:</div>
          <Grid columns='equal'>
              <Grid.Column>
                <Grid.Row>
                  <List bulleted className={"lr-understand-text"}>
                    <List.Item>Increase the footfall conversion rate</List.Item>
                    <List.Item>Make informed, data-driven decisions</List.Item>
                  </List>
                </Grid.Row>
              </Grid.Column>
            <Grid.Column>
              <Grid.Row>
                <List bulleted className={"lr-understand-text"}>
                  <List.Item>Win the local competition</List.Item>
                  <List.Item>Choose the right location</List.Item>
                </List>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </div>
    <div>
      <a className={"lr-email-ico"} href={"mailto:info@getplace.io"} target={"_blank"}/>
      <a className={"lr-lnkdn-ico"} href={"https://www.linkedin.com/in/denis-chernobaev/"} target={"_blank"}/>
    </div>
  </React.Fragment>;
}

export default Consultation
