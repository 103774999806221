import React, {useState} from 'react';
import {Accordion, Icon, List} from "semantic-ui-react";
import {AccordionTabs} from "../../../constants";

function Demographics() {
  const [tabs, setTabs] = useState([]);
  const at = new AccordionTabs();

  return <React.Fragment>
    <Accordion className={"report-qa"}>
      <Accordion.Title active={at.g(0,tabs)}
                       index={0}
                       onClick={() => at.toggle(0,tabs,setTabs)}>
        <Icon name='dropdown' />
        <b>What is the source of the data?</b>
      </Accordion.Title>
      <Accordion.Content active={at.g(0,tabs)}>
        <span>
          We combine and enrich the data utilizing multiple sources:<br/>
          <List bulleted>
            <List.Item>
              For age, ethnic makeup, religion, gender, and relationship status we use the official Census 2021 and Census 2011. The census happens every 10 years and gives a picture of all the people and households in England and Wales;
            </List.Item>
            <List.Item>
              Our partner, Crystal Roof, provides data on household income for the London locations. Their proprietary algorithms model data to separately pinpoint household income for every site;
            </List.Item>
            <List.Item>
              For crime statistics, we get the data from our partner, Crystal Roof, which they get from an official open database about crime and policing in England, Wales, and Northern Ireland. The data is being published on a monthly basis since January 2020.            </List.Item>
          </List>
        </span>
      </Accordion.Content>
    </Accordion>
  </React.Fragment>
}

export default Demographics;
