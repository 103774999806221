import cfg, {handleResponse} from "../constants";
import { getUser } from "./loginAction";

// get properties list
export const locationReportAction = (data) => (dispatch, getState) => {
  const profile = getUser();
  const headers = {
    "Accept": "application/json",
    'Content-Type': 'application/json',
  };
  let url = cfg.base_url + '/location-report-api';
  if (profile.token) {
    url = cfg.base_url + '/auth/location-report-api';
    headers['Authorization'] = 'Bearer ' + profile.token;
  }

  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};

export const orderReportAction = (data) => (dispatch, getState) => {
  const profile = getUser();
  const headers = {
    "Accept": "application/json",
    'Content-Type': 'application/json',
  };
  let url = cfg.base_url + '/report-order';
  if (profile.token) {
    url = cfg.base_url + '/auth/report-order';
    headers['Authorization'] = 'Bearer ' + profile.token;
  }

  return fetch(url, {
    method: "POST",
    body: JSON.stringify(data),
    headers
  }).then(handleResponse).then((data) => {
    if (data.status === 'ok') {
      return data;
    }
    if (data.errors && data.errors.length > 0) {
      throw new Error(data.errors[0]);
    }
    throw new Error('error_other');
  });
};