import React, {useCallback, useEffect, useState} from 'react';
import MyAppBar from "../../utils/MyAppBar/MyAppBar";
import {
  Container,
  Icon,
  Table,
  Button,
  Grid,
  Breadcrumb,
  Message,
  Dropdown,
  Label, Image
} from "semantic-ui-react";
import {connect, useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import cfg, {formatErrors, roleNames, roles, ROLE_ADMIN, user_statuses} from "../../constants";
import { userDeleteAction, usersAction } from '../../actions/userAction';
import { Link } from 'react-router-dom';
import { UserDeleteModal } from './utils/UserDeleteModal';
import UserInviteModal from './utils/UserInviteModal';
import UserModal from './utils/UserModal';
import Paging from '../../utils/Paging/Paging';
import UserLogoModal from "./utils/UserLogoModal";

function Users(props) {
  const {getUsers} = props;
  const [filter, setFilter] = useState(null);

  const handleChangeFilter = (value, name) => {
    if (name === "page") {
      setFilter({...filter, page: value});
    } else if (name) {
      setFilter({...filter, [name]: value, page: 1});
    } else {
      setFilter({...filter});
    }
  };

  useEffect(() => {
    const dFilter = {role_id: -1, page: 1, limit: 10};
    let curFilter = localStorage.getItem('m_userFilter');
    curFilter = curFilter ? JSON.parse(curFilter) : dFilter;
    setFilter(curFilter);
  }, []);

  return <React.Fragment>
    <MyAppBar/>

    <Container className={'container-wide'}>
      <ButtonsTop handleChange={handleChangeFilter} roleID={filter?.role_id}/>

      {filter && <UsersList filter={filter} handleChange={handleChangeFilter} getUsers={getUsers} />}

    </Container>
  </React.Fragment>;
}

const ButtonsTop = ({roleID, handleChange}) => {
  return <Grid>
    <Grid.Row columns={2}>
      <Grid.Column>
        <Breadcrumb>
          <Breadcrumb.Section active>
            Users
          </Breadcrumb.Section>
        </Breadcrumb>
      </Grid.Column>

      <Grid.Column textAlign='right'>
        <UsersFilter roleID={roleID} handleChange={handleChange} />

        <UserInviteModal ok={handleChange} trigger={<Button>Invite user</Button>}/>
      </Grid.Column>
    </Grid.Row>
  </Grid>;
};

function UsersFilter({roleID, handleChange}) {

  const handleChangeDropDown = (e, data) => {
    const name = data.name;
    let value = data.value;
    if (name === 'role_id' && value === '') {
      value = -1;
    }
    handleChange(value, name);
  };

  return (
      <Dropdown clearable options={roles} selection placeholder='All roles' value={roleID}
                name="role_id" onChange={handleChangeDropDown} className="mr-1" />
  )
}

function UsersList({filter, handleChange, getUsers}) {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.user.profile.id);
  const roleID = useSelector(state => state.user.profile.role_id);
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userRoles, setUserRoles] = useState({});
  const [statuses] = useState(user_statuses.reduce((res, i) => {
    res[i.value] = i.text;
    return res;
  }, {}));

  useEffect(() => {
    if (Object.keys(userRoles).length === 0) {
      let _roles = {};
      for (let item of roles) {
        _roles[item.value] = item.text;
      }
      setUserRoles({..._roles});
    }
  }, [userRoles]);

  const deleteUser = (id) => {
    return new Promise((resolve, reject) => {
      if (loading) {
        return resolve(false);
      }
      setLoading(true);
      dispatch(userDeleteAction(id)).then(() => {
        setLoading(false);
        resolve(true);
        if (Math.ceil(total / filter.limit) === filter.page && filter.page > 1) {
          handleChange(filter.page - 1, "page");
        } else {
          handleChange();
        }
      }).catch(err => {
        setLoading(false);
        toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
        reject(err);
      });
    })
  };

  useEffect(() => {
    if (!filter) {
      return;
    }
    getUsersList(filter);
  }, [filter]);

  // get users list
  const getUsersList = useCallback((filter) => {
    getUsers(filter).then((data) => {
      setItems(data.items || []);
      setTotal(data.total || 0);
    }).catch(err => {
      toast.error(formatErrors([err.message]), {position: toast.POSITION.BOTTOM_RIGHT});
    });
  }, [getUsers]);

  return <React.Fragment>
    {total === 0 && <Message info>
      No users found. Please <UserInviteModal ok={handleChange} trigger={<b> invite a new one</b>}/>.
    </Message>}

    {total > 0 && <Table celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>E-mail</Table.HeaderCell>
          <Table.HeaderCell>Role</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Created</Table.HeaderCell>
          <Table.HeaderCell width={3} />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((i) => {
          return <Table.Row key={i.id}>
            <Table.Cell>
              {i.photo && <Image src={cfg.public_url + '/auth/image/' + i.id + '/i/' + i.photo} size="tiny" wrapped />}
            </Table.Cell>
            <Table.Cell>{i.name}</Table.Cell>
            <Table.Cell>{i.email}</Table.Cell>
            <Table.Cell>{userRoles.hasOwnProperty(i.role_id)?userRoles[i.role_id]:'n/a'}</Table.Cell>
            <Table.Cell>
              {i.status > 0 && <Label color='green'>{statuses[i.status]}</Label>}
              {i.status === 0 && <Label color='yellow'>{statuses[i.status]}</Label>}
            </Table.Cell>
            <Table.Cell>{i.created}</Table.Cell>
            <Table.Cell textAlign="center">
              {userID !== i.id && <UserModal ok={handleChange} itemID={i.id} trigger={<Button icon disabled={loading}><Icon name="edit"/></Button>}/>}
              {(userID !== i.id || roleID === ROLE_ADMIN) && <UserLogoModal ok={() => getUsersList(filter)} itemId={i.id} trigger={<Button icon><Icon name='image' /></Button>}/>}
              {userID === i.id && <Button icon as={Link} to={'/profile'}><Icon name='edit'/></Button>}
              {userID !== i.id && i.role_id !== ROLE_ADMIN && <UserDeleteModal ok={() => deleteUser(i.id)} trigger={<Button icon><Icon name='delete'/></Button>}/>}
            </Table.Cell>
          </Table.Row>;
        })}
      </Table.Body>
    </Table>}

    <Paging total={total} page={filter.page} limit={filter.limit} handleChange={handleChange} />

  </React.Fragment>;
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers(data) {
      return dispatch(usersAction(data));
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Users)
