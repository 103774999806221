import React, {Component} from "react";
import {Button} from "semantic-ui-react"
import PropTypes from "prop-types";

class FileInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
        this.fileInput = React.createRef();
        this.handleChange = this.handleChange.bind(this);
        this.openFileDialog = this.openFileDialog.bind(this);
    }

    openFileDialog() {
        if (this.props.disabled) {
            return false;
        }
        this.fileInput.current.click();
    }

    handleChange(e) {
        if (this.props.disabled) {
            return false;
        }
        if (!!this.props.multiple) {
            const file = e.target.files && e.target.files.length > 0 ? e.target.files : null;
            this.props.handleChange(file);
        } else {
            const file = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
            this.props.handleChange(file);
        }
        this.setState({value: ''});
    }

    render() {
        const {value} = this.state;
        const {className, children, accept, loading, disabled} = this.props;
        return <Button className={className} disabled={disabled} loading={loading} onClick={this.openFileDialog}>
            {children}
            <input
                hidden
                ref={this.fileInput}
                className="fileInput"
                type="file"
                multiple={!!this.props.multiple}
                onChange={this.handleChange}
                accept={accept}
                value={value}
            />
        </Button>
    }
}

FileInput.propTypes = {
    className: PropTypes.string,
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    handleChange: PropTypes.func.isRequired,
    children: PropTypes.node
};

export default FileInput;
