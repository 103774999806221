import {
  UPDATE_LANGUAGES, UPDATE_LANGUAGE
} from "../constants";

const  initialState = {
    languages: null,
    lang: 'en',
};

export default function common(state = initialState, action) {
    switch (action.type) {
        case UPDATE_LANGUAGES:
            return {
                ...state,
                languages: action.languages
            };
        case UPDATE_LANGUAGE:
            return {
                ...state,
                lang: action.lang
            };
        default:
            return state;
    }
}
