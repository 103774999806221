import cfg, {formatStatusError, handleResponse} from "../constants";
import {authMiddleware} from "./loginAction";

// get page by ID
export const pageGetAction = (id) => (dispatch, getState) => {
    const headers = authMiddleware(dispatch, getState);
    return fetch(cfg.base_url + '/auth/page/' + id, {
        method: "GET",
        headers
    }).then(handleResponse).then((data) => {
        if (data.status === 'ok') {
            return data.item;
        }
        if (data.errors && data.errors.length > 0) {
            throw new Error(data.errors[0]);
        }
        throw new Error('error_other');
    });
};

// get pages list
export const pagesAction = (filter) => (dispatch, getState) => {
    const headers = authMiddleware(dispatch, getState);
    return fetch(cfg.base_url + '/auth/pages', {
        method: "POST",
        body: JSON.stringify(filter),
        headers
    }).then(handleResponse).then((data) => {
        if (data.status === 'ok') {
            return data;
        }
        if (data.errors && data.errors.length > 0) {
            throw new Error(data.errors[0]);
        }
        throw new Error('error_other');
    });
};

// delete page
export const pageDeleteAction = (id) => (dispatch, getState) => {
    const headers = authMiddleware(dispatch, getState);
    return fetch(cfg.base_url + '/auth/page/' + id, {
        method: "DELETE",
        headers
    }).then(handleResponse).then((data) => {
        if (data.status === 'ok') {
            return data;
        }
        if (data.errors && data.errors.length > 0) {
            throw new Error(data.errors[0]);
        }
        throw new Error('error_other');
    });
};

// create / edit page
export const pageEditAction = (form) =>  (dispatch, getState) => {
    const headers = authMiddleware(dispatch, getState);
    return fetch(cfg.base_url + '/auth/page', {
        method: "POST",
        body: JSON.stringify(form),
        headers
    }).then(handleResponse).then((data) => {
        if (data.status === 'ok') {
            return data;
        }
        if (data.errors && data.errors.length > 0) {
            throw new Error(data.errors[0]);
        }
        throw new Error('error_other');
    });
};

// upload page image
export const pageUploadImageAction = (form, name) =>  (dispatch, getState) => {
    const token = authMiddleware(dispatch, getState, false, true);
    
    return new Promise((resolve, reject) => {
        const formData = new FormData();
  
        if (form[name] && form[name].name) {
            formData.append("file", form[name], form[name].name);
        }
        formData.append('name', name);
        formData.append('page_id', form.id);
  
        const req = new XMLHttpRequest();
        req.addEventListener("load", event => {
            if (req.status === 200) {
                resolve(req.response);
            } else {
                reject(formatStatusError(req.status, req.response.trim()));
            }
        });
  
        req.upload.addEventListener("error", event => {
            reject(req.response);
        });
  
        req.open("POST", cfg.base_url + '/auth/page/upload');
        req.setRequestHeader("Authorization", 'Bearer ' + token);
        req.send(formData);
    });
};

// remove page image
export const pageDeleteImageAction = (pageId, name) => (dispatch, getState) => {
    const headers = authMiddleware(dispatch, getState);
    return fetch(cfg.base_url + '/auth/page/' + pageId + '/delete-image/' + name, {
        method: "GET",
        headers
    }).then(handleResponse).then((data) => {
        if (data.status === 'ok') {
            return data;
        }
        if (data.errors && data.errors.length > 0) {
            throw new Error(data.errors[0]);
        }
        throw new Error('error_other');
    });
};
