import {UPDATE_COUNTRIES} from "../constants";

const initialState = {
  list: null
};

export default function countries(state = initialState, action) {
    switch (action.type) {
      case UPDATE_COUNTRIES:
        return {
          ...initialState,
          list: action.list
        };
      default:
        return state;
    }
}
